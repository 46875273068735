'use strict';

import _renderComponent from "recompose/renderComponent";
import _mapProps from "recompose/mapProps";
import _lifecycle from "recompose/lifecycle";
import _branch from "recompose/branch";
import _compose from "recompose/compose";
import _omit from "lodash/omit";
import _isEmpty from "lodash/isEmpty";
import _identity from "lodash/identity";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { denormalize } from 'normalizr';
import { withRouter } from 'react-router-dom';
import { organizationSchema, organizationArraySchema } from 'schemas/organizations';
import { determineCurrentOrganization, fetchOrganizationsIfNeeded } from 'actions/Organizations';
import { setSiteError } from 'actions/Site';
import Loader from 'components/base/loader/Loader';
export function mapStateToProps(state) {
  var currentUser = state.auth.currentUser;

  var _ref = _isEmpty(state.organizations) ? {
    current: null,
    items: [],
    isFetching: true
  } : state.organizations,
      current = _ref.current,
      isFetching = _ref.isFetching,
      error = _ref.error,
      items = _ref.items;

  var organizations = denormalize(items || [], organizationArraySchema, state.entities);
  var currentOrganization = denormalize(current, organizationSchema, state.entities);
  return {
    currentUser: currentUser,
    currentOrganization: currentOrganization,
    organizations: organizations,
    isFetching: isFetching,
    error: error
  };
}

var isOrganizationSet = function isOrganizationSet(_ref2) {
  var currentOrganization = _ref2.currentOrganization;
  return currentOrganization;
};

export default function requireOrganization(OrganizationComponent) {
  var mapDispatchToProps = function mapDispatchToProps(dispatch) {
    return {
      fetchOrganizations: bindActionCreators(fetchOrganizationsIfNeeded, dispatch),
      determineCurrentOrganization: bindActionCreators(determineCurrentOrganization, dispatch),
      setSiteError: bindActionCreators(setSiteError, dispatch)
    };
  };

  return _compose(withRouter, connect(mapStateToProps, mapDispatchToProps), _lifecycle({
    componentDidMount: function componentDidMount() {
      var _this = this;

      var params = this.props.match.params;
      this.props.fetchOrganizations().then(function () {
        _this.props.determineCurrentOrganization(params.organizationId);
      }, function (error) {
        _this.props.setSiteError(error);
      });
    }
  }), _mapProps(function (props) {
    return _omit(props, ['fetchOrganizations', 'determineCurrentOrganization', 'setSiteError']);
  }), _branch(isOrganizationSet, _identity, _renderComponent(Loader)))(OrganizationComponent);
}
module.exports = {
	"common": {
		"content_error_report": false,
		"campaign": false,
		"assessment": false,
		"quiz_and_review": true,
		"download_class_report": false,
		"teacher_search": false,
		"facebook": false,
		"back_to_school": false,
		"student_detail": true,
		"assignment_group_select": true,
		"self_study_quiz": false,
		"usage_reports": false,
		"assignment_analysis": true,
		"student_performance": false,
		"react_messages": true,
		"attach_files_on_message": true
	},
	"common_non_production": {
		"early_fiscal_year": false,
		"shuffle_questions": true
	},
	"common_production_and_release": null,
	"production": {
		"content_error_report": false,
		"campaign": false,
		"assessment": false,
		"quiz_and_review": true,
		"download_class_report": false,
		"teacher_search": false,
		"facebook": false,
		"back_to_school": false,
		"student_detail": true,
		"assignment_group_select": true,
		"self_study_quiz": false,
		"usage_reports": false,
		"assignment_analysis": true,
		"student_performance": false,
		"react_messages": true,
		"attach_files_on_message": true
	},
	"release": {
		"content_error_report": false,
		"campaign": false,
		"assessment": false,
		"quiz_and_review": true,
		"download_class_report": false,
		"teacher_search": false,
		"facebook": false,
		"back_to_school": false,
		"student_detail": true,
		"assignment_group_select": true,
		"self_study_quiz": false,
		"usage_reports": false,
		"assignment_analysis": true,
		"student_performance": false,
		"react_messages": true,
		"attach_files_on_message": true
	},
	"edge": {
		"content_error_report": false,
		"campaign": false,
		"assessment": false,
		"quiz_and_review": true,
		"download_class_report": false,
		"teacher_search": false,
		"facebook": false,
		"back_to_school": false,
		"student_detail": true,
		"assignment_group_select": true,
		"self_study_quiz": false,
		"usage_reports": false,
		"assignment_analysis": true,
		"student_performance": false,
		"react_messages": true,
		"attach_files_on_message": true
	},
	"staging": {
		"early_fiscal_year": false,
		"shuffle_questions": true,
		"content_error_report": false,
		"campaign": false,
		"assessment": false,
		"quiz_and_review": true,
		"download_class_report": false,
		"teacher_search": false,
		"facebook": false,
		"back_to_school": false,
		"student_detail": true,
		"assignment_group_select": true,
		"self_study_quiz": false,
		"usage_reports": false,
		"assignment_analysis": true,
		"student_performance": false,
		"react_messages": true,
		"attach_files_on_message": true
	},
	"develop": {
		"early_fiscal_year": false,
		"shuffle_questions": true,
		"content_error_report": false,
		"campaign": false,
		"assessment": false,
		"quiz_and_review": true,
		"download_class_report": false,
		"teacher_search": false,
		"facebook": false,
		"back_to_school": false,
		"student_detail": true,
		"assignment_group_select": true,
		"self_study_quiz": false,
		"usage_reports": false,
		"assignment_analysis": true,
		"student_performance": false,
		"react_messages": true,
		"attach_files_on_message": true
	},
	"rdev-1": {
		"early_fiscal_year": false,
		"shuffle_questions": true,
		"content_error_report": false,
		"campaign": false,
		"assessment": false,
		"quiz_and_review": true,
		"download_class_report": false,
		"teacher_search": false,
		"facebook": false,
		"back_to_school": false,
		"student_detail": true,
		"assignment_group_select": true,
		"self_study_quiz": false,
		"usage_reports": false,
		"assignment_analysis": true,
		"student_performance": false,
		"react_messages": true,
		"attach_files_on_message": true
	},
	"rdev-2": {
		"early_fiscal_year": false,
		"shuffle_questions": true,
		"content_error_report": false,
		"campaign": false,
		"assessment": false,
		"quiz_and_review": true,
		"download_class_report": false,
		"teacher_search": false,
		"facebook": false,
		"back_to_school": false,
		"student_detail": true,
		"assignment_group_select": true,
		"self_study_quiz": false,
		"usage_reports": false,
		"assignment_analysis": true,
		"student_performance": false,
		"react_messages": true,
		"attach_files_on_message": true
	},
	"rdev-3": {
		"early_fiscal_year": false,
		"shuffle_questions": true,
		"content_error_report": false,
		"campaign": false,
		"assessment": false,
		"quiz_and_review": true,
		"download_class_report": false,
		"teacher_search": false,
		"facebook": false,
		"back_to_school": false,
		"student_detail": true,
		"assignment_group_select": true,
		"self_study_quiz": false,
		"usage_reports": false,
		"assignment_analysis": true,
		"student_performance": false,
		"react_messages": true,
		"attach_files_on_message": true
	},
	"rdev-4": {
		"early_fiscal_year": false,
		"shuffle_questions": true,
		"content_error_report": false,
		"campaign": false,
		"assessment": false,
		"quiz_and_review": true,
		"download_class_report": false,
		"teacher_search": false,
		"facebook": false,
		"back_to_school": false,
		"student_detail": true,
		"assignment_group_select": true,
		"self_study_quiz": false,
		"usage_reports": false,
		"assignment_analysis": true,
		"student_performance": false,
		"react_messages": true,
		"attach_files_on_message": true
	},
	"development": {
		"early_fiscal_year": false,
		"shuffle_questions": true,
		"content_error_report": false,
		"campaign": false,
		"assessment": false,
		"quiz_and_review": true,
		"download_class_report": false,
		"teacher_search": false,
		"facebook": false,
		"back_to_school": false,
		"student_detail": true,
		"assignment_group_select": true,
		"self_study_quiz": false,
		"usage_reports": false,
		"assignment_analysis": true,
		"student_performance": false,
		"react_messages": true,
		"attach_files_on_message": true
	}
};
'use strict';

import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import API from 'utils/API';
import { denormalize, normalize } from 'normalizr';
import { FETCH_ENTITY_REQUEST, FETCH_ENTITY_SUCCESS, FETCH_ENTITY_FAILURE, FETCH_ORGANIZATION_REQUEST, FETCH_ORGANIZATION_SUCCESS, FETCH_ORGANIZATION_FAILURE, EDIT_ORGANIZATION, JOIN_ORGANIZATION_REQUEST, JOIN_ORGANIZATION_SUCESSS, JOIN_ORGANIZATION_FAILURE, SAVE_ORGANIZATION_REQUEST, SAVE_ORGANIZATION_FAILURE, SAVE_ORGANIZATION_SUCCESS, SAVE_ORGANIZATION_CANCEL } from 'constants/ActionTypes';
import { organizationSchema } from 'schemas/organizations';

function fetchOrganizationRequest() {
  return {
    type: FETCH_ORGANIZATION_REQUEST
  };
}

export function fetchOrganizationSuccess(organization) {
  return {
    type: FETCH_ORGANIZATION_SUCCESS,
    organization: organization
  };
}
export function fetchOrganizationFailure() {
  return {
    type: FETCH_ORGANIZATION_FAILURE
  };
}
export var joinOrganizationRequest = function joinOrganizationRequest() {
  return {
    type: JOIN_ORGANIZATION_REQUEST
  };
};
export var joinOrganizationSuccess = function joinOrganizationSuccess() {
  return {
    type: JOIN_ORGANIZATION_SUCESSS
  };
};
export var joinOrganizationFailure = function joinOrganizationFailure() {
  return {
    type: JOIN_ORGANIZATION_FAILURE
  };
};
export var requestOrganizationDetails = function requestOrganizationDetails(organizationId) {
  return {
    type: FETCH_ENTITY_REQUEST,
    entityName: 'organizations',
    entityId: organizationId
  };
};
export var fetchOrganizationDetailsFailure = function fetchOrganizationDetailsFailure(organizationId, error) {
  return {
    type: FETCH_ENTITY_FAILURE,
    entityName: 'organizations',
    entityId: organizationId,
    error: error
  };
};
export var receiveOrganizationDetails = function receiveOrganizationDetails(organization) {
  var response = normalize(organization, organizationSchema);
  return {
    type: FETCH_ENTITY_SUCCESS,
    entityName: 'organizations',
    entityId: organization.id,
    entity: response.entities.organizations[organization.id],
    response: response
  };
};

function _editOrganization(organization) {
  return {
    type: EDIT_ORGANIZATION,
    organization: organization
  };
}

function _saveOrganization(organization) {
  return {
    type: SAVE_ORGANIZATION_REQUEST,
    organization: organization
  };
}

export function failedSaveOrganization(organization, error) {
  var errorCode = _get(error, 'data.errors.code', '');

  var errorCodeIntlKeyMap = {
    not_include_requester_ip: 'management.school_settings.errors.not_include_requester_ip',
    invalid_ip_format: 'management.school_settings.errors.invalid_ip_format'
  };
  var errorMessages = errorCode ? [errorCodeIntlKeyMap[errorCode]] : [];
  return {
    type: SAVE_ORGANIZATION_FAILURE,
    organization: organization,
    errorMessages: errorMessages
  };
}
export function successSaveOrganization(organization) {
  return {
    type: SAVE_ORGANIZATION_SUCCESS,
    organization: organization
  };
}
export function _cancelSaveOrganization(organization) {
  return {
    type: SAVE_ORGANIZATION_CANCEL,
    organization: organization
  };
}
export function fetchOrganization(organizationId) {
  return function (dispatch) {
    dispatch(fetchOrganizationRequest());

    var successFn = function successFn(response) {
      dispatch(fetchOrganizationSuccess(response.data));
    };

    var failedFn = function failedFn() {
      dispatch(fetchOrganizationFailure());
    };

    return API.get("/api/organizations/".concat(organizationId)).then(successFn, failedFn);
  };
}
export var fetchOrganizationDetails = function fetchOrganizationDetails(organizationId) {
  return function (dispatch) {
    return new Promise(function (resolve, reject) {
      dispatch(requestOrganizationDetails(organizationId));
      API.get("/api/organizations/".concat(organizationId)).then(function (_ref) {
        var data = _ref.data;
        dispatch(receiveOrganizationDetails(data));
        resolve(data);
      }, function (error) {
        dispatch(fetchOrganizationDetailsFailure(organizationId, error));
        reject(error);
      });
    });
  };
};
export function fetchOrganizationDetailsIfNeeded(organizationId) {
  return function (dispatch, getState) {
    return new Promise(function (resolve, reject) {
      var _getState = getState(),
          entities = _getState.entities;

      var organization = denormalize(organizationId, organizationSchema, entities);

      if ((organization === null || organization === void 0 ? void 0 : organization.permissions) || (organization === null || organization === void 0 ? void 0 : organization.isFetching)) {
        resolve(organization);
      } else {
        dispatch(fetchOrganizationDetails(organizationId)).then(resolve, reject);
      }
    });
  };
}
export function saveOrganization(organization) {
  return function (dispatch) {
    var url = "/api/organizations/".concat(organization.id);
    var changeSets = {
      name: organization.name,
      address: organization.address,
      district: organization.district,
      city: organization.city,
      province: organization.province,
      postcode: organization.postcode,
      phone: organization.phone,
      qlearn_timeline: organization.qlearn_timeline,
      messaging: organization.messaging,
      use_target_planner: organization.use_target_planner,
      use_missions: organization.use_missions,
      use_self_study_quiz: organization.use_self_study_quiz,
      classes: organization.classes
    };
    dispatch(_saveOrganization(organization));

    var successFn = function successFn(response) {
      dispatch(successSaveOrganization(response.data));
      dispatch(receiveOrganizationDetails(response.data));
    };

    var failedFn = function failedFn(error) {
      dispatch(failedSaveOrganization(organization, error));
    };

    return API.put(url, changeSets).then(successFn, failedFn);
  };
}
export function cancelSaveOrganization(organization) {
  return function (dispatch) {
    dispatch(_cancelSaveOrganization(organization));
  };
}
export function editOrganization(organization) {
  return function (dispatch) {
    dispatch(_editOrganization(organization));
  };
}

function shouldFetchOrganization(state) {
  var currentOrganization = state.currentOrganization;

  if (_isEmpty(currentOrganization)) {
    return true;
  }

  if (currentOrganization.isFetching) {
    return false;
  }

  return currentOrganization.didInvalidate;
}

export function fetchOrganizationIfNeeded(organizationId) {
  return function (dispatch, getState) {
    if (shouldFetchOrganization(getState())) {
      return dispatch(fetchOrganization(organizationId));
    }

    return false;
  };
}
export var joinOrganization = function joinOrganization(organizationId, invitationId) {
  return function (dispatch, getState) {
    var userId = getState().auth.currentUser.id;
    dispatch(joinOrganizationRequest());
    return API.post("/api/users/".concat(userId, "/join_organization"), {
      organization_id: organizationId,
      invitation_id: invitationId
    }).then(function () {
      dispatch(joinOrganizationSuccess());
    }, function () {
      return dispatch(joinOrganizationFailure());
    });
  };
};
'use strict';

import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { FETCH_SERVER_TIME } from 'constants/ActionTypes';
import API from 'utils/API';
import { getCurrentOrganization } from 'actions/Organizations';
import { utcToZonedTime } from 'date-fns-tz';
var createAsync = asyncFactory(actionCreatorFactory());
export var fetchServerTime = createAsync(FETCH_SERVER_TIME, function (_params, _dispatch, getState) {
  var state = getState();
  var currentOrganization = getCurrentOrganization(state);
  return API.get("/api/organizations/" + currentOrganization.id + "/time").then(function (_a) {
    var data = _a.data;
    var offset = data.current_time.split('+')[1];
    return {
      currentTime: utcToZonedTime(data.current_time, data.timezone_name),
      timezone: {
        name: data.timezone_name,

        /*
         * Timezones with offset like +7:00 should be GMT+7
         * while those like +5:30 should be GMT+5:30
         */
        offset: 'GMT+' + offset.replace(':00', '').replace(/^0/, '')
      }
    };
  });
});
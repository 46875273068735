'use strict';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { FETCH_UNUSED_MEMBERSHIPS_SUCCESS, FETCH_UNUSED_MEMBERSHIPS_FAILURE, DELETE_UNUSED_MEMBERSHIPS_SUCCESS, DELETE_UNUSED_MEMBERSHIPS_FAILURE } from 'constants/ActionTypes';
export default function unusedMemberships() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_UNUSED_MEMBERSHIPS_SUCCESS:
    case FETCH_UNUSED_MEMBERSHIPS_FAILURE:
    case DELETE_UNUSED_MEMBERSHIPS_SUCCESS:
    case DELETE_UNUSED_MEMBERSHIPS_FAILURE:
      return _objectSpread({}, state);

    default:
      return state;
  }
}
'use strict';

/*
 * Applies sessionStorage polyfill. Please use instead of window.sessionStorage
 * This is needed for browsers that disable storage.
 *
 * This is meant to not throw errors in those browsers.
 * If you need to store and access data, this needs modification
 */
var polyfill;

try {
  sessionStorage.setItem('test', '1');
  sessionStorage.removeItem('test');
  polyfill = sessionStorage;
} catch (e) {
  /*
   * TypeError handles case when window.sessionStorage is not available
   * QuotaExceededError handles case when browser blocks writing to sessionStorage (e.g. Safari private)
   */
  polyfill = {
    key: function key() {},
    setItem: function setItem() {},
    getItem: function getItem() {},
    removeItem: function removeItem() {},
    clear: function clear() {}
  };
}

var SessionStorage = polyfill;
export default SessionStorage;
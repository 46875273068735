'use strict';

import _isEmpty from "lodash/isEmpty";
import _mapValues from "lodash/mapValues";
import _get from "lodash/get";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import entity from 'reducers/Entity';
import { CREATE_ENTITY_REQUEST, CREATE_ENTITY_SUCCESS, CREATE_ENTITY_FAILURE, FETCH_ENTITY_REQUEST, FETCH_ENTITY_SUCCESS, FETCH_ENTITY_FAILURE, UPDATE_ENTITY_REQUEST, UPDATE_ENTITY_SUCCESS, UPDATE_ENTITY_FAILURE, DELETE_ENTITY_REQUEST, DELETE_ENTITY_SUCCESS, DELETE_ENTITY_FAILURE, CLEAR_ENTITY } from 'constants/ActionTypes';
export var initialState = {
  achievements: {},
  assignments: {},
  assignmentStudents: {},
  bundles: {},
  classes: {},
  contracts: {},
  conversations: {},
  courses: {},
  courseSets: {},
  courseSubsets: {},
  organizationContracts: {},
  organizations: {},
  topics: {},
  statistics: {},
  students: {},
  teachers: {},
  joinClasses: {},
  subjects: {},
  studentsMembership: {},
  selfAdminStudentsMembership: {},
  tags: {},
  conversationUsers: {},
  videos: {}
};

var _entities = function _entities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var responseState = arguments.length > 1 ? arguments[1] : undefined;
  var actionEntities = arguments.length > 2 ? arguments[2] : undefined;

  if (actionEntities) {
    return _objectSpread(_objectSpread({}, state), responseState);
  }

  return state;
}; // Updates an entity cache in response to any action with response.entities or payload.result.entities (for async actions created with asyncFactory).


var entities = function entities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  var actionEntities = _get(action, 'response.entities') || _get(action, 'payload.result.entities');

  if (actionEntities) {
    return _objectSpread(_objectSpread({}, state), _isEmpty(actionEntities) ? _defineProperty({}, action.entityName, _objectSpread(_objectSpread({}, state[action.entityName]), entity({}, action))) // Even there is no response, status should be changed.
    : _mapValues(actionEntities, function (responseState, entityName) {
      if (action.entityName === entityName) {
        var entityState = state[entityName];
        var newState = _isEmpty(entityState) ? responseState : entityState; // Perform single-entity action

        return entity(_objectSpread({}, entity(newState, action)), action);
      }

      return _entities(state[entityName], responseState, actionEntities);
    }));
  }

  var entityName = action.entityName;

  switch (action.type) {
    case CREATE_ENTITY_REQUEST:
    case CREATE_ENTITY_SUCCESS:
    case CREATE_ENTITY_FAILURE:
    case FETCH_ENTITY_REQUEST:
    case FETCH_ENTITY_SUCCESS:
    case FETCH_ENTITY_FAILURE:
    case UPDATE_ENTITY_REQUEST:
    case UPDATE_ENTITY_SUCCESS:
    case UPDATE_ENTITY_FAILURE:
    case DELETE_ENTITY_REQUEST:
    case DELETE_ENTITY_SUCCESS:
    case DELETE_ENTITY_FAILURE:
    case CLEAR_ENTITY:
      if (!entityName) {
        throw new Error("entityName is needed for the action ".concat(action.type));
      }

      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, entityName, entity(state[entityName], action)));

    default:
      return state;
  }
};

export default entities;
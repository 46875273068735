'use strict';

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { FETCH_PEOPLE } from 'constants/ActionTypes';
import API from 'utils/API';
var create = actionCreatorFactory('people');
var createAsync = asyncFactory(actionCreatorFactory());
export var fetchPeople = createAsync(FETCH_PEOPLE, function (_a, _, getState) {
  var organizationId = _a.organizationId,
      fetchPeopleParams = __rest(_a, ["organizationId"]);

  return API.get("/v1/organizations/" + organizationId + "/people", fetchPeopleParams, {
    headers: {
      Authorization: "Bearer " + getState().auth.currentUser.token
    }
  }).then(function (_a) {
    var data = _a.data;
    return data;
  });
});
export var resetPeople = create('RESET');
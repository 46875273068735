'use strict';

import _union from "lodash/union";
import _isArray from "lodash/isArray";
import _findIndex from "lodash/findIndex";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

import { createConversation, deleteConversation, deleteMessage, fetchConversations, markConversationAsRead, readConversation, sendConversationMessage, uploadConversationImage, uploadConversationPdf } from 'actions/Conversations';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
export var initialState = {
  isCreating: false,
  isDeleting: false,
  deleteConversationError: '',
  deleteMessageError: '',
  isFetching: false,
  isUpdating: false,
  items: [],
  error: undefined,
  isSendingMessage: false,
  fileUploads: []
};
export var conversations = reducerWithInitialState(initialState)["case"](fetchConversations.async.started, function (state) {
  return __assign(__assign({}, state), {
    isFetching: true,
    error: undefined
  });
})["case"](fetchConversations.async.failed, function (state, _a) {
  var error = _a.error;
  return __assign(__assign({}, state), {
    isFetching: false,
    error: error
  });
})["case"](fetchConversations.async.done, function (state, _a) {
  var result = _a.result;
  return __assign(__assign({}, state), {
    isFetching: false,
    items: result.result.conversations
  });
})["case"](deleteConversation.async.started, function (state) {
  return __assign(__assign({}, state), {
    isDeleting: true,
    deleteConversationError: ''
  });
})["case"](deleteConversation.async.failed, function (state, _a) {
  var error = _a.error;
  return __assign(__assign({}, state), {
    isDeleting: false,
    deleteConversationError: error.alert
  });
})["case"](deleteConversation.async.done, function (state, payload) {
  return __assign(__assign({}, state), {
    isDeleting: false,
    items: state.items.filter(function (id) {
      return id !== payload.params.conversationId;
    })
  });
})["case"](createConversation.async.started, function (state) {
  return __assign(__assign({}, state), {
    isCreating: true,
    error: undefined
  });
})["case"](createConversation.async.failed, function (state, _a) {
  var error = _a.error;
  return __assign(__assign({}, state), {
    isCreating: false,
    error: error
  });
})["case"](createConversation.async.done, function (state, _a) {
  var result = _a.result.result;
  return __assign(__assign({}, state), {
    isCreating: false,
    items: _union(state.items, _isArray(result.conversation) ? result.conversation : [result.conversation])
  });
})["case"](sendConversationMessage.async.started, function (state) {
  return __assign(__assign({}, state), {
    isSendingMessage: true,
    error: undefined
  });
})["case"](sendConversationMessage.async.failed, function (state, _a) {
  var error = _a.error;
  return __assign(__assign({}, state), {
    isSendingMessage: false,
    error: error
  });
})["case"](sendConversationMessage.async.done, function (state) {
  return __assign(__assign({}, state), {
    isSendingMessage: false
  });
})["case"](deleteMessage.async.started, function (state) {
  return __assign(__assign({}, state), {
    isUpdating: true,
    deleteMessageError: ''
  });
})["case"](deleteMessage.async.done, function (state, _a) {
  var result = _a.result;
  return __assign(__assign({}, state), {
    isUpdating: false,
    items: state.items.filter(function (id) {
      var conversationId = result.result.conversation;
      var conversation = result.entities.conversations[conversationId];
      return id !== conversationId || conversation.messages.length;
    })
  });
})["case"](deleteMessage.async.failed, function (state, _a) {
  var error = _a.error;
  return __assign(__assign({}, state), {
    isUpdating: false,
    deleteMessageError: error.alert
  });
})["case"](uploadConversationImage.async.started, function (state, _a) {
  var image = _a.image;
  return __assign(__assign({}, state), {
    fileUploads: __spreadArrays(state.fileUploads, [image.name])
  });
})["case"](uploadConversationImage.async.done, function (state, _a) {
  var result = _a.result;

  var indexToRemove = _findIndex(state.fileUploads, function (image) {
    return image === result.filename;
  });

  return __assign(__assign({}, state), {
    fileUploads: __spreadArrays(state.fileUploads.slice(0, indexToRemove), state.fileUploads.slice(indexToRemove + 1))
  });
})["case"](uploadConversationImage.async.failed, function (state, _a) {
  var error = _a.error,
      params = _a.params;

  var indexToRemove = _findIndex(state.fileUploads, function (image) {
    return image === params.image.name;
  });

  return __assign(__assign({}, state), {
    error: error,
    fileUploads: __spreadArrays(state.fileUploads.slice(0, indexToRemove), state.fileUploads.slice(indexToRemove + 1))
  });
})["case"](uploadConversationPdf.async.started, function (state, _a) {
  var pdf = _a.pdf;
  return __assign(__assign({}, state), {
    fileUploads: __spreadArrays(state.fileUploads, [pdf.name])
  });
})["case"](uploadConversationPdf.async.done, function (state, _a) {
  var result = _a.result;

  var indexToRemove = _findIndex(state.fileUploads, function (pdf) {
    return pdf === result.filename;
  });

  return __assign(__assign({}, state), {
    fileUploads: __spreadArrays(state.fileUploads.slice(0, indexToRemove), state.fileUploads.slice(indexToRemove + 1))
  });
})["case"](uploadConversationPdf.async.failed, function (state, _a) {
  var error = _a.error,
      params = _a.params;

  var indexToRemove = _findIndex(state.fileUploads, function (pdf) {
    return pdf === params.pdf.name;
  });

  return __assign(__assign({}, state), {
    error: error,
    fileUploads: __spreadArrays(state.fileUploads.slice(0, indexToRemove), state.fileUploads.slice(indexToRemove + 1))
  });
})["case"](readConversation.async.started, function (state) {
  return __assign(__assign({}, state), {
    isUpdating: true,
    error: undefined
  });
})["case"](readConversation.async.done, function (state) {
  return __assign(__assign({}, state), {
    isUpdating: false
  });
})["case"](readConversation.async.failed, function (state, _a) {
  var error = _a.error;
  return __assign(__assign({}, state), {
    isUpdating: false,
    error: error
  });
})["case"](markConversationAsRead.async.started, function (state) {
  return __assign(__assign({}, state), {
    isUpdating: true,
    error: undefined
  });
})["case"](markConversationAsRead.async.done, function (state) {
  return __assign(__assign({}, state), {
    isUpdating: false
  });
})["case"](markConversationAsRead.async.failed, function (state, _a) {
  var error = _a.error;
  return __assign(__assign({}, state), {
    isUpdating: false,
    error: error
  });
});
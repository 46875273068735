'use strict';

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { CREATE_ASSIGNMENTS_DELAYED, CREATE_ASSIGNMENTS_FAILURE, CREATE_ASSIGNMENTS_REQUEST, CREATE_ASSIGNMENTS_SUCCESS, CREATE_ASSIGNMENTS_RESET, DELETE_ASSIGNMENTS_FAILURE, DELETE_ASSIGNMENTS_REQUEST, DELETE_ASSIGNMENTS_SUCCESS, FETCH_ASSIGNMENTS_FAILURE, FETCH_ASSIGNMENTS_REQUEST, FETCH_ASSIGNMENTS_SUCCESS, FETCH_CLASS_ASSIGNMENTS_FAILURE, FETCH_CLASS_ASSIGNMENTS_REQUEST, FETCH_CLASS_ASSIGNMENTS_SUCCESS, SET_CURRENT_ASSIGNMENT, SET_CURRENT_TOPIC, UPDATE_ASSIGNMENTS_FAILURE, UPDATE_ASSIGNMENTS_REQUEST, UPDATE_ASSIGNMENTS_SUCCESS, TOGGLE_UPDATE_TIME_PICKER } from 'constants/ActionTypes';
export var initialState = {
  isFetching: false,
  isUpdating: false,
  items: [],
  isCreating: false,
  isError: false,
  isDelayed: false,
  timePickerProps: false
}; // tslint:disable-next-line no-any

function _assignments(state, action) {
  switch (action.type) {
    case FETCH_ASSIGNMENTS_REQUEST:
      return {
        isFetching: true,
        error: null
      };

    case FETCH_ASSIGNMENTS_SUCCESS:
      return {
        isFetching: false,
        items: action.response.result.assignments,
        totalCount: action.response.total_count,
        page: action.response.page,
        perPage: action.response.per_page
      };

    case FETCH_ASSIGNMENTS_FAILURE:
      return {
        isFetching: false,
        error: action.error,
        items: []
      };

    case FETCH_CLASS_ASSIGNMENTS_REQUEST:
      return {
        isFetching: true,
        error: null
      };

    case FETCH_CLASS_ASSIGNMENTS_SUCCESS:
      return __assign(__assign({}, initialState), {
        isFetching: false,
        items: action.response.result.assignments,
        totalCount: action.response.total_count,
        page: action.response.page,
        perPage: action.response.per_page
      });

    case FETCH_CLASS_ASSIGNMENTS_FAILURE:
      return {
        isFetching: false,
        error: action.error,
        items: []
      };

    case CREATE_ASSIGNMENTS_REQUEST:
      return {
        isCreating: true,
        isError: false
      };

    case CREATE_ASSIGNMENTS_SUCCESS:
      return {
        isCreating: false,
        isDelayed: false
      };

    case CREATE_ASSIGNMENTS_FAILURE:
      return {
        isCreating: false,
        isError: true,
        isDelayed: false
      };

    case CREATE_ASSIGNMENTS_DELAYED:
      return {
        isDelayed: true
      };

    case CREATE_ASSIGNMENTS_RESET:
      return {
        isCreating: false,
        isError: false,
        isDelayed: false
      };

    case SET_CURRENT_ASSIGNMENT:
      return {
        current: action.response.current
      };

    case SET_CURRENT_TOPIC:
      return {
        currentTopic: action.response.currentTopic
      };

    case TOGGLE_UPDATE_TIME_PICKER:
      return {
        timePickerProps: !state.timePickerProps
      };

    default:
      return state;
  }
}

export function assignments(state, action) {
  if (state === void 0) {
    state = initialState;
  }

  switch (action.type) {
    case FETCH_ASSIGNMENTS_REQUEST:
    case FETCH_ASSIGNMENTS_SUCCESS:
    case FETCH_ASSIGNMENTS_FAILURE:
    case FETCH_CLASS_ASSIGNMENTS_REQUEST:
    case FETCH_CLASS_ASSIGNMENTS_SUCCESS:
    case FETCH_CLASS_ASSIGNMENTS_FAILURE:
    case CREATE_ASSIGNMENTS_REQUEST:
    case CREATE_ASSIGNMENTS_SUCCESS:
    case CREATE_ASSIGNMENTS_FAILURE:
    case CREATE_ASSIGNMENTS_DELAYED:
    case CREATE_ASSIGNMENTS_RESET:
    case SET_CURRENT_ASSIGNMENT:
    case SET_CURRENT_TOPIC:
      return __assign(__assign({}, state), _assignments(state, action));

    case UPDATE_ASSIGNMENTS_REQUEST:
      return __assign(__assign({}, state), {
        isUpdating: true,
        isError: false
      });

    case UPDATE_ASSIGNMENTS_SUCCESS:
      return __assign(__assign({}, state), {
        isUpdating: false
      });

    case UPDATE_ASSIGNMENTS_FAILURE:
      return __assign(__assign({}, state), {
        isUpdating: false,
        isError: true
      });

    case DELETE_ASSIGNMENTS_REQUEST:
      return __assign(__assign({}, state), {
        isUpdating: true,
        isError: false
      });

    case DELETE_ASSIGNMENTS_SUCCESS:
      return __assign(__assign({}, state), {
        isUpdating: false
      });

    case DELETE_ASSIGNMENTS_FAILURE:
      return __assign(__assign({}, state), {
        isUpdating: false,
        isError: true
      });

    case TOGGLE_UPDATE_TIME_PICKER:
      return __assign(__assign({}, state), {
        timePickerProps: !state.timePickerProps
      });

    default:
      return state;
  }
}
'use strict';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { FETCH_GRADES_FAILURE, FETCH_GRADES_REQUEST, FETCH_GRADES_SUCCESS } from 'constants/ActionTypes';
var initialState = {
  isFetching: false,
  didInvalidate: false,
  items: {}
};

function _grades() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_GRADES_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        didInvalidate: true
      });

    case FETCH_GRADES_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        didInvalidate: false,
        isFetching: true
      });

    case FETCH_GRADES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        didInvalidate: false,
        isFetching: false,
        items: action.grades
      });

    default:
      return state;
  }
}

export default function grades() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_GRADES_FAILURE:
    case FETCH_GRADES_SUCCESS:
    case FETCH_GRADES_REQUEST:
      return _objectSpread(_objectSpread({}, state), _grades(state, action));

    default:
      return state;
  }
}
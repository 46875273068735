'use strict';

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { schema } from 'normalizr';
var conversationUserSchema = new schema.Entity('conversationUsers', {}, {
  processStrategy: function processStrategy(value, _, key) {
    return __assign(__assign({}, value), {
      is_manager: key === 'managers'
    });
  }
});
export var conversationUserArraySchema = new schema.Array(conversationUserSchema);
'use strict';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { AUTHENTICATE_USER_REQUEST, AUTHENTICATE_USER_SUCCESS, AUTHENTICATE_USER_FAILURE, FACEBOOK_CONNECT_REQUEST, UPDATE_AUTH_CURRENT_USER } from 'constants/ActionTypes';
var initialState = {
  authenticationStatus: 'idle' // idle, pending, succeed, failed

};

function _auth() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case AUTHENTICATE_USER_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        authenticationStatus: 'pending'
      });

    case AUTHENTICATE_USER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        authenticationStatus: 'succeed',
        facebookConnectRequest: false,
        currentUser: action.currentUser
      });

    case AUTHENTICATE_USER_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        authenticationStatus: 'failed',
        errorMessage: action.errorMessage
      });

    case FACEBOOK_CONNECT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        authenticationStatus: 'idle',
        facebookConnectRequest: true,
        facebookToken: action.fbToken
      });

    case UPDATE_AUTH_CURRENT_USER:
      return _objectSpread(_objectSpread({}, state), {}, {
        currentUser: action.currentUser
      });

    default:
      return state;
  }
}

export default function auth() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case AUTHENTICATE_USER_REQUEST:
    case AUTHENTICATE_USER_SUCCESS:
    case AUTHENTICATE_USER_FAILURE:
    case FACEBOOK_CONNECT_REQUEST:
    case UPDATE_AUTH_CURRENT_USER:
      return _objectSpread(_objectSpread({}, state), _auth(state, action));

    default:
      return state;
  }
}
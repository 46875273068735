'use strict';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { FETCH_ROSTERS_FAILURE, FETCH_ROSTERS_REQUEST, FETCH_ROSTERS_SUCCESS, DOWNLOAD_CSV_REQUEST, DOWNLOAD_CSV_SUCCESS, DOWNLOAD_CSV_FAILURE, UPLOAD_CSV_REQUEST, UPLOAD_CSV_SUCCESS, UPLOAD_CSV_FAILURE, RESET_CSV_ALERT_MESSAGE } from 'constants/ActionTypes';
var initialState = {
  isFetching: false,
  didInvalidate: false,
  items: {},
  csvDownloadRequesting: false,
  csvDownloadRequestSuccess: false,
  csvDownloadRequestFailure: false,
  csvAlertMessageEmail: '',
  csvUploadRequesting: false,
  csvUploadRequestSuccess: false,
  csvUploadRequestFailure: false,
  csvAlertMessage: '',
  uploadCsvStatus: ''
};

function _rosters() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_ROSTERS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        didInvalidate: true
      });

    case FETCH_ROSTERS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        didInvalidate: false,
        isFetching: true
      });

    case FETCH_ROSTERS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        didInvalidate: false,
        isFetching: false,
        items: action.rosters
      });

    case DOWNLOAD_CSV_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        csvDownloadRequesting: true
      });

    case DOWNLOAD_CSV_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        csvDownloadRequesting: false,
        csvDownloadRequestSuccess: true,
        csvDownloadRequestFailure: false,
        csvAlertMessageEmail: action.csvAlertMessageEmail
      });

    case DOWNLOAD_CSV_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        csvDownloadRequesting: false,
        csvDownloadRequestSuccess: false,
        csvDownloadRequestFailure: true,
        csvAlertMessageEmail: ''
      });

    case UPLOAD_CSV_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        csvUploadRequesting: true
      });

    case UPLOAD_CSV_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        csvUploadRequesting: false,
        csvUploadRequestSuccess: true,
        csvUploadRequestFailure: false,
        csvAlertMessage: action.data.message,
        uploadCsvStatus: action.data.status
      });

    case UPLOAD_CSV_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        csvUploadRequesting: false,
        csvUploadRequestSuccess: false,
        csvUploadRequestFailure: true,
        csvAlertMessage: action.message
      });

    case RESET_CSV_ALERT_MESSAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        csvDownloadRequesting: false,
        csvDownloadRequestSuccess: false,
        csvDownloadRequestFailure: false,
        csvAlertMessageEmail: '',
        csvUploadRequesting: false,
        csvUploadRequestSuccess: false,
        csvUploadRequestFailure: false,
        csvAlertMessage: '',
        uploadCsvStatus: ''
      });

    default:
      return state;
  }
}

export default function rosters() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_ROSTERS_FAILURE:
    case FETCH_ROSTERS_SUCCESS:
    case FETCH_ROSTERS_REQUEST:
    case DOWNLOAD_CSV_REQUEST:
    case DOWNLOAD_CSV_SUCCESS:
    case DOWNLOAD_CSV_FAILURE:
    case UPLOAD_CSV_REQUEST:
    case UPLOAD_CSV_SUCCESS:
    case UPLOAD_CSV_FAILURE:
    case RESET_CSV_ALERT_MESSAGE:
      return _objectSpread(_objectSpread({}, state), _rosters(state, action));

    default:
      return state;
  }
}
'use strict';

import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import API from 'utils/API';
var createAsync = asyncFactory(actionCreatorFactory());
export var getTeacherProfile = createAsync('GET_TEACHER_PROFILE', function (params) {
  return API.get('/api/aya/teacher_profile', {
    organization_id: params.organization_id
  });
});
export var updateTeacherProfile = createAsync('UPDATE_TEACHER_PROFILE', function (params) {
  return API.put('/api/aya/teacher_profile', params);
});
'use strict';

import { ASSESSMENT_RESET_SELECTED_TOPIC, ASSESSMENT_UPDATE_SEARCH_COURSES_KEYWORD, ASSESSMENT_UPDATE_ACTIVE_STEP, ASSESSMENT_UPDATE_SELECTED_COURSES_SOURCE, ASSESSMENT_UPDATE_SELECTED_TOPIC } from 'constants/ActionTypes';
import actionCreatorFactory from 'typescript-fsa';
var actionCreator = actionCreatorFactory();
export var assessmentUpdateActiveStep = actionCreator(ASSESSMENT_UPDATE_ACTIVE_STEP);
export var assessmentUpdateSelectedTopic = actionCreator(ASSESSMENT_UPDATE_SELECTED_TOPIC);
export var assessmentResetSelectedTopic = actionCreator(ASSESSMENT_RESET_SELECTED_TOPIC);
export var assessmentUpdateSelectedCoursesSource = actionCreator(ASSESSMENT_UPDATE_SELECTED_COURSES_SOURCE);
export var assessmentUpdateSearchCoursesKeyword = actionCreator(ASSESSMENT_UPDATE_SEARCH_COURSES_KEYWORD);
export default {
  assessmentUpdateActiveStep: assessmentUpdateActiveStep,
  assessmentUpdateSelectedTopic: assessmentUpdateSelectedTopic,
  assessmentResetSelectedTopic: assessmentResetSelectedTopic,
  assessmentUpdateSelectedCoursesSource: assessmentUpdateSelectedCoursesSource,
  assessmentUpdateSearchCoursesKeyword: assessmentUpdateSearchCoursesKeyword
};
'use strict';

import { TOGGLE_LOCALE_MENU } from 'constants/ActionTypes';
var initialState = {
  dropdownOpen: false
};
export default function localeMenu() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case TOGGLE_LOCALE_MENU:
      return {
        dropdownOpen: !state.dropdownOpen
      };

    default:
      return state;
  }
}
'use strict';

import { normalize } from 'normalizr';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { FETCH_USER_CLASSES } from 'constants/ActionTypes';
import { userClassArraySchema } from 'schemas/userClasses';
import API from 'utils/API';
import I18n from 'utils/I18n';
var createAsync = asyncFactory(actionCreatorFactory());
export var fetchUserClasses = createAsync(FETCH_USER_CLASSES, function (_a) {
  var organizationId = _a.organizationId;
  return API.get('/api/groups', I18n.isSiteModeQS() && {
    organization_id: organizationId
  }).then(function (_a) {
    var data = _a.data;
    var normalizedUserClasses = normalize({
      userClasses: data
    }, {
      userClasses: userClassArraySchema
    });
    return normalizedUserClasses;
  });
});
'use strict';

import isEmpty from 'lodash/isEmpty';
import API from 'utils/API';
import { FETCH_UNREAD_MESSAGES_COUNT_REQUEST, FETCH_UNREAD_MESSAGES_COUNT_SUCCESS, FETCH_UNREAD_MESSAGES_COUNT_FAILURE, UPDATE_UNREAD_MESSAGES_COUNT } from 'constants/ActionTypes';
export function requestUnreadMessagesCount() {
  return {
    type: FETCH_UNREAD_MESSAGES_COUNT_REQUEST
  };
}
export function receiveUnreadMessagesCount(_ref) {
  var unreadMessagesCount = _ref.unread_messages_count;
  return {
    type: FETCH_UNREAD_MESSAGES_COUNT_SUCCESS,
    unreadMessagesCount: unreadMessagesCount
  };
}
export function fetchUnreadMessagesCountFailure() {
  return {
    type: FETCH_UNREAD_MESSAGES_COUNT_FAILURE
  };
}
export function updateUnreadMessagesCount(conversations) {
  return {
    type: UPDATE_UNREAD_MESSAGES_COUNT,
    conversations: conversations
  };
}
export function fetchUnreadMessagesCount() {
  return function (dispatch) {
    dispatch(requestUnreadMessagesCount());
    return API.get('/api/messages/unread_messages_count').then(function (response) {
      return dispatch(receiveUnreadMessagesCount(response.data));
    }, function () {
      return dispatch(fetchUnreadMessagesCountFailure());
    });
  };
}

function shouldFetchUnreadMessagesCount(state) {
  var messages = state.messages;

  if (isEmpty(messages)) {
    return true;
  }

  if (messages.isFetching) {
    return false;
  }

  return true;
}

export function fetchUnreadMessagesCountIfNeeded() {
  return function (dispatch, getState) {
    if (shouldFetchUnreadMessagesCount(getState())) {
      return dispatch(fetchUnreadMessagesCount());
    }

    return false;
  };
}
'use strict';

import _omit from "lodash/omit";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { schema } from 'normalizr';
var messageSchema = new schema.Entity('messages', {}, {
  // Convert from Unix Epoch time to JavaScript milliseconds
  processStrategy: function processStrategy(message) {
    var createdAtMs = message.created_ts * 1000;

    var processedMessage = _omit(message, 'created_ts');

    return __assign({
      created_at_ms: createdAtMs
    }, processedMessage);
  }
});
export var messageArraySchema = new schema.Array(messageSchema);
'use strict';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { INVALIDATE_STUDENTS_MEMBERSHIP, CLEAR_STUDENTS_MEMBERSHIP, FETCH_STUDENTS_MEMBERSHIP_FAILURE, FETCH_STUDENTS_MEMBERSHIP_REQUEST, FETCH_STUDENTS_MEMBERSHIP_SUCCESS, UPDATE_STUDENTS_MEMBERSHIP_FAILURE, UPDATE_STUDENTS_MEMBERSHIP_REQUEST, UPDATE_STUDENTS_MEMBERSHIP_SUCCESS } from 'constants/ActionTypes';
/*
  NOTE: This is different from the reducers/Students.js
  since the students here rely on `Aya::OrganizationMembership`
  */

var initialState = {
  isFetching: false,
  items: [],
  error: null
};

function _studentsMembership() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_STUDENTS_MEMBERSHIP_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true
      });

    case FETCH_STUDENTS_MEMBERSHIP_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        error: action.error
      });

    case FETCH_STUDENTS_MEMBERSHIP_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        valid: true,
        items: action.response.result.studentsMembership,
        page: action.response.page,
        per_page: action.response.per_page,
        total_count: action.response.total_count
      });

    case UPDATE_STUDENTS_MEMBERSHIP_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isUpdating: true
      });

    case UPDATE_STUDENTS_MEMBERSHIP_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isUpdating: false
      });

    case UPDATE_STUDENTS_MEMBERSHIP_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isUpdating: false,
        error: action.error
      });

    case INVALIDATE_STUDENTS_MEMBERSHIP:
      return _objectSpread(_objectSpread({}, state), {}, {
        valid: false
      });

    case CLEAR_STUDENTS_MEMBERSHIP:
      return _objectSpread(_objectSpread({}, state), {}, {
        items: []
      });

    default:
      return state;
  }
}

export default function studentsMembership() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case INVALIDATE_STUDENTS_MEMBERSHIP:
    case CLEAR_STUDENTS_MEMBERSHIP:
    case FETCH_STUDENTS_MEMBERSHIP_FAILURE:
    case FETCH_STUDENTS_MEMBERSHIP_SUCCESS:
    case FETCH_STUDENTS_MEMBERSHIP_REQUEST:
    case UPDATE_STUDENTS_MEMBERSHIP_REQUEST:
    case UPDATE_STUDENTS_MEMBERSHIP_SUCCESS:
    case UPDATE_STUDENTS_MEMBERSHIP_FAILURE:
      return _objectSpread(_objectSpread({}, state), _studentsMembership(state, action));

    default:
      return state;
  }
}
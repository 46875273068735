'use strict';

import _includes from "lodash/includes";
import _filter from "lodash/filter";
import _mapValues from "lodash/mapValues";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { COMMIT_CONTRACTS_REQUEST, COMMIT_CONTRACTS_SUCCESS, COMMIT_CONTRACTS_FAILURE, MOVE_CONTRACTS, CLEAR_MOVE_CONTRACTS } from 'constants/ActionTypes';
var initialState = {
  moveItems: {},
  isCreating: false,
  isError: false
};

function _contracts(state, action) {
  var moveItems = state.moveItems;
  var type = action.type,
      contractType = action.contractType,
      organizationMembershipIds = action.organizationMembershipIds;

  switch (type) {
    case COMMIT_CONTRACTS_REQUEST:
      return {
        isCreating: true,
        isError: false
      };

    case COMMIT_CONTRACTS_SUCCESS:
      return {
        isCreating: false
      };

    case COMMIT_CONTRACTS_FAILURE:
      return {
        isCreating: false,
        isError: true
      };

    case MOVE_CONTRACTS:
      {
        var filteredMoveItems = _mapValues(moveItems, function (itemByContractType) {
          return _filter(itemByContractType, function (item) {
            return !_includes(organizationMembershipIds, item);
          });
        });

        var currentContract = moveItems[contractType] || [];
        return _objectSpread(_objectSpread({}, state), {}, {
          moveItems: _objectSpread(_objectSpread({}, filteredMoveItems), {}, _defineProperty({}, contractType, [].concat(_toConsumableArray(currentContract), _toConsumableArray(organizationMembershipIds))))
        });
      }

    case CLEAR_MOVE_CONTRACTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        moveItems: {}
      });

    default:
      return state;
  }
}

var contracts = function contracts() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case COMMIT_CONTRACTS_REQUEST:
    case COMMIT_CONTRACTS_SUCCESS:
    case COMMIT_CONTRACTS_FAILURE:
    case MOVE_CONTRACTS:
    case CLEAR_MOVE_CONTRACTS:
      return _objectSpread(_objectSpread({}, state), _contracts(state, action));

    default:
      return state;
  }
};

export default contracts;
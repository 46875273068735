'use strict';

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { fetchStatistics, fetchStatisticsForOrganizationStudents, requestDownloadStatistics } from 'actions/Statistics';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
export var initialState = {
  isFetching: false,
  isRequestingDownload: false,
  downloadRequestError: undefined,
  error: undefined,
  items: [],
  pagination: {
    totalRecords: 0,
    currentPage: 0,
    perPage: 50
  }
};
export var statistics = reducerWithInitialState(initialState)["case"](fetchStatistics.async.started, function (state) {
  return __assign(__assign({}, state), {
    isFetching: true,
    error: undefined
  });
})["case"](fetchStatistics.async.failed, function (state, _a) {
  var error = _a.error;
  return __assign(__assign({}, state), {
    isFetching: false,
    error: error
  });
})["case"](fetchStatistics.async.done, function (state, _a) {
  var data = _a.result;
  return __assign(__assign({}, state), {
    isFetching: false,
    items: data.result.statistics,
    pagination: data.pagination
  });
})["case"](requestDownloadStatistics.async.started, function (state) {
  return __assign(__assign({}, state), {
    isRequestingDownload: true,
    error: undefined
  });
})["case"](requestDownloadStatistics.async.failed, function (state, _a) {
  var error = _a.error;
  return __assign(__assign({}, state), {
    isRequestingDownload: false,
    downloadRequestError: error
  });
})["case"](requestDownloadStatistics.async.done, function (state) {
  return __assign(__assign({}, state), {
    isRequestingDownload: false
  });
});
export var statisticsForOrganizationStudentsInitialState = {
  isFetching: false,
  error: undefined,
  items: [],
  pagination: {
    totalRecords: 0,
    currentPage: 0,
    perPage: 50
  }
};
export var statisticsForOrganizationStudents = reducerWithInitialState(statisticsForOrganizationStudentsInitialState)["case"](fetchStatisticsForOrganizationStudents.async.started, function (state) {
  return __assign(__assign({}, state), {
    isFetching: true,
    error: undefined
  });
})["case"](fetchStatisticsForOrganizationStudents.async.failed, function (state, _a) {
  var error = _a.error;
  return __assign(__assign({}, state), {
    isFetching: false,
    error: error
  });
})["case"](fetchStatisticsForOrganizationStudents.async.done, function (state, _a) {
  var data = _a.result;
  return __assign(__assign({}, state), {
    isFetching: false,
    items: data.result.statisticsForOrganizationStudents,
    pagination: data.pagination
  });
});
'use strict';

// tslint:disable:no-any
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import axios from 'axios';
import I18n from 'utils/I18n';
axios.defaults.baseURL = '';
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';

function addLocaleIfNeeded(path) {
  // Lowercase locale because API expects it to be lowercase
  // https://github.com/quipper/monorepo/blob/develop/qlink/config/routes.rb#L6
  var locale = path.split('/')[1];

  if (I18n.isLocaleValid(locale)) {
    return path;
  }

  return "/" + I18n.currentLocale() + path;
} // The apis of qlink are usually like this.
// "/:locale/api/authorizations.json"
// This API classes automatically add the locale so path needs to be '/api/authorizations.json'


var API =
/** @class */
function () {
  function API() {}

  API.get = function (path, params, options) {
    if (params === void 0) {
      params = {};
    }

    if (options === void 0) {
      options = {};
    }

    return axios.get(addLocaleIfNeeded(path), __assign({
      withCredentials: true,
      params: params
    }, options));
  };

  API.post = function (path, data, options) {
    if (data === void 0) {
      data = {};
    }

    if (options === void 0) {
      options = {};
    }

    return axios.post(addLocaleIfNeeded(path), data, __assign({
      withCredentials: true
    }, options));
  };

  API.put = function (path, data, options) {
    if (data === void 0) {
      data = {};
    }

    if (options === void 0) {
      options = {};
    }

    return axios.put(addLocaleIfNeeded(path), data, __assign({
      withCredentials: true
    }, options));
  };

  API["delete"] = function (path, data, options) {
    if (data === void 0) {
      data = {};
    }

    if (options === void 0) {
      options = {};
    }

    return axios["delete"](addLocaleIfNeeded(path), __assign({
      withCredentials: true,
      data: data
    }, options));
  };

  API.CancelToken = axios.CancelToken;

  API.isCancel = function (error) {
    return axios.isCancel(error);
  };

  return API;
}();

export default API;
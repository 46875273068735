'use strict';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { FETCH_TEACHERS_FAILURE, FETCH_TEACHERS_REQUEST, FETCH_TEACHERS_SUCCESS, DELETE_TEACHER_REQUEST, DELETE_TEACHER_SUCCESS, DELETE_TEACHER_FAILURE, UPDATE_TEACHER_REQUEST, UPDATE_TEACHER_SUCCESS, UPDATE_TEACHER_FAILURE, CREATE_TEACHER_REQUEST, CREATE_TEACHER_SUCCESS, CREATE_TEACHER_FAILURE, CREATE_TEACHER_INIT, INVALIDATE_TEACHERS, RESET_PASSWORD_TEACHERS_REQUEST, RESET_PASSWORD_TEACHERS_SUCCESS, RESET_PASSWORD_TEACHERS_FAILURE } from 'constants/ActionTypes';
var initialState = {
  isFetching: false,
  isCreating: false,
  created: false,
  createErrors: {},
  didInvalidate: false,
  items: []
};

function _teachers(state, action) {
  switch (action.type) {
    case FETCH_TEACHERS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        didInvalidate: false
      });

    case FETCH_TEACHERS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        didInvalidate: false,
        isFetching: true
      });

    case FETCH_TEACHERS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        didInvalidate: true,
        items: action.response.result.teachers
      });

    case DELETE_TEACHER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isSubmitting: false,
        items: state.items.filter(function (teacher) {
          return teacher.id !== action.teacherId;
        })
      });

    case INVALIDATE_TEACHERS:
      return _objectSpread(_objectSpread({}, state), {}, {
        didInvalidate: false
      });

    case CREATE_TEACHER_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isCreating: true,
        created: false,
        createErrors: {}
      });

    case CREATE_TEACHER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isCreating: false,
        created: true,
        createErrors: {}
      });

    case CREATE_TEACHER_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isCreating: false,
        created: false,
        createErrors: action.createErrors
      });

    case CREATE_TEACHER_INIT:
      return _objectSpread(_objectSpread({}, state), {}, {
        created: false,
        createErrors: {}
      });

    case DELETE_TEACHER_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isSubmitting: false
      });

    case DELETE_TEACHER_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isSubmitting: true
      });

    case UPDATE_TEACHER_SUCCESS:
    case UPDATE_TEACHER_REQUEST:
    case UPDATE_TEACHER_FAILURE:
    default:
      return state;
  }
}

export default function teachers() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_TEACHERS_FAILURE:
    case FETCH_TEACHERS_SUCCESS:
    case FETCH_TEACHERS_REQUEST:
    case DELETE_TEACHER_FAILURE:
    case DELETE_TEACHER_SUCCESS:
    case DELETE_TEACHER_REQUEST:
    case UPDATE_TEACHER_REQUEST:
    case UPDATE_TEACHER_SUCCESS:
    case UPDATE_TEACHER_FAILURE:
    case CREATE_TEACHER_SUCCESS:
    case CREATE_TEACHER_REQUEST:
    case CREATE_TEACHER_FAILURE:
    case CREATE_TEACHER_INIT:
    case INVALIDATE_TEACHERS:
    case RESET_PASSWORD_TEACHERS_REQUEST:
    case RESET_PASSWORD_TEACHERS_SUCCESS:
    case RESET_PASSWORD_TEACHERS_FAILURE:
      return _objectSpread(_objectSpread({}, state), _teachers(state, action));

    default:
      return state;
  }
}
import I18n from 'utils/I18n'
import config from 'config'
import { includes } from 'lodash'

export const locales = () => (
  I18n.isSiteModeAya()
    ? [config.aya_lang]
    /*
     * When directories are added under locales, it would be treated as a locale that we handle.
     * That being said, private use subtag (RFC 5646: https://tools.ietf.org/html/rfc5646#section-2.2.7) would be rejected
     *
     * So `['en', 'en-MX', 'ja']` would be returned following cases.
     *
     * $ tree -d locales
     * locales
     * ├── en
     * ├── en-base
     * ├── en-MX
     * ├── ja
     * ├── ja-x-foo
     * └── ja-x-bar
     */
    : getAllLocales()
)

export const getAllLocales = () => (
  require // eslint-disable-line global-require
    .context('locales', true, /\.json$/)
    .keys()
    .map(file => file.split('/')[1])
    .filter(ext => !includes(['th','vi'], ext)) //filter to not show VI or TH locales
    .filter(path => !path.match(/\-x\-/i))
)

'use strict';

import _identity from "lodash/identity";
import _omit from "lodash/omit";
import _isEmpty from "lodash/isEmpty";
import _renderComponent from "recompose/renderComponent";
import _lifecycle from "recompose/lifecycle";
import _mapProps from "recompose/mapProps";
import _branch from "recompose/branch";
import _compose from "recompose/compose";
// Requires authentication to access SecureComponent
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { authenticateByToken } from 'actions/Auth';
import Preloader from 'components/base/Preloader';
export var mapStateToProps = function mapStateToProps(state) {
  var _ref = _isEmpty(state.auth) ? {
    authenticationStatus: 'idle',
    currentUser: null
  } : state.auth,
      authenticationStatus = _ref.authenticationStatus,
      currentUser = _ref.currentUser;

  return {
    authenticationStatus: authenticationStatus,
    currentUser: currentUser
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    authenticateByToken: bindActionCreators(authenticateByToken, dispatch)
  };
};

var requireAuthentication = function requireAuthentication(SecureComponent) {
  var isAuthenticated = function isAuthenticated(_ref2) {
    var authenticationStatus = _ref2.authenticationStatus,
        currentUser = _ref2.currentUser;
    return authenticationStatus === 'succeed' && !!currentUser;
  };

  return _compose(withRouter, connect(mapStateToProps, mapDispatchToProps), _lifecycle({
    componentDidMount: function componentDidMount() {
      // pathname is like "/ja/organizations/1234567890abcdefg/dashboard"
      var organizationId = this.props.match.params.organizationId;

      if (!isAuthenticated(this.props)) {
        this.props.authenticateByToken(organizationId);
      }
    }
  }), _mapProps(function (props) {
    return _omit(props, ['authenticateByToken']);
  }), _branch(isAuthenticated, _identity, _renderComponent(Preloader)))(SecureComponent);
};

export default requireAuthentication;
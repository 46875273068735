'use strict';

import { normalize } from 'normalizr';
import { studentSchema } from 'schemas/students';
export var requestStudentDetails = function requestStudentDetails(type, studentId) {
  return {
    type: type,
    entityName: 'students',
    entityId: studentId
  };
};
export var failedStudentDetails = function failedStudentDetails(type, studentId, error) {
  return {
    type: type,
    entityName: 'students',
    entityId: studentId,
    error: error
  };
};
export var receiveStudentDetails = function receiveStudentDetails(type, student) {
  var response = normalize(student, studentSchema);
  return {
    type: type,
    entityName: 'students',
    entityId: student.id,
    entity: response.entities.students[student.id],
    response: response
  };
};
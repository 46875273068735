'use strict';

import _isEmpty from "lodash/isEmpty";
import _omit from "lodash/omit";
import _withHandlers from "recompose/withHandlers";
import _mapProps from "recompose/mapProps";
import _compose from "recompose/compose";
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';
import SentryHelper from 'utils/SentryHelper';
/*
 * HOC for sending tracking event to Treasure Data
 */

var mapStateToProps = function mapStateToProps(state) {
  var currentUser = state.auth.currentUser;
  return {
    currentUser: currentUser
  };
};

var doTrackEvent = function doTrackEvent(props, eventName, params) {
  if (process.env.APP_CONFIG.site_env === 'development') {
    /* eslint-disable no-console */
    console.log('Sent tdTrackEvent with', props.currentUser.id, eventName, params);
  } else if (!_isEmpty(tdClient)) {
    try {
      tdClient.trackEvent(props.currentUser.id, eventName, params);
    } catch (err) {
      if (SentryHelper.isSentrySupport()) {
        Sentry.captureException(err);
      }
    }
  }
};

export var handlers = {
  trackEvent: function trackEvent(props) {
    return function (eventName) {
      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      doTrackEvent(props, eventName, params);
    };
  },
  trackPageEvent: function trackPageEvent(props) {
    return function (actionName) {
      var actionParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      doTrackEvent(props, 'qlink_pageevents', {
        action_name: actionName,
        action_params: actionParams
      });
    };
  }
};

var tdTrackEvent = function tdTrackEvent() {
  return _compose(connect(mapStateToProps, null), _withHandlers(handlers), _mapProps(function (props) {
    return _omit(props, ['currentUser']);
  }));
};

export default tdTrackEvent;
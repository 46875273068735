'use strict';

import _orderBy from "lodash/orderBy";
import _remove from "lodash/remove";
import _mapProps from "recompose/mapProps";
import _lifecycle from "recompose/lifecycle";
import _compose from "recompose/compose";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import omit from 'lodash/omit';
import { denormalize } from 'normalizr';
import { withRouter } from 'react-router-dom';
import MainView from 'components/organizations/switcher/Main';
import { changeOrganization } from 'actions/Organizations';
import { organizationArraySchema } from 'schemas/organizations';
export function mapStateToProps(state, ownProps) {
  var items = state.organizations.items; // remove current organization id from items

  if (items && ownProps.currentOrganization) {
    _remove(items, function (item) {
      return item === ownProps.currentOrganization.id;
    });
  }

  var currentOrganization = state.currentOrganization && state.currentOrganization.organization ? state.currentOrganization.organization : ownProps.currentOrganization;
  var currentUser = state.auth.currentUser;
  var organizations = denormalize(items || [], organizationArraySchema, state.entities);
  organizations = _orderBy(organizations, [function (org) {
    return org.name.toLowerCase();
  }]);
  return {
    currentUser: currentUser,
    currentOrganization: currentOrganization,
    organizations: organizations
  };
}

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    changeOrganization: bindActionCreators(changeOrganization, dispatch)
  };
};

export default _compose(connect(mapStateToProps, mapDispatchToProps), withRouter, _lifecycle({
  componentDidUpdate: function componentDidUpdate(prevProps) {
    var currentOrganization = this.props.currentOrganization;

    if (prevProps.currentOrganization && prevProps.currentOrganization.id !== currentOrganization.id) {
      // When changing organization, it should navigate to path of the other organization
      if (currentOrganization) {
        this.props.history.push("/organizations/".concat(currentOrganization.id, "/dashboard"));
      }
    }
  }
}), _mapProps(function (props) {
  return omit(props, ['currentUser']);
}))(MainView);
'use strict';

import _compose from "recompose/compose";
import _defaultProps from "recompose/defaultProps";
import _withProps from "recompose/withProps";
import _mapProps from "recompose/mapProps";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import { bindActionCreators } from 'redux';
import cookie from 'cookie_js';
import { connect } from 'react-redux';
import toggleLocaleMenu from 'actions/LocaleMenu';
import isEmpty from 'lodash/isEmpty';
import I18n from 'utils/I18n';
import Browser from 'utils/Browser';
import UrlHelper from 'utils/UrlHelper';
import LocaleMenuView from 'components/footer/locale_menu/LocaleMenuView';
export function mapStateToProps(state) {
  var _ref = isEmpty(state.localeMenu) ? {
    dropdownOpen: false
  } : state.localeMenu,
      dropdownOpen = _ref.dropdownOpen;

  return {
    dropdownOpen: dropdownOpen
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggle: bindActionCreators(toggleLocaleMenu, dispatch)
  };
}

export default _compose(connect(mapStateToProps, mapDispatchToProps), _defaultProps({
  languages: [{
    id: 'en',
    name: 'English',
    sprite: 'flag-uk'
  }, {
    id: 'id',
    name: 'Bahasa Indonesia',
    sprite: 'flag-indonesia'
  }]
}), _withProps(function () {
  return {
    onItemClicked: function onItemClicked(e) {
      var locale = e.target.getAttribute('data-id');
      cookie.set('language', locale, {
        path: '/',
        domain: Browser.getRootDomain()
      });

      var _Browser$pathname$spl = Browser.pathname().split('/'),
          _Browser$pathname$spl2 = _toArray(_Browser$pathname$spl),
          rest = _Browser$pathname$spl2.slice(2); // Ignoring some returned values: https://goo.gl/tHaKPw


      var path = UrlHelper.buildPath({
        pathname: [locale].concat(_toConsumableArray(rest)).join('/')
      }); // Take over path like '/en/foo/bar#baz?qux=1' => /ja/foo/bar#baz?qux=1'

      Browser.setWindowHref(path);
    }
  };
}), _mapProps(function (props) {
  return _objectSpread(_objectSpread({}, props), {}, {
    getCurrentLanguage: function getCurrentLanguage() {
      return props.languages.find(function (language) {
        return language.id === I18n.currentLocale();
      });
    }
  });
}))(LocaleMenuView);
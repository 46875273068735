'use strict';

import _last from "lodash/last";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

import { normalize } from 'normalizr';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { setCurrentConversationId } from 'actions/Conversation';
import { uploadFile, uploadFileRequest } from 'actions/File';
import { updateUnreadMessagesCount } from 'actions/Messages';
import { hideModal, updateModal } from 'actions/Modal';
import { CREATE_CONVERSATION, DELETE_CONVERSATION, DELETE_MESSAGE, FETCH_CONVERSATIONS, FETCH_MESSAGES, MARK_CONVERSATION_AS_READ, READ_CONVERSATION, SEND_CONVERSATION_MESSAGE, UPLOAD_CONVERSATION_IMAGE, UPLOAD_CONVERSATION_PDF } from 'constants/ActionTypes';
import { conversationArraySchema, conversationSchema } from 'schemas/conversations';
import API from 'utils/API';
import I18n from 'utils/I18n';
var createAsync = asyncFactory(actionCreatorFactory());
export var fetchConversations = createAsync(FETCH_CONVERSATIONS, function (_a, _dispatch, getState) {
  var organizationId = _a.organizationId;
  var res = getState().auth.currentUser.coaching ? API.get('/api/coaching/conversations') : API.get('/api/conversations', I18n.isSiteModeQS() && {
    organization_id: organizationId
  });
  return res.then(function (_a) {
    var data = _a.data;
    var normalizedConversations = normalize({
      conversations: data
    }, {
      conversations: conversationArraySchema
    });
    return normalizedConversations;
  });
});
export var fetchMessages = createAsync(FETCH_MESSAGES, function (_a, _dispatch, getState) {
  var conversationId = _a.conversationId;
  var res = getState().auth.currentUser.coaching ? API.get("/api/coaching/conversations/" + conversationId) : API.get("/api/conversations/" + conversationId);
  return res.then(function (_a) {
    var data = _a.data;
    var normalizedConversation = normalize({
      conversation: data
    }, {
      conversation: conversationSchema
    }); // Resolves data inconsistencies with coaching endpoint and original endpoint
    // https://github.com/quipper/monorepo/pull/9965#issuecomment-531307269

    if (getState().auth.currentUser.coaching) {
      var conversation = getState().entities.conversations[conversationId];
      var last_sender_id = conversation.last_sender_id,
          last_sender_role = conversation.last_sender_role,
          unread = conversation.unread,
          updated_at_ms = conversation.updated_at_ms;
      normalizedConversation = normalize({
        conversation: __assign(__assign({}, data), {
          last_sender_id: last_sender_id,
          last_sender_role: last_sender_role,
          unread: unread,
          updated_ts: updated_at_ms / 1000
        })
      }, {
        conversation: conversationSchema
      });
    }

    return normalizedConversation;
  });
});
export var readConversation = createAsync(READ_CONVERSATION, function (_a, _dispatch, getState) {
  var conversationId = _a.conversationId;
  var res = getState().auth.currentUser.coaching ? API.post("/api/coaching/conversations/" + conversationId + "/read_all") : API.post("/api/conversations/" + conversationId + "/read_all");
  return res.then(function () {
    var _a;

    var conversations = getState().entities.conversations;

    var updatedConversation = __assign(__assign({}, conversations[conversationId]), {
      unread: false
    });

    return {
      entities: {
        conversations: __assign(__assign({}, conversations), (_a = {}, _a[updatedConversation.id] = updatedConversation, _a)),
        messages: {}
      },
      result: {
        conversation: updatedConversation.id
      }
    };
  });
});
export var markConversationAsRead = createAsync(MARK_CONVERSATION_AS_READ, function (_a, dispatch) {
  var conversationId = _a.conversationId;
  return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_b) {
      return [2
      /*return*/
      , dispatch(readConversation.action({
        conversationId: conversationId
      })).then(function (_a) {
        var conversations = _a.entities.conversations;
        return dispatch(updateUnreadMessagesCount(Object.values(conversations)));
      })];
    });
  });
});
export var deleteConversation = createAsync(DELETE_CONVERSATION, function (_a, dispatch) {
  var conversationId = _a.conversationId;
  dispatch(updateModal({
    isDeletingConversation: true
  }));
  return API["delete"]("/api/conversations/" + conversationId).then(function () {
    dispatch(hideModal());
    dispatch(setCurrentConversationId({
      conversationId: undefined
    }));
  })["catch"](function (response) {
    dispatch(updateModal({
      isDeletingConversation: false,
      error: response.data.errors.alert
    }));
    throw response.data.errors;
  });
});
export var createConversation = createAsync(CREATE_CONVERSATION, function (_a, dispatch) {
  var conversationType = _a.conversationType,
      message = _a.message,
      recipientIds = _a.recipientIds,
      contextIds = _a.contextIds; // Param to retrieve all announcement conversations;
  // API default returned only one conversation even if
  // many are being created.

  var allAnnouncements = contextIds ? contextIds.length > 1 : false;
  return API.post('/api/conversations', {
    conversation_type: conversationType,
    message: message,
    recipient_ids: recipientIds,
    context_ids: contextIds,
    all_announcements: allAnnouncements
  }).then(function (_a) {
    var data = _a.data;
    var schema = allAnnouncements ? conversationArraySchema : conversationSchema;
    var normalizedConversations = normalize({
      conversation: data
    }, {
      conversation: schema
    });
    var conversationId = normalizedConversations.result.conversation;
    dispatch(setCurrentConversationId({
      conversationId: allAnnouncements ? _last(conversationId) : conversationId
    }));
    return normalizedConversations;
  });
});
export var sendConversationMessage = createAsync(SEND_CONVERSATION_MESSAGE, function (_a) {
  var conversationId = _a.conversationId,
      message = _a.message;
  return API.post('/api/messages', {
    conversation_id: conversationId,
    message: message
  }).then(function (_a) {
    var data = _a.data;
    return normalize({
      conversation: data
    }, {
      conversation: conversationSchema
    });
  });
});

var updatedConversationTitle = function updatedConversationTitle(conversation, messages, messageId) {
  var lastIndex = conversation.messages.length - 1;

  if (conversation.messages.length > 1 && conversation.messages[lastIndex] === messageId) {
    return messages[conversation.messages[lastIndex - 1]].plain_text;
  }

  return conversation.title;
};

export var deleteMessage = createAsync(DELETE_MESSAGE, function (_a, dispatch, getState) {
  var messageId = _a.messageId;
  return __awaiter(void 0, void 0, void 0, function () {
    var response_1, selectedConversationId, conversation, messages, updatedConversation;

    var _b;

    return __generator(this, function (_c) {
      switch (_c.label) {
        case 0:
          dispatch(updateModal({
            isDeletingMessage: true
          }));
          _c.label = 1;

        case 1:
          _c.trys.push([1, 3,, 4]);

          return [4
          /*yield*/
          , API["delete"]("/api/messages/" + messageId)];

        case 2:
          _c.sent();

          return [3
          /*break*/
          , 4];

        case 3:
          response_1 = _c.sent();
          dispatch(updateModal({
            isDeletingMessage: false,
            error: response_1.data.errors.alert
          }));
          throw response_1.data.errors;

        case 4:
          dispatch(hideModal());
          selectedConversationId = getState().conversation.currentId || '';
          conversation = getState().entities.conversations[selectedConversationId];
          messages = getState().entities.messages;
          updatedConversation = __assign(__assign({}, conversation), {
            messages: conversation.messages.filter(function (id) {
              return id !== messageId;
            }),
            title: updatedConversationTitle(conversation, messages, messageId)
          });

          if (!updatedConversation.messages.length) {
            dispatch(setCurrentConversationId({
              conversationId: undefined
            }));
          }

          return [2
          /*return*/
          , {
            entities: {
              conversations: (_b = {}, _b[updatedConversation.id] = updatedConversation, _b),
              messages: {}
            },
            result: {
              conversation: updatedConversation.id
            }
          }];
      }
    });
  });
});
export var uploadConversationImage = createAsync(UPLOAD_CONVERSATION_IMAGE, function (_a, dispatch, getState) {
  var image = _a.image;
  return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_b) {
      switch (_b.label) {
        case 0:
          dispatch(hideModal());
          return [4
          /*yield*/
          , dispatch(uploadFileRequest.action({
            uploadType: 'conversation_images',
            filename: image.name
          }))];

        case 1:
          _b.sent();

          return [4
          /*yield*/
          , dispatch(uploadFile.action({
            file: image,
            uploadUrl: getState().file.uploadUrl,
            contentType: getState().file.contentType,
            uploadKey: getState().file.uploadKey
          }))];

        case 2:
          _b.sent();

          return [2
          /*return*/
          , API.post("/api/conversations/" + getState().conversation.currentId + "/images", {
            key: getState().file.uploadKey
          }).then(function (_a) {
            var data = _a.data;
            var normalizedConversation = normalize({
              conversation: data.conversation
            }, {
              conversation: conversationSchema
            });
            return __assign(__assign({}, normalizedConversation), {
              filename: image.name,
              imageUrl: data.attached_image.image_url
            });
          })];
      }
    });
  });
});
export var uploadConversationPdf = createAsync(UPLOAD_CONVERSATION_PDF, function (_a, dispatch, getState) {
  var pdf = _a.pdf;
  return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_b) {
      switch (_b.label) {
        case 0:
          dispatch(hideModal());
          return [4
          /*yield*/
          , dispatch(uploadFileRequest.action({
            uploadType: 'conversation_pdfs',
            filename: pdf.name
          }))];

        case 1:
          _b.sent();

          return [4
          /*yield*/
          , dispatch(uploadFile.action({
            file: pdf,
            uploadUrl: getState().file.uploadUrl,
            contentType: getState().file.contentType,
            uploadKey: getState().file.uploadKey
          }))];

        case 2:
          _b.sent();

          return [2
          /*return*/
          , API.post("/api/conversations/" + getState().conversation.currentId + "/pdfs", {
            key: getState().file.uploadKey,
            filename: pdf.name
          }).then(function (_a) {
            var data = _a.data;
            var normalizedConversation = normalize({
              conversation: data.conversation
            }, {
              conversation: conversationSchema
            });
            return __assign(__assign({}, normalizedConversation), {
              filename: pdf.name
            });
          })];
      }
    });
  });
});
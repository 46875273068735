'use strict';

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { setupPusher } from 'actions/Pusher';
export var initialState = {
  setupDone: false
};
var pusher = reducerWithInitialState(initialState)["case"](setupPusher, function (state) {
  return __assign(__assign({}, state), {
    setupDone: true
  });
});
export default pusher;
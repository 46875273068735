'use strict';

export var ASSESSMENT_STEPS;

(function (ASSESSMENT_STEPS) {
  ASSESSMENT_STEPS["PICK_TOPIC"] = "pick_topic";
  ASSESSMENT_STEPS["SETTING"] = "setting"; // refs: https://github.com/quipper/quipper/issues/28992
  // RUBRIC = 'rubric',
})(ASSESSMENT_STEPS || (ASSESSMENT_STEPS = {}));

export var ASSESSMENT_COURSES_SOURCE;

(function (ASSESSMENT_COURSES_SOURCE) {
  ASSESSMENT_COURSES_SOURCE["QUIPPER"] = "quipper";
  ASSESSMENT_COURSES_SOURCE["SCHOOL"] = "school";
})(ASSESSMENT_COURSES_SOURCE || (ASSESSMENT_COURSES_SOURCE = {}));
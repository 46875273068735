'use strict';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { FETCH_ACCOUNT_SETTINGS_REQUEST, FETCH_ACCOUNT_SETTINGS_SUCCESS, FETCH_ACCOUNT_SETTINGS_FAILURE, EDIT_ACCOUNT_SETTINGS, EDIT_ACCOUNT_SETTINGS_CANCEL, SAVE_ACCOUNT_SETTINGS_REQUEST, SAVE_ACCOUNT_SETTINGS_SUCCESS, SAVE_ACCOUNT_SETTINGS_FAILURE, LINK_FACEBOOK_REQUEST, LINK_FACEBOOK_SUCCESS, LINK_FACEBOOK_FAILURE, UNLINK_FACEBOOK_REQUEST, UNLINK_FACEBOOK_SUCCESS, UNLINK_FACEBOOK_FAILURE, UPLOAD_PROFILE_IMG_SUCCESS, SET_PASSWORD_SUCCESS, SET_PASSWORD_FAILURE, SET_PASSWORD_CANCEL } from 'constants/ActionTypes';
export var initState = {
  isFetching: false,
  isConnectingToFacebook: false,
  error: undefined,
  editError: undefined,
  setPasswordError: undefined,
  status: null
};

var _accountSettings = function _accountSettings(state, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_SETTINGS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: undefined,
        isFetching: true
      });

    case FETCH_ACCOUNT_SETTINGS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        currentUser: action.accountSettings
      });

    case FETCH_ACCOUNT_SETTINGS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        error: action.error
      });

    case EDIT_ACCOUNT_SETTINGS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        status: 'editing',
        pristineCurrentUser: Object.assign(action.accountSettings, {}),
        error: undefined
      });

    case EDIT_ACCOUNT_SETTINGS_CANCEL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        status: null,
        currentUser: action.accountSettings,
        editError: undefined
      });

    case SAVE_ACCOUNT_SETTINGS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        status: 'saving'
      });

    case SAVE_ACCOUNT_SETTINGS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        status: 'saved',
        pristineCurrentUser: action.accountSettings,
        currentUser: action.accountSettings,
        editError: undefined
      });

    case SAVE_ACCOUNT_SETTINGS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        status: 'error',
        editError: action.error
      });

    case LINK_FACEBOOK_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: undefined,
        isConnectingToFacebook: true
      });

    case LINK_FACEBOOK_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isConnectingToFacebook: false,
        pristineCurrentUser: action.accountSettings,
        currentUser: action.accountSettings
      });

    case LINK_FACEBOOK_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isConnectingToFacebook: false,
        error: action.error
      });

    case UNLINK_FACEBOOK_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: undefined,
        isConnectingToFacebook: true
      });

    case UNLINK_FACEBOOK_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isConnectingToFacebook: false,
        pristineCurrentUser: action.accountSettings,
        currentUser: action.accountSettings
      });

    case UNLINK_FACEBOOK_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isConnectingToFacebook: false,
        error: action.error
      });

    case UPLOAD_PROFILE_IMG_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        currentUser: _objectSpread(_objectSpread({}, state.currentUser), {}, {
          profile_image_url: action.imageUrl
        }),
        pristineCurrentUser: _objectSpread(_objectSpread({}, state.pristineCurrentUser), {}, {
          profile_image_url: action.imageUrl
        })
      });

    case SET_PASSWORD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        setPasswordError: undefined
      });

    case SET_PASSWORD_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        setPasswordError: action.error
      });

    case SET_PASSWORD_CANCEL:
      return _objectSpread(_objectSpread({}, state), {}, {
        setPasswordError: undefined
      });

    default:
      return state;
  }
};

export var accountSettings = function accountSettings() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_ACCOUNT_SETTINGS_REQUEST:
    case FETCH_ACCOUNT_SETTINGS_SUCCESS:
    case FETCH_ACCOUNT_SETTINGS_FAILURE:
    case EDIT_ACCOUNT_SETTINGS:
    case EDIT_ACCOUNT_SETTINGS_CANCEL:
    case SAVE_ACCOUNT_SETTINGS_REQUEST:
    case SAVE_ACCOUNT_SETTINGS_SUCCESS:
    case SAVE_ACCOUNT_SETTINGS_FAILURE:
    case LINK_FACEBOOK_REQUEST:
    case LINK_FACEBOOK_SUCCESS:
    case LINK_FACEBOOK_FAILURE:
    case UNLINK_FACEBOOK_REQUEST:
    case UNLINK_FACEBOOK_SUCCESS:
    case UNLINK_FACEBOOK_FAILURE:
    case UPLOAD_PROFILE_IMG_SUCCESS:
    case SET_PASSWORD_SUCCESS:
    case SET_PASSWORD_FAILURE:
    case SET_PASSWORD_CANCEL:
      return _objectSpread(_objectSpread({}, state), _accountSettings(state, action));

    default:
      return state;
  }
};
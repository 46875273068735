'use strict';

import { TOGGLE_LOCALE_MENU } from 'constants/ActionTypes';

function toggle() {
  return {
    type: TOGGLE_LOCALE_MENU
  };
}

export default function toggleLocaleMenu() {
  return function (dispatch) {
    return dispatch(toggle());
  };
}
'use strict';

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { HIDE_MODAL, SHOW_MODAL, UPDATE_MODAL } from 'constants/ActionTypes';
export var hideModal = function hideModal() {
  return {
    type: HIDE_MODAL
  };
};

var showDefaultModal = function showDefaultModal(modalType, modalProps) {
  return {
    type: SHOW_MODAL,
    modalType: modalType,
    modalProps: modalProps
  };
};

export var showModal = function showModal(modalType, props) {
  return function (dispatch) {
    var modalProps = __assign(__assign({}, props), {
      toggle: function toggle() {
        return dispatch(hideModal());
      }
    });

    dispatch(showDefaultModal(modalType, modalProps));
  };
};
export var updateModal = function updateModal(modalProps) {
  return {
    type: UPDATE_MODAL,
    modalProps: modalProps
  };
};
'use strict';

// map qlink-react routes to qlink-next routes
var linkRouteMapping = {
  dashboard: [/^[\/\w]*\/dashboard\/?/, /^[\/\w]*\/organizations\/\w+\/dashboard\/?/],
  curriculum: [/^[\/\w]*\/organizations\/\w+\/curriculum\/?/],
  assessment: [/^[\/\w]*\/organizations\/\w+\/assessment\/?/],
  statistics: [/^[\/\w]*\/organizations\/\w+\/statistics\/?$/],
  classes: [/^[\/\w]*\/organizations\/\w+\/classes\/?$/],
  "class": [/^[\/\w]*\/organizations\/\w+\/classes\/\w+\/?$/],
  class_assignment: [/^[\/\w]*\/organizations\/\w+\/classes\/\w+\/assignments\/\w+\/?$/],
  students: [/^[\/\w]*\/organizations\/\w+\/students\/?$/],
  student_assignment: [/^[\/\w]*\/organizations\/\w+\/students\/\w+\?active_assignment\=\w+/],
  student: [/^[\/\w]*\/organizations\/\w+\/students\/\w+\/?$/],
  management: [/^[\/\w]*\/organizations\/\w+\/management\/?$/],
  messages: [/^[\/\w]*\/organizations\/\w+\/messages\/?$/],
  account_settings: [/^[\/\w]*\/organizations\/\w+\/account_settings\/?$/],
  help: [/^[\/\w]*\/organizations\/\w+\/help\/?$/]
};
var linkBetaRouteMapping = {
  dashboard: '/dashboard',
  curriculum: '/curriculum',
  assessment: '/assessment',
  statistics: '/statistics',
  classes: '/classes',
  "class": '/classes/:classId',
  class_assignment: '/classes/:classId/activity/:assignmentId',
  students: '/students',
  student: '/students/:studentId',
  student_assignment: '/students/:studentId/assignments/:assignmentId',
  management: '/management',
  messages: '/messages',
  account_settings: '/account-settings',
  help: '/dashboard'
};

var redirectionMapping = function redirectionMapping() {
  var pathname = window.location.pathname;
  var hash = window.location.hash;
  var location = '';
  var returnValue = [false, location];
  Object.keys(linkRouteMapping).forEach(function (route) {
    linkRouteMapping[route].forEach(function (path) {
      if (!path.test(pathname)) {
        return;
      }

      switch (route) {
        case 'statistics':
          location = linkBetaRouteMapping[route] + hash;
          break;

        case 'class':
          var classMatches = pathname.match(/classes\/(\w+)\/?$/) || [];
          location = linkBetaRouteMapping[route].replace(':classId', classMatches[1]);
          break;

        case 'class_assignment':
          var classAssignmentMatches = pathname.match(/classes\/(\w+)\/assignments\/(\w+)\/?$/) || [];
          location = linkBetaRouteMapping[route].replace(':classId', classAssignmentMatches[1]).replace(':assignmentId', classAssignmentMatches[2]);
          break;

        case 'student_assignment':
          var studenAssignmentMatches = pathname.match(/students\/(\w+)\/assignments\/(\w+)\/?$/) || [];
          location = linkBetaRouteMapping[route].replace(':studentId', studenAssignmentMatches[1]).replace(':assignmentId', studenAssignmentMatches[2]);
          break;

        case 'student':
          var studentMatches = pathname.match(/students\/(\w+)\/?$/) || [];
          location = linkBetaRouteMapping[route].replace(':studentId', studentMatches[1]) + hash;
          break;

        case 'dashboard':
        case 'curriculum':
        case 'assessment':
        case 'students':
        case 'classes':
        case 'management':
        case 'messages':
        case 'account_settings':
        case 'help':
        default:
          location = linkBetaRouteMapping[route];
          break;
      }

      returnValue = [true, location];
    });
  });
  return returnValue;
};

export default redirectionMapping;
'use strict';

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

import { addToast, removeToastAction } from 'actions/common/Toast';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
export var initialState = {
  items: []
};
var toasts = reducerWithInitialState(initialState)["case"](addToast.done, function (state, _a) {
  var newToast = _a.result;
  return __assign(__assign({}, state), {
    items: __spreadArrays(state.items, [newToast])
  });
})["case"](removeToastAction, function (state, _a) {
  var id = _a.id;
  return __assign(__assign({}, state), {
    items: state.items.filter(function (t) {
      return t.id !== id;
    })
  });
});
export default toasts;
'use strict';

import { createStore, applyMiddleware, compose } from 'redux';
import { autoRehydrate } from 'redux-persist';
import thunk from 'redux-thunk';
import rootReducer from 'reducers/index';
import configurePersistence from 'store/configurePersistence';
export default function configureStore() {
  var initialState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var store = createStore(rootReducer, initialState, compose(applyMiddleware(thunk), autoRehydrate()));
  configurePersistence(store);
  return store;
}
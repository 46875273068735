'use strict';

import { accountSettings } from 'reducers/AccountSettings';
import { activities } from 'reducers/Activities';
import reassessmentCourses from 'reducers/assessment/courses';
import assessmentDashboard from 'reducers/assessment/dashboard';
import assessmentCourses from 'reducers/AssessmentCourses';
import { assignments } from 'reducers/Assignments';
import { assignmentStudents } from 'reducers/AssignmentStudents';
import assignmentTopics from 'reducers/AssignmentTopics';
import assignmentForm from 'reducers/AssignmentForm';
import auth from 'reducers/Auth';
import classes from 'reducers/Classes';
import toasts from 'reducers/common/Toast';
import contracts from 'reducers/Contracts';
import { conversation } from 'reducers/Conversation';
import { conversationMessages } from 'reducers/ConversationMessages';
import { conversations } from 'reducers/Conversations';
import course from 'reducers/Course';
import courseSets from 'reducers/CourseSets';
import curriculumFilter from 'reducers/CurriculumFilter';
import entities from 'reducers/Entities';
import facebook from 'reducers/Facebook';
import { file } from 'reducers/File';
import forgotPassword from 'reducers/ForgotPassword';
import helpDocuments from 'reducers/HelpDocuments';
import joinClasses from 'reducers/JoinClasses';
import liteClasses from 'reducers/LiteClasses';
import localeMenu from 'reducers/LocaleMenu'; // Organization

import billingDataFiles from 'reducers/management/BillingDataFiles';
import grades from 'reducers/management/Grades';
import rosters from 'reducers/management/Rosters';
import studentsMembership from 'reducers/management/Students';
import teachers from 'reducers/management/Teachers';
import selfAdminStudentsMembership from 'reducers/management/SelfAdminStudents';
import uploadSelfAdminStudentsCsv from 'reducers/management/UploadCsvModal';
import unusedMemberships from 'reducers/management/UnusedMemberships';
import { messages } from 'reducers/Messages';
import modal from 'reducers/Modal';
import news from 'reducers/News';
import currentOrganization from 'reducers/Organization';
import organizations from 'reducers/Organizations';
import resetPassword from 'reducers/ResetPassword';
import { serverTime } from 'reducers/ServerTime';
import site from 'reducers/Site';
import { statistics, statisticsForOrganizationStudents } from 'reducers/Statistics';
import { studentAssignments } from 'reducers/StudentAssignments';
import { studentOverview } from 'reducers/StudentOverview';
import { studentPortfolioProfile } from 'reducers/StudentPortfolioProfile';
import students from 'reducers/Students';
import { studentTopicDetail } from 'reducers/StudentTopicDetail';
import { teacherNotes } from 'reducers/TeacherNotes';
import { teacherProfile } from 'reducers/TeacherProfile';
import { topics } from 'reducers/Topics';
import topicSearch from 'reducers/TopicSearch';
import usage from 'reducers/Usage';
import user from 'reducers/User';
import { userClasses } from 'reducers/UserClasses';
import { people } from 'reducers/People';
import { zoomMeetings } from 'reducers/ZoomMeetings';
import { combineReducers } from 'redux';
import pusher from './Pusher';
export default combineReducers({
  entities: entities,
  site: site,
  facebook: facebook,
  file: file,
  modal: modal,
  news: news,
  classes: classes,
  liteClasses: liteClasses,
  joinClasses: joinClasses,
  activities: activities,
  assignmentForm: assignmentForm,
  assignmentTopics: assignmentTopics,
  assignmentStudents: assignmentStudents,
  assignments: assignments,
  statistics: statistics,
  statisticsForOrganizationStudents: statisticsForOrganizationStudents,
  localeMenu: localeMenu,
  auth: auth,
  forgotPassword: forgotPassword,
  topicSearch: topicSearch,
  resetPassword: resetPassword,
  organizations: organizations,
  grades: grades,
  rosters: rosters,
  studentsMembership: studentsMembership,
  selfAdminStudentsMembership: selfAdminStudentsMembership,
  uploadSelfAdminStudentsCsv: uploadSelfAdminStudentsCsv,
  courseSets: courseSets,
  assessmentCourses: assessmentCourses,
  students: students,
  teachers: teachers,
  currentOrganization: currentOrganization,
  messages: messages,
  contracts: contracts,
  helpDocuments: helpDocuments,
  user: user,
  accountSettings: accountSettings,
  studentAssignments: studentAssignments,
  studentOverview: studentOverview,
  studentTopicDetail: studentTopicDetail,
  studentPortfolioProfile: studentPortfolioProfile,
  unusedMemberships: unusedMemberships,
  billingDataFiles: billingDataFiles,
  course: course,
  topics: topics,
  teacherNotes: teacherNotes,
  teacherProfile: teacherProfile,
  usage: usage,
  conversation: conversation,
  conversations: conversations,
  conversationMessages: conversationMessages,
  userClasses: userClasses,
  people: people,
  zoomMeetings: zoomMeetings,
  pusher: pusher,
  toasts: toasts,
  curriculumFilter: curriculumFilter,
  serverTime: serverTime,
  assessment: combineReducers({
    courses: reassessmentCourses,
    dashboard: assessmentDashboard
  })
});
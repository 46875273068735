'use strict';

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

export var ERROR_NAMES;

(function (ERROR_NAMES) {
  ERROR_NAMES["CLIENT_ERROR"] = "CLIENT_ERROR";
  ERROR_NAMES["SERVER_ERROR"] = "SERVER_ERROR";
  ERROR_NAMES["UNPROCESSABLE_ENTITY_ERROR"] = "UNPROCESSABLE_ENTITY_ERROR";
  ERROR_NAMES["UNKNOWN_ERROR"] = "UNKNOWN_ERROR";
})(ERROR_NAMES || (ERROR_NAMES = {}));

var HttpRequestError =
/** @class */
function (_super) {
  __extends(HttpRequestError, _super);

  function HttpRequestError(response, payload) {
    var _this = _super.call(this, response.status + ": " + response.statusText) || this;

    _this.getErrorName = function (status) {
      if (400 <= status && status < 500) {
        return ERROR_NAMES.CLIENT_ERROR;
      } else if (500 <= status && status < 600) {
        return ERROR_NAMES.SERVER_ERROR;
      } else {
        return ERROR_NAMES.UNKNOWN_ERROR;
      }
    };

    _this.response = response;
    _this.payload = payload;
    _this.status = response.status;
    _this.name = _this.getErrorName(response.status);
    return _this;
  }

  return HttpRequestError;
}(Error);

export { HttpRequestError };
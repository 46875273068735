'use strict';

import axios from 'axios';
import { UPLOAD_FILE, UPLOAD_FILE_REQUEST } from 'constants/ActionTypes';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import API from 'utils/API';
var createAsync = asyncFactory(actionCreatorFactory());
export var uploadFileRequest = createAsync(UPLOAD_FILE_REQUEST, function (_a, _dispatch) {
  var uploadType = _a.uploadType,
      filename = _a.filename;
  return API.post("/api/upload/" + uploadType, {
    name: filename
  });
});
export var uploadFile = createAsync(UPLOAD_FILE, function (_a, _dispatch) {
  var file = _a.file,
      uploadUrl = _a.uploadUrl,
      contentType = _a.contentType; // Use Axios since we're directly uploading to S3

  return axios.put(uploadUrl, file, {
    headers: {
      'Content-Type': contentType,
      mode: 'cors'
    }
  });
});
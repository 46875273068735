'use strict';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { UPDATE_CURRICULUM_FILTERS } from 'constants/ActionTypes';
export var initialState = {
  // These are persisted in localStorage
  courseName: null,
  courseSetId: null,
  courseSubsetId: null,
  subjectId: null,
  tagId: null,
  grade: null,
  syncedWithStorage: false,
  storageKey: null
};

var curriculumFilter = function curriculumFilter() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case UPDATE_CURRICULUM_FILTERS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.filter), {}, {
        storageKey: action.storageKey || state.storageKey,
        syncedWithStorage: action.fromLocalStorage || state.syncedWithStorage
      });

    default:
      return state;
  }
};

export default curriculumFilter;
'use strict';

import { persistStore } from 'redux-persist';
import localForage from 'localforage'; // currently we only use persistence options for localforage (IndexedDB and fallback to localStorage)

var configurePersistence = function configurePersistence(store) {
  var options = {
    storage: localForage,
    whitelist: ['assignmentTopics']
  };
  persistStore(store, options);
};

export default configurePersistence;
'use strict';

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { FETCH_TEACHER_NOTES_REQUEST, FETCH_TEACHER_NOTES_SUCCESS, FETCH_TEACHER_NOTES_FAILURE, CREATE_TEACHER_NOTE_REQUEST, CREATE_TEACHER_NOTE_SUCCESS, CREATE_TEACHER_NOTE_FAILURE, UPDATE_TEACHER_NOTE_REQUEST, UPDATE_TEACHER_NOTE_SUCCESS, UPDATE_TEACHER_NOTE_FAILURE, DELETE_TEACHER_NOTE_UPDATE_ERROR, DELETE_TEACHER_NOTE_REQUEST, DELETE_TEACHER_NOTE_SUCCESS, DELETE_TEACHER_NOTE_FAILURE } from 'constants/ActionTypes';
export var initialState = {
  isFetching: false,
  isCreateRequest: false,
  isUpdateRequest: false,
  error: undefined,
  items: {
    teacherNotes: {}
  },
  result: {
    teacherNotes: []
  },
  editingErrors: {}
};

function _teacherNotes(state, action) {
  switch (action.type) {
    case FETCH_TEACHER_NOTES_REQUEST:
      return {
        isFetching: true
      };

    case FETCH_TEACHER_NOTES_SUCCESS:
      return {
        isFetching: false,
        items: action.entities,
        result: action.result
      };

    case FETCH_TEACHER_NOTES_FAILURE:
      return {
        isFetching: false,
        error: action.error
      };

    case CREATE_TEACHER_NOTE_REQUEST:
      return {
        isCreateRequest: true
      };

    case CREATE_TEACHER_NOTE_SUCCESS:
      return {
        isCreateRequest: false,
        items: {
          teacherNotes: _objectSpread(_defineProperty({}, action.response.id, action.response), state.items.teacherNotes)
        },
        result: {
          teacherNotes: [action.response.id].concat(_toConsumableArray(state.result.teacherNotes))
        },
        error: {}
      };

    case CREATE_TEACHER_NOTE_FAILURE:
      return {
        isCreateRequest: false,
        error: action.error
      };

    case UPDATE_TEACHER_NOTE_REQUEST:
      return {
        isUpdateRequest: true
      };

    case UPDATE_TEACHER_NOTE_SUCCESS:
      return {
        isUpdateRequest: false,
        items: {
          teacherNotes: _objectSpread(_objectSpread({}, state.items.teacherNotes), {}, _defineProperty({}, action.response.id, action.response))
        },
        editingErrors: _objectSpread(_objectSpread({}, state.editingErrors), {}, _defineProperty({}, action.response.id, {}))
      };

    case UPDATE_TEACHER_NOTE_FAILURE:
      return {
        isUpdateRequest: false,
        editingErrors: _objectSpread(_objectSpread({}, state.editingErrors), {}, _defineProperty({}, action.noteId, action.error))
      };

    case DELETE_TEACHER_NOTE_UPDATE_ERROR:
      return {
        editingErrors: _objectSpread(_objectSpread({}, state.editingErrors), {}, _defineProperty({}, action.noteId, {}))
      };

    case DELETE_TEACHER_NOTE_REQUEST:
      return {
        isDeleteRequest: true
      };

    case DELETE_TEACHER_NOTE_SUCCESS:
      return {
        isDeleteRequest: false,
        result: {
          teacherNotes: state.result.teacherNotes.filter(function (item) {
            return item !== action.noteId;
          })
        }
      };

    case DELETE_TEACHER_NOTE_FAILURE:
      return {
        isDeleteRequest: false
      };

    default:
      return state;
  }
}

export function teacherNotes() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_TEACHER_NOTES_REQUEST:
    case FETCH_TEACHER_NOTES_SUCCESS:
    case FETCH_TEACHER_NOTES_FAILURE:
    case CREATE_TEACHER_NOTE_REQUEST:
    case CREATE_TEACHER_NOTE_SUCCESS:
    case CREATE_TEACHER_NOTE_FAILURE:
    case UPDATE_TEACHER_NOTE_REQUEST:
    case UPDATE_TEACHER_NOTE_SUCCESS:
    case UPDATE_TEACHER_NOTE_FAILURE:
    case DELETE_TEACHER_NOTE_UPDATE_ERROR:
    case DELETE_TEACHER_NOTE_REQUEST:
    case DELETE_TEACHER_NOTE_SUCCESS:
    case DELETE_TEACHER_NOTE_FAILURE:
      return _objectSpread(_objectSpread({}, state), _teacherNotes(state, action));

    default:
      return state;
  }
}
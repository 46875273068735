'use strict';

import _concat from "lodash/concat";
import _compact from "lodash/compact";
import _flatten from "lodash/flatten";
import _reduce from "lodash/reduce";
import _isEmpty from "lodash/isEmpty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { FETCH_INVITATION_REQUEST, FETCH_INVITATION_SUCCESS, FETCH_INVITATION_FAILURE, FETCH_SIGNUP_REQUEST, FETCH_SIGNUP_SUCCESS_CODE, FETCH_SIGNUP_SUCCESS_ORGANIZATION, FETCH_SIGNUP_FAILURE, SUBMIT_SIGNUP_REQUEST, SUBMIT_SIGNUP_SUCCESS, SUBMIT_SIGNUP_FAILURE, SUBMIT_EMAIL_CONFIRM_REQUEST, SUBMIT_EMAIL_CONFIRM_SUCCESS, SUBMIT_EMAIL_CONFIRM_FAILURE, RESEND_EMAIL_CONFIRM_REQUEST, RESEND_EMAIL_CONFIRM_SUCCESS, RESEND_EMAIL_CONFIRM_FAILURE, CLOSE_USER_NOTIFICATION, ACCEPT_TERMS_REQUEST, ACCEPT_TERMS_SUCCESS, ACCEPT_TERMS_FAILURE } from 'constants/ActionTypes';
import { setRoutingStatus } from 'actions/Site';
import API from 'utils/API'; // import I18n from 'utils/I18n';

import AuthHelper from 'utils/AuthHelper'; // import UrlHelper from 'utils/UrlHelper';

import { authenticateUserSuccess, authenticateUserRequest, facebookConnectRequest, loginUser } from 'actions/Auth';
export var fetchInvitationRequest = function fetchInvitationRequest() {
  return {
    type: FETCH_INVITATION_REQUEST
  };
};
export var fetchInvitationSuccess = function fetchInvitationSuccess(data) {
  return {
    type: FETCH_INVITATION_SUCCESS,
    invitee: data
  };
};
export var fetchInvitationFailure = function fetchInvitationFailure(error) {
  return {
    type: FETCH_INVITATION_FAILURE,
    noInvitation: !_isEmpty(error)
  };
};
export var fetchSignupRequest = function fetchSignupRequest() {
  return {
    type: FETCH_SIGNUP_REQUEST
  };
};
export var fetchSignupSuccessCode = function fetchSignupSuccessCode(data) {
  return {
    type: FETCH_SIGNUP_SUCCESS_CODE,
    signupDetails: data
  };
};
export var fetchSignupSuccessOrganization = function fetchSignupSuccessOrganization(data) {
  return {
    type: FETCH_SIGNUP_SUCCESS_ORGANIZATION,
    organization: data
  };
};
export var fetchSignupFailure = function fetchSignupFailure(error) {
  return {
    type: FETCH_SIGNUP_FAILURE,
    error: error
  };
};
export var submitSignupRequest = function submitSignupRequest() {
  return {
    type: SUBMIT_SIGNUP_REQUEST
  };
};
export var submitSignupSuccess = function submitSignupSuccess() {
  return {
    type: SUBMIT_SIGNUP_SUCCESS
  };
};
export var submitSignupFailure = function submitSignupFailure(data) {
  return {
    type: SUBMIT_SIGNUP_FAILURE,
    errors: data.errors || data
  };
};
export var submitEmailConfirmRequest = function submitEmailConfirmRequest() {
  return {
    type: SUBMIT_EMAIL_CONFIRM_REQUEST
  };
};
export var submitEmailConfirmSuccess = function submitEmailConfirmSuccess(data) {
  return {
    type: SUBMIT_EMAIL_CONFIRM_SUCCESS,
    confirmationObject: data
  };
};
export var submitEmailConfirmFailure = function submitEmailConfirmFailure(data) {
  return {
    type: SUBMIT_EMAIL_CONFIRM_FAILURE,
    confirmationErrors: data.errors || data
  };
};
export var resendEmailConfirmRequest = function resendEmailConfirmRequest() {
  return {
    type: RESEND_EMAIL_CONFIRM_REQUEST
  };
};
export var resendEmailConfirmSuccess = function resendEmailConfirmSuccess(message) {
  return {
    type: RESEND_EMAIL_CONFIRM_SUCCESS,
    resendMessage: message
  };
};
export var resendEmailConfirmFailure = function resendEmailConfirmFailure(error) {
  return {
    type: RESEND_EMAIL_CONFIRM_FAILURE,
    resendError: error
  };
};
export var acceptTermsRequest = function acceptTermsRequest() {
  return {
    type: ACCEPT_TERMS_REQUEST
  };
};
export var acceptTermsSuccess = function acceptTermsSuccess(status) {
  return {
    type: ACCEPT_TERMS_SUCCESS,
    legalConsentStatus: status
  };
};
export var acceptTermsFailure = function acceptTermsFailure(data) {
  return {
    type: ACCEPT_TERMS_FAILURE,
    errors: data.errors || data
  };
};
export var validateUserParams = function validateUserParams(params) {
  var _ref = params.user || params,
      email = _ref.email,
      password = _ref.password,
      confirmPassword = _ref.confirm_password,
      firstName = _ref.first_name,
      lastName = _ref.last_name,
      facebookToken = _ref.facebook_token,
      phone = _ref.phone;

  var errors = [];

  if (_isEmpty(firstName)) {
    errors.push('user.alerts.empty_first_name');
  }

  if (_isEmpty(lastName)) {
    errors.push('user.alerts.empty_last_name');
  }

  if (_isEmpty(phone)) {
    errors.push('user.alerts.empty_phone');
  }

  if (facebookToken) {
    // Email and password validation unnecessary
    return errors;
  }

  if (!AuthHelper.emailValidation(email)) {
    errors.push('user.alerts.invalid_email');
  }

  var passwordErrors = AuthHelper.passwordValidationErrorMessages(password);

  if (!_isEmpty(passwordErrors)) {
    errors = _concat(errors, passwordErrors);
  }

  if (!AuthHelper.confirmPasswordValidation(password, confirmPassword)) {
    errors.push('user.alerts.passwords_different');
  }

  return errors;
}; // To test this function directly, added `export`.

export var errorMessagesMap = function errorMessagesMap(key, val) {
  if (key === 'password' && val === 'contains_username') return 'user.join.errors.password_contains_username';
  if (key === 'first_name' && /too long/.test(val)) return 'user.join.errors.first_name_too_long';
  if (key === 'last_name' && /too long/.test(val)) return 'user.join.errors.last_name_too_long';
  return val;
};

var generateErrorMessages = function generateErrorMessages(errors) {
  var messages = _reduce(errors, function (result, value, key) {
    result.push(_compact(value.map(function (val) {
      return errorMessagesMap(key, val);
    })));
    return result;
  }, []);

  return _flatten(messages);
};

export var createAccount = function createAccount(params) {
  return function (dispatch) {
    dispatch(submitSignupRequest());
    return API.post('/api/users', params).then(function (response) {
      dispatch(submitSignupSuccess());
      dispatch(loginUser(response.data.auth));
    }, function (_ref2) {
      var response = _ref2.response;
      dispatch(submitSignupFailure(response.data));
    });
  };
};
export var createInvitedAccount = function createInvitedAccount(params) {
  return function (dispatch) {
    dispatch(submitSignupRequest());
    var validationErrors = validateUserParams(params);

    if (!_isEmpty(validationErrors)) {
      return dispatch(submitSignupFailure(validationErrors));
    }

    return API.post('/api/invitees', _objectSpread({
      organization: {},
      user_preference: {}
    }, params)).then(function (response) {
      dispatch(submitSignupSuccess());
      dispatch(loginUser(response.data.auth));
    }, function (_ref3) {
      var response = _ref3.response;
      var data = response.data || {};

      if (data.errors) {
        dispatch(submitSignupFailure(generateErrorMessages(data.errors)));
      } else {
        dispatch(submitSignupFailure(['errors.server_error.message']));
      }
    });
  };
};
export var checkJoinInvitation = function checkJoinInvitation(invitationId) {
  return function (dispatch) {
    dispatch(fetchInvitationRequest());
    return API.get("/api/invitees/".concat(invitationId)).then(function (response) {
      dispatch(fetchInvitationSuccess(response.data));
    }, function (error) {
      return dispatch(fetchInvitationFailure(error.data));
    });
  };
};
export var checkSignupCode = function checkSignupCode(code) {
  return function (dispatch) {
    dispatch(fetchSignupRequest());
    return API.get("/api/subscriptions/".concat(code)).then(function (response) {
      return dispatch(fetchSignupSuccessCode(response.data));
    }, function (error) {
      return dispatch(fetchSignupFailure(error.response));
    });
  };
};
export var checkOrganization = function checkOrganization(organizationId) {
  return function (dispatch) {
    dispatch(fetchSignupRequest());
    return API.get("/api/organizations/".concat(organizationId), {
      join_organization: true
    }).then(function (response) {
      return dispatch(fetchSignupSuccessOrganization(response.data));
    }, function (error) {
      return dispatch(fetchSignupFailure(error));
    });
  };
};
export var checkFacebookToken = function checkFacebookToken(token) {
  var email = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return function (dispatch) {
    dispatch(authenticateUserRequest());
    return API.get('/api/authorizations/facebook', {
      token: token,
      email: email
    }).then(function (response) {
      dispatch(authenticateUserSuccess(response.data));
    }, function () {
      dispatch(facebookConnectRequest(token));
    });
  };
};
export var confirmEmail = function confirmEmail(token) {
  return function (dispatch) {
    dispatch(submitEmailConfirmRequest());
    return API.post('/api/users/confirm_email', {
      user: {
        token: token
      }
    }).then(function (response) {
      var user = response.data;
      dispatch(submitEmailConfirmSuccess({
        confirmed_email: user.email
      }));
      dispatch(loginUser(user));
    }, function (_ref4) {
      var response = _ref4.response;
      var errorData = '';

      if (response.status === 422) {
        errorData = 'user.alerts.confirm_email.already_confirmed';
      } else if (response.status >= 500) {
        errorData = 'user.alerts.confirm_email.server_error';
      } else {
        errorData = 'user.alerts.confirm_email.confirm_url_not_correct';
      }

      dispatch(submitEmailConfirmFailure(errorData));
    });
  };
};
export var resendConfirmLink = function resendConfirmLink(userId) {
  return function (dispatch) {
    dispatch(resendEmailConfirmRequest());
    return API.post("/api/users/".concat(userId, "/resend_email_confirmation")).then(function () {
      return dispatch(resendEmailConfirmSuccess('user.alerts.resend_email_confirmation_instructions.success'));
    }, function () {
      return dispatch(resendEmailConfirmFailure('user.alerts.resend_email_confirmation_instructions.error'));
    });
  };
};
export var closeUserNotification = function closeUserNotification() {
  return function (dispatch) {
    return dispatch({
      type: CLOSE_USER_NOTIFICATION
    });
  };
};
export var acceptTerms = function acceptTerms(userId) {
  return function (dispatch) {
    dispatch(acceptTermsRequest());
    return API.post("/api/users/".concat(userId, "/accept_terms")).then(function (response) {
      var _response$data = response.data,
          status = _response$data.status,
          success = _response$data.success,
          error = _response$data.error;

      if (success === false && error === 'Not logged in') {
        dispatch(setRoutingStatus('401'));
      }

      dispatch(acceptTermsSuccess(status));
    }, function (error) {
      return dispatch(acceptTermsFailure(error.response));
    });
  };
};
'use strict';

import _conforms from "lodash/conforms";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { normalize } from 'normalizr';
import cookie from 'cookie_js';
import SessionStorage from 'utils/storage/SessionStorage';
import { SET_CURRENT_ORGANIZATION, FETCH_ORGANIZATIONS_REQUEST, FETCH_ORGANIZATIONS_SUCCESS, FETCH_ORGANIZATIONS_FAILURE, FETCH_PROVINCES_REQUEST, FETCH_PROVINCES_SUCCESS, FETCH_PROVINCES_FAILURE, FETCH_CITIES_REQUEST, FETCH_CITIES_SUCCESS, FETCH_CITIES_FAILURE, FETCH_SCHOOLS_REQUEST, FETCH_SCHOOLS_SUCCESS, FETCH_SCHOOLS_FAILURE } from 'constants/ActionTypes';
import { organizationArraySchema } from 'schemas/organizations';
import { setRoutingStatus } from 'actions/Site';
import { fetchOrganizationDetailsIfNeeded } from 'actions/Organization';
import { requestShowJoinClasses, showJoinClasses } from 'actions/JoinClasses';
import config from 'config';
import API from 'utils/API';
import Browser from 'utils/Browser';
import { clearAssignmentTopics } from 'actions/AssignmentTopics';
import { fetchUnreadMessagesCountIfNeeded } from 'actions/Messages';
import UrlHelper from 'utils/UrlHelper';
export var setCurrentOrganization = function setCurrentOrganization(organization) {
  return {
    type: SET_CURRENT_ORGANIZATION,
    organization: organization
  };
};
export var requestOrganizations = function requestOrganizations() {
  return {
    type: FETCH_ORGANIZATIONS_REQUEST
  };
};
export var fetchProvincesRequest = function fetchProvincesRequest() {
  return {
    type: FETCH_PROVINCES_REQUEST
  };
};
export var fetchCitiesRequest = function fetchCitiesRequest() {
  return {
    type: FETCH_CITIES_REQUEST
  };
};
export var fetchSchoolsRequest = function fetchSchoolsRequest() {
  return {
    type: FETCH_SCHOOLS_REQUEST
  };
};
export var receiveOrganizations = function receiveOrganizations(organizations, currentOrganization) {
  var response = normalize({
    organizations: organizations
  }, {
    organizations: organizationArraySchema
  });
  var normalizedCurrentOrganization = response.entities.organizations[currentOrganization === null || currentOrganization === void 0 ? void 0 : currentOrganization.id];

  if (normalizedCurrentOrganization) {
    response.entities.organizations[currentOrganization.id] = _objectSpread(_objectSpread({}, currentOrganization), normalizedCurrentOrganization);
  }

  return {
    type: FETCH_ORGANIZATIONS_SUCCESS,
    response: response
  };
};
export var receiveProvinces = function receiveProvinces(provinces) {
  return {
    type: FETCH_PROVINCES_SUCCESS,
    provinces: provinces
  };
};
export var receiveCities = function receiveCities(cities) {
  return {
    type: FETCH_CITIES_SUCCESS,
    cities: cities
  };
};
export var receiveSchools = function receiveSchools(schools, query) {
  return {
    type: FETCH_SCHOOLS_SUCCESS,
    schools: schools.results,
    schoolQuery: query
  };
};
export var fetchOrganizationsFailure = function fetchOrganizationsFailure(error) {
  return {
    type: FETCH_ORGANIZATIONS_FAILURE,
    error: error
  };
};
export var fetchProvincesFailure = function fetchProvincesFailure(error) {
  return {
    type: FETCH_PROVINCES_FAILURE,
    error: error
  };
};
export var fetchCitiesFailure = function fetchCitiesFailure(error) {
  return {
    type: FETCH_CITIES_FAILURE,
    error: error
  };
};
export var fetchSchoolsFailure = function fetchSchoolsFailure(error) {
  return {
    type: FETCH_SCHOOLS_FAILURE,
    error: error
  };
};
export var fetchOrganizations = function fetchOrganizations() {
  return function (dispatch, getState) {
    dispatch(requestOrganizations());
    return API.get('/api/organizations').then(function (response) {
      var state = getState();
      var currentOrganization = state.entities.organizations[state.organizations.current];
      dispatch(receiveOrganizations(response.data, currentOrganization));
    }, function (error) {
      return dispatch(fetchOrganizationsFailure(error));
    });
  };
};
export var fetchProvinces = function fetchProvinces(countryCode) {
  return function (dispatch) {
    dispatch(fetchProvincesRequest());
    return API.get('/api/organizations/provinces', {
      country_code: countryCode
    }).then(function (response) {
      var filteredProvinces = _filter(response.data, _conforms({
        value: function value(v) {
          return !_isEmpty(v);
        }
      }));

      dispatch(receiveProvinces(filteredProvinces));
    }, function (error) {
      return dispatch(fetchProvincesFailure(error.data));
    });
  };
};
export var fetchCities = function fetchCities(countryCode, province) {
  return function (dispatch) {
    dispatch(fetchCitiesRequest());
    return API.get('/api/organizations/cities', {
      country_code: countryCode,
      province: province
    }).then(function (response) {
      return dispatch(receiveCities(response.data));
    }, function (error) {
      return dispatch(fetchCitiesFailure(error.data));
    });
  };
};
export var fetchSchools = function fetchSchools(countryCode, province, city) {
  var query = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
  return function (dispatch) {
    dispatch(fetchSchoolsRequest());
    return API.post('/api/organizations/search', {
      limit: 20,
      verified_only: true,
      country_code: countryCode,
      query: query,
      province: province,
      city: city
    }).then(function (response) {
      return dispatch(receiveSchools(response.data, query));
    }, function (error) {
      return dispatch(fetchSchoolsFailure(error.data));
    });
  };
};

var shouldFetchOrganizations = function shouldFetchOrganizations(_ref) {
  var organizations = _ref.organizations;
  return _isEmpty(organizations.items) && !organizations.isFetching;
};

export var fetchOrganizationsIfNeeded = function fetchOrganizationsIfNeeded() {
  return function (dispatch, getState) {
    if (shouldFetchOrganizations(getState())) {
      return dispatch(fetchOrganizations());
    }

    return Promise.resolve(false);
  };
};
export var changeOrganization = function changeOrganization(organization) {
  return function (dispatch, getState) {
    SessionStorage.removeItem('isShownEnterTeacherProfileModal'); // Always redirect user to `dashboard` page on changeOrganization

    var state = getState();
    var url = UrlHelper.buildOrganizationBasedPath(UrlHelper.defaultMenu(state.auth.currentUser), organization.id);
    Browser.setWindowHref(url);
  };
};
export var determineCurrentOrganization = function determineCurrentOrganization(organizationId) {
  return function (dispatch, getState) {
    var state = getState(); // const key = `defaultOrganization:${state.auth.currentUser.id}`;

    var cookie_name = "defaultOrganization_".concat(state.auth.currentUser.id);
    var currentOrganizationId = organizationId || cookie.get(cookie_name) || state.organizations.items[0];

    if (!currentOrganizationId) {
      dispatch(setRoutingStatus('404'));
      return false;
    } // No change


    if (state.organizations.current && state.organizations.current === currentOrganizationId) {
      return false;
    }

    return dispatch(fetchOrganizationDetailsIfNeeded(currentOrganizationId)).then(function (currentOrganization) {
      /*
       * Teachers of meiko organization must be redirected to Meiko site
       * ref: https://github.com/quipper/quipper/issues/24610
       */
      if (currentOrganization.alliance) {
        Browser.setWindowHref(Browser.pathname(), config.meiko_qlink_url);
        return;
      }

      dispatch(requestShowJoinClasses());
      dispatch(setCurrentOrganization(currentOrganization));
      dispatch(showJoinClasses());
      dispatch(fetchUnreadMessagesCountIfNeeded());
      /*
       *  Clear assignmentTopics when organization changed
       *  ref: https://github.com/quipper/quipper/issues/22846
       */

      if (cookie.get(cookie_name) !== currentOrganization.id) {
        dispatch(clearAssignmentTopics());
        cookie.set(cookie_name, currentOrganization.id, {
          path: '/',
          domain: Browser.getRootDomain(),
          secure: process.env.APP_CONFIG.site_env !== 'development' // Allow access on secure connection unless development

        });
      }
    }, function () {
      cookie.remove(cookie_name);
      dispatch(setRoutingStatus('404'));
    });
  };
};
export var getCurrentOrganization = function getCurrentOrganization(_ref2) {
  var entities = _ref2.entities,
      organizations = _ref2.organizations;

  if (entities && entities.organizations && organizations && organizations.current) {
    return entities.organizations[organizations.current] || {};
  }

  return {};
};
'use strict';

import _withHandlers from "recompose/withHandlers";
import _compose from "recompose/compose";
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import withPusher from 'HOCs/withPusher';
import HeaderView from 'components/header/HeaderView';
import { bindActionCreators } from 'redux';
import { clearCurrentUser } from 'actions/Auth';
import { postTdEvent } from 'actions/Header';
import { fetchUnreadMessagesCount } from 'actions/Messages';
import { showModal, hideModal } from 'actions/Modal';
import { acceptTerms } from 'actions/User';
export function mapStateToProps(state) {
  var _ref = isEmpty(state.auth) ? {
    currentUser: {}
  } : state.auth,
      currentUser = _ref.currentUser;

  var _ref2 = isEmpty(state.user) ? {
    legalConsentStatus: null
  } : state.user,
      legalConsentStatus = _ref2.legalConsentStatus;

  var _ref3 = isEmpty(state.messages) ? {
    unreadMessagesCount: 0
  } : state.messages,
      unreadMessagesCount = _ref3.unreadMessagesCount;

  var _ref4 = isEmpty(state.modal) ? {
    modalType: null,
    modalProps: {}
  } : state.modal,
      modalType = _ref4.modalType,
      modalProps = _ref4.modalProps;

  return {
    modal: {
      modalType: modalType,
      modalProps: modalProps
    },
    currentUser: currentUser,
    unreadMessagesCount: unreadMessagesCount,
    legalConsentStatus: legalConsentStatus
  };
}

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    clearCurrentUser: bindActionCreators(clearCurrentUser, dispatch),
    fetchUnreadMessagesCount: bindActionCreators(fetchUnreadMessagesCount, dispatch),
    postTdEvent: bindActionCreators(postTdEvent, dispatch),
    showModal: bindActionCreators(showModal, dispatch),
    hideModal: bindActionCreators(hideModal, dispatch),
    acceptTerms: bindActionCreators(acceptTerms, dispatch)
  };
};

export default _compose(withPusher, connect(mapStateToProps, mapDispatchToProps), _withHandlers({
  updateUnreadMessagesCount: function updateUnreadMessagesCount(props) {
    return function () {
      return props.fetchUnreadMessagesCount();
    };
  },
  onClickAcceptTerms: function onClickAcceptTerms(props) {
    return function () {
      props.acceptTerms(props.currentUser.id);
      hideModal('LegalModal');
    };
  }
}))(HeaderView);
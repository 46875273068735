'use strict';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { SET_CURRENT_ORGANIZATION, FETCH_ORGANIZATIONS_REQUEST, FETCH_ORGANIZATIONS_SUCCESS, FETCH_ORGANIZATIONS_FAILURE, FETCH_PROVINCES_REQUEST, FETCH_PROVINCES_SUCCESS, FETCH_PROVINCES_FAILURE, FETCH_CITIES_REQUEST, FETCH_CITIES_SUCCESS, FETCH_CITIES_FAILURE, FETCH_SCHOOLS_REQUEST, FETCH_SCHOOLS_SUCCESS, FETCH_SCHOOLS_FAILURE } from 'constants/ActionTypes';

function _organizations() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_CURRENT_ORGANIZATION:
      return _objectSpread(_objectSpread({}, state), {}, {
        current: action.organization ? action.organization.id : null
      });

    case FETCH_ORGANIZATIONS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true,
        error: undefined
      });

    case FETCH_PROVINCES_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetchingProvinces: true,
        error: undefined
      });

    case FETCH_CITIES_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetchingCities: true,
        error: undefined
      });

    case FETCH_SCHOOLS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetchingSchools: true,
        error: undefined
      });

    case FETCH_ORGANIZATIONS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        error: undefined,
        items: action.response.result.organizations
      });

    case FETCH_PROVINCES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetchingProvinces: false,
        error: undefined,
        provinces: action.provinces
      });

    case FETCH_CITIES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetchingCities: false,
        error: undefined,
        cities: action.cities
      });

    case FETCH_SCHOOLS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetchingSchools: false,
        error: undefined,
        schools: action.schools,
        schoolQuery: action.schoolQuery
      });

    case FETCH_ORGANIZATIONS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        error: action.error,
        items: []
      });

    case FETCH_PROVINCES_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetchingProvinces: false,
        error: action.error,
        provinces: []
      });

    case FETCH_CITIES_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetchingCities: false,
        error: action.error,
        cities: []
      });

    case FETCH_SCHOOLS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetchingSchools: false,
        error: action.error,
        schools: []
      });

    default:
      return state;
  }
}

export default function organizations() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_CURRENT_ORGANIZATION:
    case FETCH_ORGANIZATIONS_REQUEST:
    case FETCH_ORGANIZATIONS_SUCCESS:
    case FETCH_ORGANIZATIONS_FAILURE:
    case FETCH_PROVINCES_REQUEST:
    case FETCH_PROVINCES_SUCCESS:
    case FETCH_PROVINCES_FAILURE:
    case FETCH_CITIES_REQUEST:
    case FETCH_CITIES_SUCCESS:
    case FETCH_CITIES_FAILURE:
    case FETCH_SCHOOLS_REQUEST:
    case FETCH_SCHOOLS_SUCCESS:
    case FETCH_SCHOOLS_FAILURE:
      return _objectSpread(_objectSpread({}, state), _organizations(state, action));

    default:
      return state;
  }
}
'use strict';

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { fetchAssessmentCourse, fetchSchoolAssessmentCourse } from 'actions/assessment/courses';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
export var initialState = {
  isFetchingAssessmentCourses: false,
  isFetchingSchoolAssessmentCourses: false
};
export default reducerWithInitialState(initialState)["case"](fetchAssessmentCourse.started, function (state) {
  return __assign(__assign({}, state), {
    error: undefined,
    isFetchingAssessmentCourses: true
  });
})["case"](fetchAssessmentCourse.failed, function (state, _a) {
  var error = _a.error;
  return __assign(__assign({}, state), {
    error: error,
    isFetchingAssessmentCourses: false
  });
})["case"](fetchAssessmentCourse.done, function (state, _a) {
  var result = _a.result;
  return __assign(__assign({}, state), {
    isFetchingAssessmentCourses: false,
    result: result
  });
})["case"](fetchSchoolAssessmentCourse.started, function (state) {
  return __assign(__assign({}, state), {
    school_courses_error: undefined,
    isFetchingSchoolAssessmentCourses: true
  });
})["case"](fetchSchoolAssessmentCourse.failed, function (state, _a) {
  var error = _a.error;
  return __assign(__assign({}, state), {
    school_courses_error: error,
    isFetchingSchoolAssessmentCourses: false
  });
})["case"](fetchSchoolAssessmentCourse.done, function (state, _a) {
  var result = _a.result;
  return __assign(__assign({}, state), {
    isFetchingSchoolAssessmentCourses: false,
    school_courses_result: result
  });
});
'use strict';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { SEARCH_TOPICS_REQUEST, SEARCH_TOPICS_SUCCESS, SEARCH_TOPICS_FAILURE, SEARCH_TOPICS_INVALID } from 'constants/ActionTypes';
var initialState = {
  didInvalidate: true,
  isSearching: false,
  topicIds: [],
  bundleIds: [],
  courseIds: []
};

var _topicSearch = function _topicSearch() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SEARCH_TOPICS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isSearching: true,
        didInvalidate: true,
        error: undefined
      });

    case SEARCH_TOPICS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        topicIds: action.data.topic_ids,
        bundleIds: action.data.bundle_ids,
        courseIds: action.data.course_ids,
        isSearching: false,
        didInvalidate: false,
        error: undefined
      });

    case SEARCH_TOPICS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isSearching: false,
        didInvalidate: true,
        error: action.error,
        topicIds: [],
        bundleIds: [],
        courseIds: []
      });

    case SEARCH_TOPICS_INVALID:
      return _objectSpread(_objectSpread({}, state), {}, {
        didInvalidate: true
      });

    default:
      return state;
  }
};

export default function topicSearch() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SEARCH_TOPICS_REQUEST:
    case SEARCH_TOPICS_SUCCESS:
    case SEARCH_TOPICS_FAILURE:
    case SEARCH_TOPICS_INVALID:
      return _objectSpread(_objectSpread({}, state), _topicSearch(state, action));

    default:
      return state;
  }
}
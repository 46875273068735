'use strict';

export var ConversationType;

(function (ConversationType) {
  ConversationType["All"] = "all";
  ConversationType["Private"] = "private";
  ConversationType["PrivateContract"] = "private_contract";
  ConversationType["Group"] = "group";
  ConversationType["Announcement"] = "announcement";
})(ConversationType || (ConversationType = {}));

export var ComposerState;

(function (ComposerState) {
  ComposerState["Private"] = "private";
  ComposerState["Announcement"] = "announcement";
})(ComposerState || (ComposerState = {}));
'use strict';

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty'; // Helper for component modifier classes
// This modifies variant, size, etc by attaching the modifier classes to component name
//
// This outputs classes like `list-group-item-primary`, `list-group-item-sm`

var componentModifierClasses = function componentModifierClasses(props, baseHtmlClass, iconSize) {
  var _classnames;

  var variant = props.variant,
      isBlock = props.isBlock;
  return classnames((_classnames = {}, _defineProperty(_classnames, "".concat(baseHtmlClass, "-").concat(variant), !isEmpty(variant)), _defineProperty(_classnames, "".concat(baseHtmlClass, "-").concat(iconSize), !isEmpty(iconSize)), _defineProperty(_classnames, "".concat(baseHtmlClass, "-block"), isBlock), _classnames));
}; // Utility function for aggregating Qstrap classes from props
//
// Parameters:
//  - props: Object
//      Object for which the HTML classes to be applied components is generated from.
//      Component's props can be directly passed to this method.
//      Custom object can be passed as well
//
//  - baseHtmlClass: string (not required)
//      Contains the string of base HTML used by the component.
//      This is automatically added to generated classes.
//      In addition, component modifier classes will be based on this.
//      e.g. for `btn` class, modifiers like variant, size, etc will be based on this class (`btn-primary`, `btn-lg`, etc)


var aggregateClasses = function aggregateClasses(props, baseHtmlClass, iconSize) {
  var className = props.className,
      isActive = props.isActive,
      isOpen = props.isOpen;
  var classes = classnames(baseHtmlClass, className, {
    active: isActive,
    open: isOpen
  });

  if (!isEmpty(baseHtmlClass)) {
    classes = classnames(classes, componentModifierClasses(props, baseHtmlClass, iconSize));
  }

  return classes;
};

export default aggregateClasses;
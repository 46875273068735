'use strict';

import { TOGGLE_ASSIGNMENT_TOPICS, TOGGLE_MULTIPLE_ASSIGNMENT_TOPICS, CLEAR_ASSIGNMENT_TOPICS } from 'constants/ActionTypes';
export var toggleAssignmentTopics = function toggleAssignmentTopics(_ref) {
  var topicId = _ref.topicId,
      checked = _ref.checked,
      shuffleQuestions = _ref.shuffleQuestions,
      _ref$courseName = _ref.courseName,
      courseName = _ref$courseName === void 0 ? '' : _ref$courseName,
      _ref$topicName = _ref.topicName,
      topicName = _ref$topicName === void 0 ? '' : _ref$topicName,
      _ref$groupedAssignmen = _ref.groupedAssignmentId,
      groupedAssignmentId = _ref$groupedAssignmen === void 0 ? '' : _ref$groupedAssignmen,
      hasNoQuestions = _ref.hasNoQuestions,
      _ref$numberOfQuestion = _ref.numberOfQuestions,
      numberOfQuestions = _ref$numberOfQuestion === void 0 ? 0 : _ref$numberOfQuestion,
      _ref$numberOfChapters = _ref.numberOfChapters,
      numberOfChapters = _ref$numberOfChapters === void 0 ? 0 : _ref$numberOfChapters,
      _ref$totalPoints = _ref.totalPoints,
      totalPoints = _ref$totalPoints === void 0 ? 0 : _ref$totalPoints;
  return {
    type: TOGGLE_ASSIGNMENT_TOPICS,
    topicId: topicId,
    checked: checked,
    shuffleQuestions: shuffleQuestions,
    courseName: courseName,
    topicName: topicName,
    groupedAssignmentId: groupedAssignmentId,
    hasNoQuestions: hasNoQuestions,
    numberOfQuestions: numberOfQuestions,
    numberOfChapters: numberOfChapters,
    totalPoints: totalPoints
  };
};
export var toggleMultipleAssignmentTopics = function toggleMultipleAssignmentTopics(assignmentTopics, checked) {
  var courseName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var groupedAssignmentId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
  return {
    type: TOGGLE_MULTIPLE_ASSIGNMENT_TOPICS,
    assignmentTopics: assignmentTopics,
    courseName: courseName,
    groupedAssignmentId: groupedAssignmentId,
    checked: checked
  };
};
export var clearAssignmentTopics = function clearAssignmentTopics() {
  return {
    type: CLEAR_ASSIGNMENT_TOPICS
  };
};
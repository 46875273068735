'use strict';

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { createZoomMeeting } from 'actions/ZoomMeetings';
export var initialState = {
  create: {
    status: 'idle',
    error: null
  }
};
export var zoomMeetings = reducerWithInitialState(initialState)["case"](createZoomMeeting.async.started, function (state) {
  return __assign(__assign({}, state), {
    create: __assign(__assign({}, state.create), {
      status: 'pending',
      error: null
    })
  });
})["case"](createZoomMeeting.async.failed, function (state, _a) {
  var error = _a.error;
  return __assign(__assign({}, state), {
    create: __assign(__assign({}, state.create), {
      status: 'error',
      error: error
    })
  });
})["case"](createZoomMeeting.async.done, function (state) {
  return __assign(__assign({}, state), {
    create: __assign(__assign({}, state.create), {
      status: 'success'
    })
  });
});
'use strict';

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { uploadFile, uploadFileRequest } from 'actions/File';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
export var initialState = {
  isRequestingUrl: false,
  isUploadingFile: false,
  error: undefined,
  uploadUrl: '',
  uploadKey: '',
  contentType: ''
};
export var file = reducerWithInitialState(initialState)["case"](uploadFileRequest.async.started, function (state) {
  return __assign(__assign({}, state), {
    isUploadingFile: true,
    isRequestingUrl: true,
    error: undefined
  });
})["case"](uploadFileRequest.async.failed, function (state, _a) {
  var error = _a.error;
  return __assign(__assign({}, state), {
    isRequestingUrl: false,
    error: error
  });
})["case"](uploadFileRequest.async.done, function (state, _a) {
  var result = _a.result;
  return __assign(__assign({}, state), {
    isRequestUrl: false,
    uploadUrl: result.data.upload_url,
    uploadKey: result.data.upload_key,
    contentType: result.data.content_type
  });
})["case"](uploadFile.async.started, function (state) {
  return __assign(__assign({}, state), {
    isUploadingFile: true
  });
})["case"](uploadFile.async.failed, function (state, _a) {
  var error = _a.error;
  return __assign(__assign({}, state), {
    isUploadingFile: false,
    error: error
  });
})["case"](uploadFile.async.done, function (state) {
  return __assign(__assign({}, state), {
    isUploadingFile: false
  });
});
'use strict';

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import config from 'config';
import * as cookie from 'cookie_js';
import * as Pusher from 'pusher-js';
import * as React from 'react';
import { connect } from 'react-redux';
import I18n from 'utils/I18n';

var PusherHandler =
/** @class */
function (_super) {
  __extends(PusherHandler, _super);

  function PusherHandler(props) {
    return _super.call(this, props) || this;
  }

  PusherHandler.prototype.componentDidMount = function () {
    if (this.props.isSetupDone) {
      var eventName = this.props.eventName;
      this.bindPusherEvent(this.channel(), eventName);
    }
  };

  PusherHandler.prototype.componentDidUpdate = function (prevProps) {
    if (this.props.isSetupDone && !prevProps.isSetupDone) {
      var eventName = this.props.eventName;
      this.bindPusherEvent(this.channel(), eventName);
    }
  };

  PusherHandler.prototype.componentWillUnmount = function () {
    if (this.props.isSetupDone) {
      this.unbindPusherEvent(this.channel(), this.props.eventName);
    }
  };

  PusherHandler.prototype.bindPusherEvent = function (channel, event) {
    var pusherChannel = PusherHandler.client.channels.find(channel) || PusherHandler.client.subscribe(channel);
    pusherChannel.bind(event, this.props.onUpdate);

    if (PusherHandler.referenceCounter[channel] === undefined) {
      PusherHandler.referenceCounter[channel] = 0;
    }

    PusherHandler.referenceCounter[channel]++;
  };

  PusherHandler.prototype.unbindPusherEvent = function (channel, event) {
    var pusherChannel = PusherHandler.client.channels.find(channel);

    if (pusherChannel) {
      pusherChannel.unbind(event, this.props.onUpdate);
    }

    PusherHandler.referenceCounter[channel]--;

    if (PusherHandler.referenceCounter[channel] <= 0) {
      delete PusherHandler.referenceCounter[channel];
      PusherHandler.client.unsubscribe(channel);
    }
  };

  PusherHandler.prototype.channel = function () {
    return "private-messaging-" + PusherHandler.userId;
  };

  PusherHandler.prototype.render = function () {
    // tslint:disable-next-line: no-null-keyword
    return null;
  };

  PusherHandler.referenceCounter = {};
  return PusherHandler;
}(React.Component);

export { PusherHandler };

var mapStateToProps = function mapStateToProps(state) {
  return {
    isSetupDone: state.pusher.setupDone
  };
};

export var setupPusherWithUserId = function setupPusherWithUserId(userId) {
  // tslint:disable-next-line: no-any
  if (!config.pusher_key) {
    throw new Error('Set PUSHER_KEY to setup pusher');
  } // tslint:disable-next-line: no-any


  var client = new Pusher(config.pusher_key, {
    encrypted: true,
    authEndpoint: "/" + I18n.currentLocale() + "/api/authorizations/pusher.json",
    auth: {
      headers: {
        Authorization: ("Token " + cookie.get(config.auth_cookie_name)).trim()
      }
    }
  });
  PusherHandler.client = client;
  PusherHandler.userId = userId;
};
export default I18n.isSiteModeAya() ? PusherHandler : connect(mapStateToProps)(PusherHandler);
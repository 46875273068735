'use strict';

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { setCurrentConversationId } from 'actions/Conversation';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
export var conversationInitialState = {
  currentId: undefined
};
export var conversation = reducerWithInitialState(conversationInitialState)["case"](setCurrentConversationId, function (state, payload) {
  return __assign(__assign({}, state), {
    currentId: payload.conversationId
  });
});
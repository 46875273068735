'use strict';

import FontFaceObserver from 'fontfaceobserver';
import I18n from 'app/utils/I18n';

(function () {
  if (process.env.APP_CONFIG.web_font_disable === 'true') {
    return;
  }

  var config = {
    loadingClassName: 'qlink-wf-loading',
    availableClassName: 'qlink-wf-available',
    classNameAya: 'qlink-wf-aya',
    classNameQS: 'qlink-wf-qs',
    timeout: 15000
  };

  var init = function init(href) {
    document.documentElement.classList.add(config.loadingClassName);
    var head = document.getElementsByTagName('head')[0];
    var link = document.createElement('link');
    link.href = href;
    link.rel = 'stylesheet';
    head.appendChild(link);
  };

  var available = function available() {
    document.documentElement.classList.remove(config.loadingClassName);
    document.documentElement.classList.add(config.availableClassName);
  };

  var unavailable = function unavailable() {
    document.documentElement.classList.remove(config.loadingClassName);
  };

  if (I18n.isSiteModeAya()) {
    return;
  }

  var fontFaceObserverQS = function fontFaceObserverQS() {
    var timeout = config.timeout;
    var OpenSans400 = new FontFaceObserver('Open Sans', {
      weight: 400
    });
    var OpenSans600 = new FontFaceObserver('Open Sans', {
      weight: 600
    });
    var OpenSans700 = new FontFaceObserver('Open Sans', {
      weight: 700
    });
    var OpenSans400i = new FontFaceObserver('Open Sans', {
      weight: 400,
      style: 'italic'
    });
    var OpenSans700i = new FontFaceObserver('Open Sans', {
      weight: 700,
      style: 'italic'
    });
    Promise.all([OpenSans400.load(null, timeout), OpenSans600.load(null, timeout), OpenSans700.load(null, timeout), OpenSans400i.load(null, timeout), OpenSans700i.load(null, timeout)]).then(function () {
      document.documentElement.classList.add(config.classNameQS);
      available();
    }, function () {
      unavailable();
    });
  };

  init('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,700,700i&amp;subset=vietnamese');
  fontFaceObserverQS();
})();
'use strict';

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { getTeacherProfile, updateTeacherProfile } from '../actions/TeacherProfile';
export var initialState = {
  isFetching: false,
  isUpdating: false,
  teacherProfile: undefined,
  error: undefined,
  updateError: undefined
};
export var teacherProfile = reducerWithInitialState(initialState)["case"](getTeacherProfile.async.started, function (state) {
  return __assign(__assign({}, state), {
    isFetching: true
  });
})["case"](getTeacherProfile.async.failed, function (state, _a) {
  var error = _a.error;
  return __assign(__assign({}, state), {
    isFetching: false,
    error: error
  });
})["case"](getTeacherProfile.async.done, function (state, _a) {
  var result = _a.result;
  return __assign(__assign({}, state), {
    error: undefined,
    isFetching: false,
    teacherProfile: result.data
  });
})["case"](updateTeacherProfile.async.started, function (state) {
  return __assign(__assign({}, state), {
    isUpdating: true
  });
})["case"](updateTeacherProfile.async.failed, function (state, _a) {
  var error = _a.error;
  return __assign(__assign({}, state), {
    isUpdating: false,
    updateError: error
  });
})["case"](updateTeacherProfile.async.done, function (state, _a) {
  var result = _a.result;
  return __assign(__assign({}, state), {
    isUpdating: false,
    updateError: undefined,
    teacherProfile: state.teacherProfile = result.data
  });
});
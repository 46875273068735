'use strict';

import _omit from "lodash/omit";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { schema } from 'normalizr';
import { ConversationType } from 'interfaces/conversation';
import { messageArraySchema } from 'schemas/messages';

var getType = function getType(conversation) {
  var participants = conversation.participants.filter(function (p) {
    return !p.is_bot;
  });

  if (conversation.conversation_type === ConversationType.Announcement) {
    return ConversationType.Announcement;
  } else if (participants.length > 2) {
    return ConversationType.Group;
  } else {
    return ConversationType.Private;
  }
};

export var conversationSchema = new schema.Entity('conversations', {
  messages: messageArraySchema
}, {
  // Convert from Unix Epoch time to JavaScript milliseconds
  processStrategy: function processStrategy(conversation) {
    var processedConversationType = getType(conversation);
    var createdAtMs = conversation.created_ts * 1000;
    var updatedAtMs = conversation.updated_ts * 1000;

    var processedConversation = _omit(conversation, ['created_ts', 'updated_ts']);

    return __assign(__assign({}, processedConversation), {
      created_at_ms: createdAtMs,
      updated_at_ms: updatedAtMs,
      processed_type: processedConversationType
    });
  }
});
export var conversationArraySchema = new schema.Array(conversationSchema);
conversationSchema.define({
  messages: messageArraySchema
});
'use strict';

import LocalStorage from 'utils/storage/LocalStorage';
export var saveZoomAuth = function saveZoomAuth(accessToken, expiresIn) {
  LocalStorage.setItem('liveclass-token', accessToken);
  LocalStorage.setItem('liveclass-expires-in', expiresIn);
};
export var resetZoomAuth = function resetZoomAuth() {
  LocalStorage.removeItem('liveclass-token');
  LocalStorage.removeItem('liveclass-expires-in');
};
export var getZoomAuth = function getZoomAuth() {
  return {
    access_token: LocalStorage.getItem('liveclass-token'),
    expires_in: LocalStorage.getItem('liveclass-expires-in')
  };
};
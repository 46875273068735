'use strict';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { FETCH_SELF_ADMINISTERED_STUDENTS_REQUEST, FETCH_SELF_ADMINISTERED_STUDENTS_SUCCESS, FETCH_SELF_ADMINISTERED_STUDENTS_FAILURE } from 'constants/ActionTypes';
var initialState = {
  isFetching: false,
  items: [],
  error: null,
  selected: null
};

function _students() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_SELF_ADMINISTERED_STUDENTS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true
      });

    case FETCH_SELF_ADMINISTERED_STUDENTS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        error: action.error
      });

    case FETCH_SELF_ADMINISTERED_STUDENTS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        valid: true,
        items: action.response.result.selfAdminStudentsMembership,
        total_count: action.response.total_count,
        page: action.response.page,
        per_page: action.response.per_page
      });

    default:
      return state;
  }
}

export default function students() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_SELF_ADMINISTERED_STUDENTS_SUCCESS:
    case FETCH_SELF_ADMINISTERED_STUDENTS_REQUEST:
    case FETCH_SELF_ADMINISTERED_STUDENTS_FAILURE:
      return _objectSpread(_objectSpread({}, state), _students(state, action));

    default:
      return state;
  }
}
'use strict';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { FETCH_INVITATION_REQUEST, FETCH_INVITATION_SUCCESS, FETCH_INVITATION_FAILURE, FETCH_SIGNUP_REQUEST, FETCH_SIGNUP_SUCCESS_CODE, FETCH_SIGNUP_SUCCESS_ORGANIZATION, FETCH_SIGNUP_FAILURE, SUBMIT_SIGNUP_REQUEST, SUBMIT_SIGNUP_SUCCESS, SUBMIT_SIGNUP_FAILURE, SUBMIT_EMAIL_CONFIRM_REQUEST, SUBMIT_EMAIL_CONFIRM_SUCCESS, SUBMIT_EMAIL_CONFIRM_FAILURE, RESEND_EMAIL_CONFIRM_REQUEST, RESEND_EMAIL_CONFIRM_SUCCESS, RESEND_EMAIL_CONFIRM_FAILURE, CLOSE_USER_NOTIFICATION, ACCEPT_TERMS_REQUEST, ACCEPT_TERMS_SUCCESS, ACCEPT_TERMS_FAILURE } from 'constants/ActionTypes';
var initialState = {
  isFetchingInvitation: false
};

function _user() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_INVITATION_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetchingInvitation: true
      });

    case FETCH_INVITATION_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetchingInvitation: false,
        invitee: action.invitee
      });

    case FETCH_INVITATION_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetchingInvitation: false,
        noInvitation: action.noInvitation
      });

    case FETCH_SIGNUP_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetchingSignupDetails: true
      });

    case FETCH_SIGNUP_SUCCESS_CODE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetchingSignupDetails: false,
        signupDetails: action.signupDetails
      });

    case FETCH_SIGNUP_SUCCESS_ORGANIZATION:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetchingSignupDetails: false,
        organization: action.organization
      });

    case FETCH_SIGNUP_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetchingSignupDetails: false,
        error: action.error
      });

    case SUBMIT_SIGNUP_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isSubmitting: true
      });

    case SUBMIT_SIGNUP_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isSubmitting: false,
        success: true
      });

    case SUBMIT_SIGNUP_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isSubmitting: false,
        errors: action.errors
      });

    case SUBMIT_EMAIL_CONFIRM_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isConfirming: true
      });

    case SUBMIT_EMAIL_CONFIRM_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isConfirming: false,
        confirmationObject: action.confirmationObject
      });

    case SUBMIT_EMAIL_CONFIRM_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isConfirming: false,
        confirmationErrors: action.confirmationErrors
      });

    case RESEND_EMAIL_CONFIRM_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isResendingConfirmLink: true
      });

    case RESEND_EMAIL_CONFIRM_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isResendingConfirmLink: false,
        resendMessage: action.resendMessage
      });

    case RESEND_EMAIL_CONFIRM_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isResendingConfirmLink: false,
        resendError: action.resendError
      });

    case CLOSE_USER_NOTIFICATION:
      return _objectSpread(_objectSpread({}, state), {}, {
        isUserNotificationClosed: true
      });

    case ACCEPT_TERMS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isAcceptTermsLoading: true
      });

    case ACCEPT_TERMS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isAcceptTermsLoading: false,
        legalConsentStatus: action.legalConsentStatus
      });

    case ACCEPT_TERMS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isAcceptTermsLoading: false,
        errors: action.errors
      });

    default:
      return state;
  }
}

export default function user() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_INVITATION_REQUEST:
    case FETCH_INVITATION_SUCCESS:
    case FETCH_INVITATION_FAILURE:
    case FETCH_SIGNUP_REQUEST:
    case FETCH_SIGNUP_SUCCESS_CODE:
    case FETCH_SIGNUP_SUCCESS_ORGANIZATION:
    case FETCH_SIGNUP_FAILURE:
    case SUBMIT_SIGNUP_REQUEST:
    case SUBMIT_SIGNUP_SUCCESS:
    case SUBMIT_SIGNUP_FAILURE:
    case SUBMIT_EMAIL_CONFIRM_REQUEST:
    case SUBMIT_EMAIL_CONFIRM_SUCCESS:
    case SUBMIT_EMAIL_CONFIRM_FAILURE:
    case RESEND_EMAIL_CONFIRM_REQUEST:
    case RESEND_EMAIL_CONFIRM_SUCCESS:
    case RESEND_EMAIL_CONFIRM_FAILURE:
    case CLOSE_USER_NOTIFICATION:
    case ACCEPT_TERMS_REQUEST:
    case ACCEPT_TERMS_SUCCESS:
    case ACCEPT_TERMS_FAILURE:
      return _objectSpread(_objectSpread({}, state), _user(state, action));

    default:
      return state;
  }
}
'use strict';

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { fetchPeople, resetPeople } from 'actions/People';
export var initialState = {
  fetchingState: 'idle',
  error: undefined,
  data: [],
  pagination: {
    page: 0,
    per_page: 0,
    total: 0
  }
};
export var people = reducerWithInitialState(initialState)["case"](fetchPeople.async.started, function (state) {
  return __assign(__assign({}, state), {
    fetchingState: 'pending',
    error: undefined
  });
})["case"](fetchPeople.async.failed, function (state, _a) {
  var error = _a.error;
  return __assign(__assign({}, state), {
    fetchingState: 'done',
    error: error
  });
})["case"](fetchPeople.async.done, function (state, _a) {
  var result = _a.result;
  var isLoadMoreResult = state.pagination.page < result.pagination.page;
  return __assign(__assign({}, state), {
    fetchingState: 'done',
    data: isLoadMoreResult ? __spreadArrays(state.data, result.data) : result.data,
    pagination: result.pagination
  });
})["case"](resetPeople, function (state) {
  return __assign(__assign({}, state), initialState);
});
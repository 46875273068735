'use strict';

import _keys from "lodash/keys";
import _difference from "lodash/difference";
import _omit from "lodash/omit";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { TOGGLE_ASSIGNMENT_TOPICS, TOGGLE_MULTIPLE_ASSIGNMENT_TOPICS, CLEAR_ASSIGNMENT_TOPICS } from 'constants/ActionTypes';
var initialState = {
  items: {},
  groupedItems: []
};

function _assignmentTopics() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var items = state.items,
      groupedItems = state.groupedItems;
  var type = action.type,
      checked = action.checked,
      shuffleQuestions = action.shuffleQuestions,
      topicId = action.topicId,
      courseName = action.courseName,
      topicName = action.topicName,
      groupedAssignmentId = action.groupedAssignmentId,
      hasNoQuestions = action.hasNoQuestions,
      numberOfQuestions = action.numberOfQuestions,
      numberOfChapters = action.numberOfChapters,
      totalPoints = action.totalPoints;

  switch (type) {
    case TOGGLE_ASSIGNMENT_TOPICS:
      if (checked) {
        return _objectSpread(_objectSpread({}, state), {}, {
          items: _objectSpread(_objectSpread({}, items), {}, _defineProperty({}, topicId, {
            topicId: topicId,
            courseName: courseName,
            topicName: topicName,
            shuffleQuestions: shuffleQuestions,
            hasNoQuestions: hasNoQuestions,
            numberOfQuestions: numberOfQuestions,
            numberOfChapters: numberOfChapters,
            totalPoints: totalPoints
          }))
        }, groupedItems);
      }

      if (checked === false) {
        return _objectSpread(_objectSpread({}, state), {}, {
          items: _omit(items, [topicId]),
          groupedItems: _difference(groupedItems, [groupedAssignmentId])
        });
      }

      throw new Error('TOGGLE_ASSIGNMENT_TOPICS must have action.checked');

    case CLEAR_ASSIGNMENT_TOPICS:
      return _objectSpread(_objectSpread({}, state), {}, {
        items: {},
        groupedItems: []
      });

    default:
      return state;
  }
}

function _multipleAssignmentTopics() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var items = state.items,
      groupedItems = state.groupedItems; // groupedAssignmentId is the id of the course (aya) or bundle (global) the topics belongs to

  var assignmentTopics = action.assignmentTopics,
      courseName = action.courseName,
      groupedAssignmentId = action.groupedAssignmentId,
      checked = action.checked;
  var topics = assignmentTopics.reduce(function (obj, assignment) {
    return _objectSpread(_objectSpread({}, obj), {}, _defineProperty({}, assignment.topicId, _objectSpread(_objectSpread({}, assignment), {}, {
      courseName: courseName,
      groupedAssignmentId: groupedAssignmentId
    })));
  }, {});

  if (checked) {
    return _objectSpread(_objectSpread({}, state), {}, {
      items: _objectSpread(_objectSpread({}, items), topics),
      groupedItems: _toConsumableArray(new Set([].concat(_toConsumableArray(groupedItems || []), [groupedAssignmentId])))
    });
  }

  return _objectSpread(_objectSpread({}, state), {}, {
    items: _omit(items, _keys(topics)),
    groupedItems: _difference(groupedItems, [groupedAssignmentId])
  });
}

var assignmentTopics = function assignmentTopics() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case TOGGLE_ASSIGNMENT_TOPICS:
    case CLEAR_ASSIGNMENT_TOPICS:
      return _objectSpread(_objectSpread({}, state), _assignmentTopics(state, action));

    case TOGGLE_MULTIPLE_ASSIGNMENT_TOPICS:
      return _objectSpread(_objectSpread({}, state), _multipleAssignmentTopics(state, action));

    default:
      return state;
  }
};

export default assignmentTopics;
'use strict';

import I18n from 'utils/I18n';

var Site =
/** @class */
function () {
  function Site() {}

  Site.brandLogo = function () {
    if (I18n.isSiteModeAya()) {
      return 'logo-sapuri_for_teachers_vertical';
    }

    return 'logo-quipper_new';
  };

  return Site;
}();

export default Site;
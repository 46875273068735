'use strict';

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { FETCH_AVAILABLE_USAGE_REPORTS_REQUEST, FETCH_AVAILABLE_USAGE_REPORTS_FAILURE, FETCH_AVAILABLE_USAGE_REPORTS_SUCCESS, REQUEST_USAGE_REPORT, REQUEST_USAGE_REPORT_FAILURE, REQUEST_USAGE_REPORT_SUCCESS } from 'constants/ActionTypes';
export var initialState = {
  error: undefined,
  statuses: {},
  available: {
    status: 'idle',
    data: [],
    error: undefined
  }
}; // tslint:disable-next-line no-any

function _usage(state, action) {
  var newStatuses = __assign({}, state.statuses);

  switch (action.type) {
    case FETCH_AVAILABLE_USAGE_REPORTS_REQUEST:
      return {
        available: __assign(__assign({}, state.available), {
          status: 'pending',
          data: [],
          error: null
        })
      };

    case FETCH_AVAILABLE_USAGE_REPORTS_FAILURE:
      return {
        available: __assign(__assign({}, state.available), {
          status: 'failure',
          error: action.error
        })
      };

    case FETCH_AVAILABLE_USAGE_REPORTS_SUCCESS:
      return {
        available: __assign(__assign({}, state.available), {
          status: 'success',
          data: action.response
        })
      };

    case REQUEST_USAGE_REPORT:
      {
        newStatuses[action.dates] = 'requesting';
        return __assign(__assign({}, state), {
          statuses: newStatuses
        });
      }

    case REQUEST_USAGE_REPORT_SUCCESS:
      {
        newStatuses[action.dates] = 'success';
        return __assign(__assign({}, state), {
          statuses: newStatuses
        });
      }

    case REQUEST_USAGE_REPORT_FAILURE:
      {
        newStatuses[action.dates] = 'failure';
        return __assign(__assign({}, state), {
          statuses: newStatuses
        });
      }

    default:
      return state;
  }
} // tslint:disable-next-line no-any


export default function usage(state, action) {
  if (state === void 0) {
    state = initialState;
  }

  switch (action.type) {
    case FETCH_AVAILABLE_USAGE_REPORTS_REQUEST:
    case FETCH_AVAILABLE_USAGE_REPORTS_FAILURE:
    case FETCH_AVAILABLE_USAGE_REPORTS_SUCCESS:
    case REQUEST_USAGE_REPORT_FAILURE:
    case REQUEST_USAGE_REPORT:
    case REQUEST_USAGE_REPORT_SUCCESS:
      return __assign(__assign({}, state), _usage(state, action));

    default:
      return state;
  }
}
'use strict';

import UrlHelper from 'utils/UrlHelper';

var Browser =
/** @class */
function () {
  function Browser() {}

  Browser.reload = function () {
    return window.location.reload();
  }; // For testing purposes to stub window.location object


  Browser.href = function () {
    return window.location.href;
  }; // For testing purposes to stub window.location object


  Browser.pathname = function () {
    return window.location.pathname;
  }; // For testing purposes to stub window.location object


  Browser.hash = function () {
    return window.location.hash;
  }; // For testing purposes to stub window.location object


  Browser.search = function () {
    return window.location.search;
  }; // For testing purposes


  Browser.setWindowHref = function (href, origin) {
    window.location.href = "" + (origin || this.getOrigin()) + href;
  };

  Browser.setWindowHash = function (hash) {
    window.location.hash = hash;
  }; // Wrap for testing purposes


  Browser.scrollTo = function (x, y) {
    window.scrollTo(x, y);
  };

  Browser.getRootDomain = function (host) {
    if (host === void 0) {
      host = location.host;
    }

    var removePort = function removePort(s) {
      return s.split(':')[0];
    };

    var parts = host.split('.');
    var ipv4Regex = /^(\d{1,3}\.){3,3}\d{1,3}$/;

    if (parts.length === 1) {
      return removePort(parts[0]);
    }

    if (ipv4Regex.test(removePort(host))) {
      return removePort(host);
    }

    return removePort(parts.slice(parts.length - 2, parts.length).join('.'));
  };

  Browser.getOrganizationId = function () {
    var regex = /^.+\/organizations\/([a-z0-9]+)\/?/;
    var matches = this.pathname().match(regex);
    return matches ? matches[1] : undefined;
  }; // Converts current path to organization-restricted one


  Browser.organizationRestrictedPath = function (organizationId) {
    var pathnames = this.pathname().split('/');
    var removeCount = 0;

    if (pathnames[2] === 'organizations') {
      removeCount = 2;
    }

    pathnames.splice(2, removeCount, 'organizations', organizationId);
    return UrlHelper.buildPath({
      pathname: pathnames.join('/')
    });
  };
  /*
   * Original code for isIE and isEdge were taken from
   * monorepo/qlearn-react/src/utils/Browser.ts
   */


  Browser.isIE = function () {
    // [bowser](https://github.com/lancedikson/bowser) is too much for now https://stackoverflow.com/a/9851769/225291
    // @ts-ignore - documentMode is only IE
    return (
      /*@cc_on!@*/
      false || !!document.documentMode
    );
  };

  Browser.isEdge = function () {
    return navigator.userAgent.indexOf('Edge') > -1;
  };

  Browser.getOrigin = function () {
    return window.location.origin;
  };

  return Browser;
}();

export default Browser;
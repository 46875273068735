'use strict';

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { getActivities } from 'actions/Activities';
export var initialState = {
  isFetching: false,
  error: undefined,
  items: [],
  page: 1,
  perPage: 100,
  totalCount: 0
};
export var activities = reducerWithInitialState(initialState)["case"](getActivities.async.started, function (state) {
  return __assign(__assign({}, state), {
    isFetching: true
  });
})["case"](getActivities.async.failed, function (state, _a) {
  var error = _a.error;
  return __assign(__assign({}, state), {
    isFetching: false,
    error: error
  });
})["case"](getActivities.async.done, function (state, _a) {
  var result = _a.result;
  var page = result.headers['x-quipper-page'] ? parseInt(result.headers['x-quipper-page'], 10) : 1;
  return __assign(__assign({}, state), {
    error: undefined,
    isFetching: false,
    items: page === 1 ? result.data : state.items.concat(result.data),
    page: page,
    perPage: result.headers['x-quipper-per-page'] ? parseInt(result.headers['x-quipper-per-page'], 10) : 100,
    totalCount: parseInt(result.headers['x-quipper-total-count'], 10)
  });
});
'use strict';

import _some from "lodash/some";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import { normalize } from 'normalizr';
import { joinClassArraySchema } from 'schemas/joinClasses';
import { SHOW_JOIN_CLASSES_REQUEST, SHOW_JOIN_CLASSES_SUCCESS } from 'constants/ActionTypes';
export function joinClassesByOrganizationAndUser(organizations, organizationId, user) {
  var joinClasses = _filter(organizations[organizationId].classes, function (_class) {
    return _some(_class.managers, function (manager) {
      return manager.id === user.id;
    });
  });

  var response = normalize({
    joinClasses: joinClasses
  }, {
    joinClasses: joinClassArraySchema
  });
  return {
    type: SHOW_JOIN_CLASSES_SUCCESS,
    response: response
  };
}
export function requestShowJoinClasses() {
  return {
    type: SHOW_JOIN_CLASSES_REQUEST
  };
}
export function showJoinClasses() {
  return function (dispatch, getState) {
    var organizationId = getState().organizations.current;
    var organizations = getState().entities.organizations;
    var currentUser = getState().auth.currentUser;

    if (!_isEmpty(organizations[organizationId])) {
      dispatch(joinClassesByOrganizationAndUser(organizations, organizationId, currentUser));
    }
  };
}
export var showJoinClassesIfNeeded = function showJoinClassesIfNeeded() {
  return function (dispatch, getState) {
    var _getState = getState(),
        joinClasses = _getState.joinClasses;

    if (_isEmpty(joinClasses)) {
      return dispatch(showJoinClasses());
    }

    return Promise.resolve(false);
  };
};
'use strict';

export var postTdEvent = function postTdEvent() {
  return function (despatch, getState) {
    var userId = getState().auth.currentUser.id;
    var payload = {
      organization_id: getState().organizations.current
    };
    var eventName = 'teacher_autologin';

    if (process.env.APP_CONFIG.site_env === 'development') {
      /* eslint-disable no-console */
      console.log('Sent tdTrackEvent with', userId, eventName, payload);
    } else {
      tdClient.trackEvent(userId, eventName, payload);
    }
  };
};
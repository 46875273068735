'use strict';

import Browser from 'utils/Browser';
import I18n from 'utils/I18n';

var UrlHelper =
/** @class */
function () {
  function UrlHelper() {}
  /*
   * Usage:
   *
   * buildOrganizationBasedPath('curriculum')     // => '/en/organizations/001/curriculum'
   * buildOrganizationBasedPath('curriculum/foo') // => '/en/organizations/001/curriculum/foo'
   */


  UrlHelper.buildOrganizationBasedPath = function (path, organizationId) {
    if (path === void 0) {
      path = '';
    }

    if (organizationId === void 0) {
      organizationId = Browser.getOrganizationId();
    }

    if (organizationId) {
      return "/organizations/" + organizationId + "/" + path;
    }

    return '/';
  };
  /*
   * Usage:
   *
   * buildOrganizationBasedPath('curriculum')     // => '/en/api/organizations/001/curriculum'
   * buildOrganizationBasedPath('curriculum/foo') // => '/en/api/organizations/001/curriculum/foo'
   */


  UrlHelper.buildOrganizationBasedAPIPath = function (path) {
    if (path === void 0) {
      path = '';
    }

    var organizationId = Browser.getOrganizationId();

    if (organizationId) {
      return "/" + I18n.currentLocale() + "/api/organizations/" + organizationId + "/" + path;
    }

    return "/" + I18n.currentLocale() + "/api/";
  };

  UrlHelper.buildPath = function (_a) {
    var _b = _a === void 0 ? {} : _a,
        _c = _b.pathname,
        pathname = _c === void 0 ? Browser.pathname() : _c,
        _d = _b.search,
        search = _d === void 0 ? Browser.search() : _d,
        _e = _b.hash,
        hash = _e === void 0 ? Browser.hash() : _e;

    var path = [pathname, search, hash].join('');
    return path.charAt(0) === '/' ? path : "/" + path;
  };

  UrlHelper.defaultMenu = function (user) {
    if (user.sapuri_support_center_agent) {
      return 'management';
    } else {
      return 'dashboard';
    }
  };

  return UrlHelper;
}();

export default UrlHelper;
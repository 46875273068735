'use strict';

import { useState, useEffect } from 'react';

var useNetworkStatus = function useNetworkStatus() {
  var _a = useState('online'),
      networkStatus = _a[0],
      setNetworkStatus = _a[1];

  useEffect(function () {
    var setNetworkStatusToOffline = function setNetworkStatusToOffline() {
      return setNetworkStatus('offline');
    };

    var setNetworkStatusToOnline = function setNetworkStatusToOnline() {
      return setNetworkStatus('online');
    };

    window.addEventListener('offline', setNetworkStatusToOffline);
    window.addEventListener('online', setNetworkStatusToOnline);
    return function () {
      window.removeEventListener('offline', setNetworkStatusToOffline);
      window.removeEventListener('online', setNetworkStatusToOnline);
    };
  }, []);
  return networkStatus;
};

export default useNetworkStatus;
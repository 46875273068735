'use strict';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { FETCH_BILLING_DATA_FILES_REQUEST, FETCH_BILLING_DATA_FILES_SUCCESS, FETCH_BILLING_DATA_FILES_FAILURE } from 'constants/ActionTypes';
var initialState = {
  isFetching: false,
  items: [],
  error: undefined
};

var _billingDataFiles = function _billingDataFiles(state, action) {
  switch (action.type) {
    case FETCH_BILLING_DATA_FILES_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true
      });

    case FETCH_BILLING_DATA_FILES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        items: action.billingDataFiles
      });

    case FETCH_BILLING_DATA_FILES_FAILURE:
      return {
        isFetching: false,
        error: action.error
      };

    default:
      return state;
  }
};

export default function billingDataFiles() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_BILLING_DATA_FILES_REQUEST:
    case FETCH_BILLING_DATA_FILES_SUCCESS:
    case FETCH_BILLING_DATA_FILES_FAILURE:
      return _objectSpread(_objectSpread({}, state), _billingDataFiles(state, action));

    default:
      return state;
  }
}
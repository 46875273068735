'use strict';

import * as Sentry from '@sentry/browser';
import SentryHelper from 'utils/SentryHelper';
/*
 * Applies localStorage polyfill. Please use instead of window.localStorage
 * This is needed for browsers that disable storage.
 *
 * This is meant to not throw errors in those browsers.
 * If you need to store and access data, this needs modification
 */

var polyfill;

try {
  localStorage.setItem('test', '1');
  localStorage.removeItem('test');
  polyfill = localStorage;
} catch (err) {
  if (SentryHelper.isSentrySupport()) {
    Sentry.captureException(err);
  }
  /*
   * TypeError handles case when window.localStorage is not available
   * QuotaExceededError handles case when browser blocks writing to localStorage (e.g. Safari private)
   */


  polyfill = {
    key: function key() {},
    setItem: function setItem() {},
    getItem: function getItem() {},
    removeItem: function removeItem() {},
    clear: function clear() {}
  };
}

var LocalStorage = polyfill;
export default LocalStorage;
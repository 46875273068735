'use strict';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { UPLOAD_SELF_ADMIN_CSV_REQUEST, UPLOAD_SELF_ADMIN_CSV_SUCCESS, UPLOAD_SELF_ADMIN_CSV_FAILURE, UPLOAD_SELF_ADMIN_CSV_VALIDATION_SUCCESS, UPLOAD_SELF_ADMIN_CSV_VALIDATION_PARTIAL_SUCCESS, UPLOAD_SELF_ADMIN_CSV_CLEAR, UPLOAD_SELF_ADMIN_CSV_CONTINUE_WITH_WARNING } from 'constants/ActionTypes';
export var MODAL_VIEW = {
  INITIAL: 'initial',
  WARNING: 'warning',
  CONFIRMATION: 'confirmation'
};
var initialState = {
  isFetching: false,
  file: null,
  error: null,
  view: MODAL_VIEW.INITIAL
};

var uploadCsvReducer = function uploadCsvReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case UPLOAD_SELF_ADMIN_CSV_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true
      });

    case UPLOAD_SELF_ADMIN_CSV_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        error: null,
        view: MODAL_VIEW.CONFIRMATION
      });

    case UPLOAD_SELF_ADMIN_CSV_VALIDATION_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        error: null,
        file: action.data,
        view: MODAL_VIEW.INITIAL
      });

    case UPLOAD_SELF_ADMIN_CSV_VALIDATION_PARTIAL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        file: action.data,
        error: action.error,
        view: MODAL_VIEW.INITIAL
      });

    case UPLOAD_SELF_ADMIN_CSV_CONTINUE_WITH_WARNING:
      return _objectSpread(_objectSpread({}, state), {}, {
        view: MODAL_VIEW.WARNING
      });

    case UPLOAD_SELF_ADMIN_CSV_CLEAR:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        file: null,
        error: null,
        view: MODAL_VIEW.INITIAL
      });

    case UPLOAD_SELF_ADMIN_CSV_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        file: action.data,
        error: action.error,
        view: MODAL_VIEW.INITIAL
      });

    default:
      return state;
  }
};

export default uploadCsvReducer;
'use strict';

import _omit from "lodash/omit";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { CREATE_ENTITY_REQUEST, CREATE_ENTITY_SUCCESS, CREATE_ENTITY_FAILURE, FETCH_ENTITY_REQUEST, FETCH_ENTITY_SUCCESS, FETCH_ENTITY_FAILURE, UPDATE_ENTITY_REQUEST, UPDATE_ENTITY_SUCCESS, UPDATE_ENTITY_FAILURE, DELETE_ENTITY_REQUEST, DELETE_ENTITY_SUCCESS, DELETE_ENTITY_FAILURE, CLEAR_ENTITY } from 'constants/ActionTypes';

var _entity = function _entity() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_ENTITY_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true,
        error: undefined
      });

    case FETCH_ENTITY_SUCCESS:
      {
        if (action.entity && action.entity.entities) {
          return _objectSpread(_objectSpread({}, state), {}, {
            isFetching: false,
            error: undefined,
            results: action.results || [],
            entities: _objectSpread(_objectSpread({}, state.entities), action.entity.entities)
          });
        }

        return _objectSpread(_objectSpread(_objectSpread({}, state), action.entity), {}, {
          isFetching: false,
          error: undefined,
          results: action.results || []
        });
      }

    case FETCH_ENTITY_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        error: action.error
      });

    case CREATE_ENTITY_REQUEST:
    case UPDATE_ENTITY_REQUEST:
    case DELETE_ENTITY_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isUpdating: true,
        error: undefined
      });

    case CREATE_ENTITY_SUCCESS:
    case UPDATE_ENTITY_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.entity), {}, {
        isUpdating: false,
        error: undefined
      });

    case CREATE_ENTITY_FAILURE:
    case UPDATE_ENTITY_FAILURE:
    case DELETE_ENTITY_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isUpdating: false,
        error: action.error
      });

    case DELETE_ENTITY_SUCCESS:
      return {
        isUpdating: false,
        error: undefined
      };

    default:
      return state;
  }
};

var entity = function entity() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var entityId = action.entityId;

  switch (action.type) {
    case CREATE_ENTITY_REQUEST:
    case CREATE_ENTITY_SUCCESS:
    case CREATE_ENTITY_FAILURE:
    case FETCH_ENTITY_REQUEST:
    case FETCH_ENTITY_SUCCESS:
    case FETCH_ENTITY_FAILURE:
    case UPDATE_ENTITY_REQUEST:
    case UPDATE_ENTITY_SUCCESS:
    case UPDATE_ENTITY_FAILURE:
    case DELETE_ENTITY_REQUEST:
    case DELETE_ENTITY_SUCCESS:
    case DELETE_ENTITY_FAILURE:
      if (entityId) {
        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, entityId, _entity(state[entityId], action)));
      }

      return _objectSpread(_objectSpread({}, state), _entity(state, action));

    case CLEAR_ENTITY:
      if (entityId) {
        return _omit(state, entityId);
      }

      return {};

    default:
      return state;
  }
};

export default entity;
'use strict';

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { assessmentUpdateSearchCoursesKeyword, assessmentResetSelectedTopic, assessmentUpdateActiveStep, assessmentUpdateSelectedCoursesSource, assessmentUpdateSelectedTopic } from 'actions/assessment/dashboard';
import { ASSESSMENT_COURSES_SOURCE } from 'interfaces/assessment';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
export var initialState = {
  selectedTopic: {},
  coursesSource: ASSESSMENT_COURSES_SOURCE.QUIPPER,
  searchCoursesKeyword: ''
};
export default reducerWithInitialState(initialState)["case"](assessmentUpdateActiveStep, function (state, params) {
  return __assign(__assign({}, state), {
    activeStep: params.activeStep
  });
})["case"](assessmentUpdateSelectedTopic, function (state, params) {
  return __assign(__assign({}, state), {
    selectedTopic: __assign(__assign({}, state.selectedTopic), {
      topicId: params.topicId,
      courseId: params.courseId,
      sentClassIds: params.sentClassIds
    })
  });
})["case"](assessmentResetSelectedTopic, function (state) {
  return __assign(__assign({}, state), {
    selectedTopic: {}
  });
})["case"](assessmentUpdateSelectedCoursesSource, function (_, params) {
  return __assign(__assign({}, initialState), {
    coursesSource: params.coursesSource
  });
})["case"](assessmentUpdateSearchCoursesKeyword, function (state, params) {
  return __assign(__assign({}, state), {
    searchCoursesKeyword: params.searchCoursesKeyword
  });
});
'use strict';

import { createStore, applyMiddleware, compose } from 'redux';
import { autoRehydrate } from 'redux-persist';
import thunk from 'redux-thunk';
import rootReducer from 'reducers/index';
import configurePersistence from 'store/configurePersistence';
export default function configureStore() {
  var initialState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var store = createStore(rootReducer, initialState, compose(applyMiddleware(thunk), autoRehydrate(), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : function (f) {
    return f;
  }));
  configurePersistence(store);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('reducers', function () {
      var nextRootReducer = require('reducers')["default"]; // eslint-disable-line global-require


      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
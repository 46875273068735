module.exports = {
	"common": {
		"facebook": true,
		"content_error_report": true,
		"back_to_school": false,
		"campaign": true,
		"assessment": true,
		"quiz_and_review": true,
		"download_class_report": true,
		"teacher_search": false,
		"add_teacher_via_email": true,
		"student_detail": true,
		"assignment_group_select": true,
		"assignment_analysis": true,
		"self_study_quiz": true,
		"usage_reports": true,
		"shuffle_questions": true,
		"student_performance": true,
		"react_messages": true,
		"attach_files_on_message": false,
		"new_page_assessment": true
	},
	"common_non_production": null,
	"common_production_and_release": null,
	"production": {
		"facebook": true,
		"content_error_report": true,
		"back_to_school": false,
		"campaign": true,
		"assessment": true,
		"quiz_and_review": true,
		"download_class_report": true,
		"teacher_search": false,
		"add_teacher_via_email": true,
		"student_detail": true,
		"assignment_group_select": true,
		"assignment_analysis": true,
		"self_study_quiz": true,
		"usage_reports": true,
		"shuffle_questions": true,
		"student_performance": true,
		"react_messages": true,
		"attach_files_on_message": false,
		"new_page_assessment": true
	},
	"staging": {
		"facebook": true,
		"content_error_report": true,
		"back_to_school": false,
		"campaign": true,
		"assessment": true,
		"quiz_and_review": true,
		"download_class_report": true,
		"teacher_search": false,
		"add_teacher_via_email": true,
		"student_detail": true,
		"assignment_group_select": true,
		"assignment_analysis": true,
		"self_study_quiz": true,
		"usage_reports": true,
		"shuffle_questions": true,
		"student_performance": true,
		"react_messages": true,
		"attach_files_on_message": false,
		"new_page_assessment": true
	},
	"develop": {
		"facebook": true,
		"content_error_report": true,
		"back_to_school": false,
		"campaign": true,
		"assessment": true,
		"quiz_and_review": true,
		"download_class_report": true,
		"teacher_search": false,
		"add_teacher_via_email": true,
		"student_detail": true,
		"assignment_group_select": true,
		"assignment_analysis": true,
		"self_study_quiz": true,
		"usage_reports": true,
		"shuffle_questions": true,
		"student_performance": true,
		"react_messages": true,
		"attach_files_on_message": false,
		"new_page_assessment": true
	},
	"edge": {
		"facebook": true,
		"content_error_report": true,
		"back_to_school": false,
		"campaign": true,
		"assessment": true,
		"quiz_and_review": true,
		"download_class_report": true,
		"teacher_search": false,
		"add_teacher_via_email": true,
		"student_detail": true,
		"assignment_group_select": true,
		"assignment_analysis": true,
		"self_study_quiz": true,
		"usage_reports": true,
		"shuffle_questions": true,
		"student_performance": true,
		"react_messages": true,
		"attach_files_on_message": false,
		"new_page_assessment": true
	},
	"release": {
		"facebook": true,
		"content_error_report": true,
		"back_to_school": false,
		"campaign": true,
		"assessment": true,
		"quiz_and_review": true,
		"download_class_report": true,
		"teacher_search": false,
		"add_teacher_via_email": true,
		"student_detail": true,
		"assignment_group_select": true,
		"assignment_analysis": true,
		"self_study_quiz": true,
		"usage_reports": true,
		"shuffle_questions": true,
		"student_performance": true,
		"react_messages": true,
		"attach_files_on_message": false,
		"new_page_assessment": true
	},
	"development": {
		"facebook": true,
		"content_error_report": true,
		"back_to_school": false,
		"campaign": true,
		"assessment": true,
		"quiz_and_review": true,
		"download_class_report": true,
		"teacher_search": false,
		"add_teacher_via_email": true,
		"student_detail": true,
		"assignment_group_select": true,
		"assignment_analysis": true,
		"self_study_quiz": true,
		"usage_reports": true,
		"shuffle_questions": true,
		"student_performance": true,
		"react_messages": true,
		"attach_files_on_message": false,
		"new_page_assessment": true
	}
};
'use strict';

import _isEmpty from "lodash/isEmpty";
import _isUndefined from "lodash/isUndefined";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import cookie from 'cookie_js';
import * as Sentry from '@sentry/browser';
import SessionStorage from 'utils/storage/SessionStorage';
import config from 'config';
import API from 'utils/API';
import I18n from 'utils/I18n';
import Browser from 'utils/Browser';
import SentryHelper from 'utils/SentryHelper'; // import UrlHelper from 'utils/UrlHelper';

import { AUTHENTICATE_USER_REQUEST, AUTHENTICATE_USER_SUCCESS, AUTHENTICATE_USER_FAILURE, FACEBOOK_CONNECT_REQUEST, UPDATE_AUTH_CURRENT_USER } from 'constants/ActionTypes';
import { clearAssignmentTopics } from 'actions/AssignmentTopics';
export var authenticateUserRequest = function authenticateUserRequest() {
  return {
    type: AUTHENTICATE_USER_REQUEST
  };
};
export var authenticateUserSuccess = function authenticateUserSuccess(data) {
  SentryHelper.setUser(data);
  return {
    type: AUTHENTICATE_USER_SUCCESS,
    currentUser: data
  };
};
export var authenticateUserFailure = function authenticateUserFailure(errorMessage) {
  return {
    type: AUTHENTICATE_USER_FAILURE,
    errorMessage: errorMessage
  };
};
export var redirectToLogin = function redirectToLogin() {
  // browserHistory.push(`/${I18n.currentLocale()}/login`);
  Browser.setWindowHref("/".concat(I18n.currentLocale(), "/login"));
};
export var facebookConnectRequest = function facebookConnectRequest(fbToken) {
  return {
    type: FACEBOOK_CONNECT_REQUEST,
    fbToken: fbToken
  };
};
export var removeRedirectCookie = function removeRedirectCookie() {
  cookie.removeSpecific('x_quipper_old_link', {
    path: '/',
    domain: Browser.getRootDomain()
  });
};
export var removeToken = function removeToken() {
  cookie.removeSpecific(config.auth_cookie_name, {
    path: '/',
    domain: Browser.getRootDomain()
  });
};
export var _updateAuthCurrentUser = function _updateAuthCurrentUser(currentUser) {
  return {
    type: UPDATE_AUTH_CURRENT_USER,
    currentUser: currentUser
  };
};
export var clearCurrentUser = function clearCurrentUser() {
  return function (dispatch) {
    var token = cookie.get(config.auth_cookie_name);
    return API["delete"]('/api/authorizations', {
      token: token
    })["finally"](function () {
      dispatch(clearAssignmentTopics());
      removeToken();
      removeRedirectCookie();
      SessionStorage.clear(); // This kind of redirection is needed to clear store data,
      // so redirectToLogin() wouldn't suffice as it would use react router instead.

      Browser.setWindowHref("/".concat(I18n.currentLocale(), "/login"));
    });
  };
};
export var loginUser = function loginUser(data) {
  var rememberMe = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return function (dispatch) {
    dispatch(authenticateUserSuccess(data));
    var option = rememberMe ? {
      expires: 180
    } : {};
    cookie.set(config.auth_cookie_name, data.token, _objectSpread(_objectSpread({}, option), {}, {
      path: '/',
      domain: Browser.getRootDomain(),
      // Allow access on insecure connection for development
      secure: process.env.K8S_CLUSTER !== 'minikube' && // Allow access on insecure connection for minikube
      process.env.APP_CONFIG.site_env !== 'development'
    }));
  };
};

var getAccessToken = function getAccessToken() {
  return cookie.get(config.auth_cookie_name);
};

export var isLoggedOut = function isLoggedOut() {
  return _isEmpty(getAccessToken());
};
export var authenticateByToken = function authenticateByToken(organizationId) {
  return function (dispatch) {
    var accessToken = getAccessToken(); // accessToken is undefined when user first loads the page.
    // During logout, removeToken() sets the accessToken value to '' so
    // we need to check whether accessToken is empty string and redirect them to login
    // isEmpty is able to return true when accessToken is undefined or ''

    if (_isEmpty(accessToken)) {
      return redirectToLogin();
    }

    dispatch(authenticateUserRequest());
    return API.post('/api/authorizations', {
      token: accessToken,
      organization_id: organizationId,
      no_organizations: true
    }).then(function (response) {
      // Set currentUser last as this signals completion of authentication
      dispatch(authenticateUserSuccess(response.data));
    }, function () {
      dispatch(authenticateUserFailure());
      redirectToLogin();
    })["catch"](function (err) {
      console.error(err); // eslint-disable-line no-console

      if (SentryHelper.isSentrySupport()) {
        Sentry.captureException(err); // TODO find better way
      } // TODO show error page?

    });
  };
};
export var authenticateByCredentials = function authenticateByCredentials(_ref) {
  var email_or_username = _ref.email_or_username,
      password = _ref.password,
      rememberMe = _ref.rememberMe;
  return function (dispatch) {
    dispatch(authenticateUserRequest());
    return API.post('/api/authorizations', {
      email_or_username: email_or_username,
      password: password,
      no_organizations: true
    }).then(function (response) {
      dispatch(loginUser(response.data, rememberMe));
    }, function (_ref2) {
      var response = _ref2.response;
      var data = !_isEmpty(response) ? response.data : {};
      var errorMessage = _isUndefined(data.errors) ? null : data.errors.alert;
      dispatch(authenticateUserFailure(errorMessage));
    })["catch"](function (err) {
      console.error(err); // eslint-disable-line no-console

      if (SentryHelper.isSentrySupport()) {
        Sentry.captureException(err); // TODO find better way
      } // TODO handle 500 for login

    });
  };
};
export var authenticateByFacebook = function authenticateByFacebook(token, rememberMe) {
  return function (dispatch) {
    dispatch(authenticateUserRequest());
    return API.get('/api/authorizations/facebook', {
      token: token
    }).then(function (response) {
      dispatch(loginUser(response.data, rememberMe));
    }, function () {
      dispatch(facebookConnectRequest(token));
    });
  };
};
export var connectFacebookAccount = function connectFacebookAccount(fbToken, email, password, rememberMe) {
  return function (dispatch) {
    dispatch(authenticateUserRequest());
    return API.post('/api/authorizations/facebook', {
      facebook_token: fbToken,
      email: email,
      password: password
    }).then(function (response) {
      dispatch(loginUser(response.data, rememberMe));
    }, function (response) {
      var data = response.data || {};
      var errorMessage = _isUndefined(data.errors) ? null : data.errors.alert;
      dispatch(authenticateUserFailure(errorMessage));
    });
  };
};
export var updateAuthCurrentUser = function updateAuthCurrentUser() {
  return function (dispatch, getState) {
    return dispatch(_updateAuthCurrentUser(getState().accountSettings.currentUser));
  };
};
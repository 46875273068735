'use strict';

import { SET_ROUTING_STATUS, SET_SITE_ERROR } from 'constants/ActionTypes';
export function setRoutingStatus(routingStatus) {
  return {
    type: SET_ROUTING_STATUS,
    routingStatus: routingStatus
  };
}
export function setSiteError(error) {
  return {
    type: SET_SITE_ERROR,
    error: error
  };
}
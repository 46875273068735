'use strict';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { UPDATE_MODAL, SHOW_MODAL, HIDE_MODAL } from 'constants/ActionTypes';
var initialState = {
  modalType: null,
  modalProps: {}
};
export default function modal() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type,
      modalType = action.modalType,
      modalProps = action.modalProps;

  switch (type) {
    case SHOW_MODAL:
      return {
        modalProps: _objectSpread(_objectSpread({}, modalProps), {}, {
          showModal: true
        }),
        modalType: modalType
      };

    case HIDE_MODAL:
      return _objectSpread(_objectSpread({}, state), {}, {
        modalProps: _objectSpread(_objectSpread({}, state.modalProps), {}, {
          context: null,
          showModal: false
        })
      });

    case UPDATE_MODAL:
      return _objectSpread(_objectSpread({}, state), {}, {
        modalProps: _objectSpread(_objectSpread({}, state.modalProps), modalProps)
      });

    default:
      return state;
  }
}
'use strict';

import _map from "lodash/map";
import _isEmpty from "lodash/isEmpty";

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { normalize } from 'normalizr';
import API from 'utils/API';
import { INVALIDATE_STUDENTS, FETCH_STUDENTS_FAILURE, FETCH_STUDENTS_REQUEST, FETCH_STUDENTS_SUCCESS, UPDATE_ENTITY_REQUEST, UPDATE_ENTITY_SUCCESS, UPDATE_ENTITY_FAILURE, SELECT_STUDENT, UPDATE_MODAL, HIDE_MODAL, FETCH_PENDING_REQUEST, FETCH_PENDING_FAILURE, FETCH_PENDING_SUCCESS } from 'constants/ActionTypes';
import { studentArraySchema } from 'schemas/students';
import { requestStudentDetails, receiveStudentDetails } from 'actions/Student';
import AuthHelper from 'utils/AuthHelper';
export function invalidateStudents() {
  return {
    type: INVALIDATE_STUDENTS
  };
}
export function requestStudents() {
  return {
    type: FETCH_STUDENTS_REQUEST
  };
}
export function fetchStudentsFailure(error) {
  return {
    type: FETCH_STUDENTS_FAILURE,
    error: error
  };
}
export function leaveClassRequest() {
  return {
    type: UPDATE_MODAL,
    modalProps: {
      isSubmitting: true
    }
  };
}
export function leaveClassSuccess() {
  return {
    type: UPDATE_MODAL,
    modalProps: {
      isSubmitting: false
    }
  };
}
export function leaveClassFailure(errorResponse) {
  var _errorResponse$respon = errorResponse.response,
      error = _errorResponse$respon.data,
      status = _errorResponse$respon.status;
  return {
    type: UPDATE_MODAL,
    modalProps: {
      status: status,
      errors: error,
      isSubmitting: false
    }
  };
}
export function receiveStudents(response) {
  var _response$data = response.data,
      data = _response$data.data,
      pagination = _response$data.pagination;
  var transformData = data.map(function (item) {
    return _objectSpread(_objectSpread({}, item), {}, {
      student_group_names: item.student_groups.map(function (group) {
        return group.name;
      })
    });
  });
  var studentsNormalized = normalize({
    students: transformData
  }, {
    students: studentArraySchema
  });
  var paginationInfo = {
    total_count: parseInt(pagination.total, 10),
    current_page: parseInt(pagination.page, 10),
    per_page: parseInt(pagination.per_page, 10)
  };
  return {
    type: FETCH_STUDENTS_SUCCESS,
    response: _objectSpread(_objectSpread({}, studentsNormalized), paginationInfo)
  };
}
export function selectStudent(student) {
  return {
    type: SELECT_STUDENT,
    student: student
  };
}
export function updatePasswordOk(data) {
  return {
    type: UPDATE_MODAL,
    modalProps: {
      status: data.status,
      errors: null
    }
  };
}
export function updatePasswordFailure(error) {
  return {
    type: UPDATE_MODAL,
    modalProps: {
      status: error.status,
      errors: null
    }
  };
}
export function updateClassStudentsOk(data) {
  return {
    type: UPDATE_MODAL,
    modalProps: {
      status: data.status,
      errors: null
    }
  };
}
export function updateClassStudentsFailure(error) {
  return {
    type: UPDATE_MODAL,
    modalProps: {
      status: error.response.status,
      errors: null
    }
  };
}
export function passwordValidationOk() {
  return {
    type: UPDATE_MODAL,
    modalProps: {
      errors: null
    }
  };
}
export function nameValidationOk() {
  return {
    type: UPDATE_MODAL,
    modalProps: {
      errors: null
    }
  };
}
export function updateStudentOk() {
  return {
    type: HIDE_MODAL
  };
}
export function updateStudentFailure(error, studentId) {
  return {
    type: UPDATE_ENTITY_FAILURE,
    entityName: 'students',
    entityId: studentId
  };
}
export function passwordValidationFailure(errors) {
  return {
    type: UPDATE_MODAL,
    modalProps: {
      errors: {
        user: {
          password: errors
        }
      }
    }
  };
}
export function validationFailure(errors) {
  return {
    type: UPDATE_MODAL,
    modalProps: {
      errors: errors.data,
      status: errors.status
    }
  };
}
export function updateCanSubmitStatus(value) {
  return {
    type: UPDATE_MODAL,
    modalProps: {
      canSubmit: value
    }
  };
}
export function updateStudentFetchStatus(value) {
  return {
    type: UPDATE_MODAL,
    modalProps: {
      isFetching: value
    }
  };
}
export function fetchStudent(student) {
  return function (dispatch) {
    dispatch(selectStudent(student));
  };
}
export function requestLeaveClassRequest() {
  return {
    type: FETCH_PENDING_REQUEST
  };
}
export function receivePendingCount(count) {
  return {
    type: FETCH_PENDING_SUCCESS,
    count: count
  };
}
export function fetchPendingCountFailure(error) {
  return {
    type: FETCH_PENDING_FAILURE,
    error: error
  };
}
export function fetchStudents() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return function (dispatch, getState) {
    var token = getState().auth.currentUser.token;
    var organizationId = getState().organizations.current;
    var opts = {
      headers: {
        Authorization: "Bearer ".concat(token)
      }
    };
    dispatch(requestStudents());
    return API.get("/v1/organizations/".concat(organizationId, "/students"), _objectSpread(_objectSpread({}, params), {}, {
      student_group_ids: params.student_group_ids ? params.student_group_ids.join(',') : '',
      per_page: 50
    }), opts).then(function (response) {
      return dispatch(receiveStudents(response));
    }, function (error) {
      return dispatch(fetchStudentsFailure(error));
    });
  };
}
export function fetchPendingRequestCount() {
  return function (dispatch, getState) {
    var token = getState().auth.currentUser.token;
    var organizationId = getState().organizations.current;
    var opts = {
      headers: {
        Authorization: "Bearer ".concat(token)
      }
    };
    dispatch(requestLeaveClassRequest());
    return API.get("/v1/organizations/".concat(organizationId, "/students/pending_request"), {}, opts).then(function (response) {
      return dispatch(receivePendingCount(response.data.data.count));
    }, function (error) {
      return dispatch(fetchPendingCountFailure(error));
    });
  };
}
export function updateStudentPassword(studentId, password) {
  return function (dispatch, getState) {
    var organizationId = getState().organizations.current;
    return API.post("/api/organizations/".concat(organizationId, "/students/").concat(studentId, "/set_password"), {
      password: password
    }).then(function (response) {
      return dispatch(updatePasswordOk(response));
    }, function (error) {
      return dispatch(updatePasswordFailure(error));
    });
  };
}
export function updateStudentName(studentId, firstName, lastName) {
  return function (dispatch, getState) {
    var organizationId = getState().organizations.current;
    dispatch(requestStudentDetails(UPDATE_ENTITY_REQUEST, studentId));
    dispatch(updateStudentFetchStatus(true));
    return API.post("/api/organizations/".concat(organizationId, "/students/").concat(studentId, "/update_name"), {
      first_name: firstName,
      last_name: lastName
    }).then(function (response) {
      dispatch(receiveStudentDetails(UPDATE_ENTITY_SUCCESS, response.data));
      dispatch(updateStudentOk());
      dispatch(updateStudentFetchStatus(false));
    }, function (error) {
      dispatch(updateStudentFailure(error, studentId));
      dispatch(validationFailure(error));
      dispatch(updateStudentFetchStatus(false));
    });
  };
}
export function updateStudentsListOnLeaveClassApproval(classIds, entity) {
  var currentStudentGroups = entity.students[entity.studentId];

  if (entity.type === 'accept') {
    currentStudentGroups = currentStudentGroups.student_groups.filter(function (group) {
      return !classIds.includes(group.id);
    }).map(function (group) {
      if (classIds.includes(group.id)) {
        return _objectSpread(_objectSpread({}, group), {}, {
          requested_removal: false
        });
      }

      return group;
    });
  }

  if (entity.type === 'decline') {
    currentStudentGroups = currentStudentGroups.student_groups.map(function (group) {
      if (classIds.includes(group.id)) {
        return _objectSpread(_objectSpread({}, group), {}, {
          requested_removal: false
        });
      }

      return group;
    });
  }

  return {
    type: UPDATE_ENTITY_SUCCESS,
    entityName: 'students',
    entityId: entity.studentId,
    entity: _objectSpread(_objectSpread({}, entity.students[entity.studentId]), {}, {
      student_groups: currentStudentGroups,
      student_group_names: currentStudentGroups.map(function (group) {
        return group.name;
      })
    })
  };
}
export function updateClassListOnLeaveClassApproval(classId, entity) {
  var currentStudentsInClasses = entity.classes[classId].students.filter(function (student) {
    return student.id !== entity.studentId;
  });
  return {
    type: UPDATE_ENTITY_SUCCESS,
    entityName: 'classes',
    entityId: classId,
    entity: _objectSpread(_objectSpread({}, entity.classes[classId]), {}, {
      students: currentStudentsInClasses
    })
  };
}
export function leaveClassAction(param) {
  return function (dispatch, getState) {
    var token = getState().auth.currentUser.token;
    var organizationId = getState().organizations.current;
    var _getState$entities = getState().entities,
        students = _getState$entities.students,
        classes = _getState$entities.classes;
    var count = getState().students.count;

    var addToast = param.addToast,
        toggle = param.toggle,
        restParams = _objectWithoutProperties(param, ["addToast", "toggle"]);

    var opts = {
      headers: {
        Authorization: "Bearer ".concat(token)
      }
    };
    dispatch(leaveClassRequest());
    return API.post("/v1/organizations/".concat(organizationId, "/classes/leave_class_action"), restParams, opts).then(function (response) {
      var _students$restParams$;

      var _response$data$data = response.data.data,
          accept_leave_class = _response$data$data.accept_leave_class,
          decline_leave_class = _response$data$data.decline_leave_class;
      dispatch(leaveClassSuccess(response));
      addToast();
      toggle();
      var totalProcessedRequest = accept_leave_class.length + decline_leave_class.length;
      var totalStudentRequest = (_students$restParams$ = students[restParams.student_id].student_groups) === null || _students$restParams$ === void 0 ? void 0 : _students$restParams$.filter(function (group) {
        return group.requested_removal === true;
      }).length;

      if (accept_leave_class.length > 0) {
        dispatch(updateStudentsListOnLeaveClassApproval(accept_leave_class, {
          students: students,
          classes: classes,
          studentId: param.student_id,
          type: 'accept'
        }));
        accept_leave_class.forEach(function (classId) {
          dispatch(updateClassListOnLeaveClassApproval(classId, {
            students: students,
            classes: classes,
            studentId: param.student_id,
            type: 'accept'
          }));
        });
      }

      if (decline_leave_class.length > 0) {
        var _getState$entities2 = getState().entities,
            updatedStudents = _getState$entities2.students,
            updatedClasses = _getState$entities2.classes;
        dispatch(updateStudentsListOnLeaveClassApproval(decline_leave_class, {
          students: updatedStudents,
          classes: updatedClasses,
          studentId: param.student_id,
          type: 'decline'
        }));
        decline_leave_class.forEach(function (classId) {
          dispatch(updateClassListOnLeaveClassApproval(classId, {
            students: updatedStudents,
            classes: updatedClasses,
            studentId: param.student_id,
            type: 'decline'
          }));
        });
      }

      if (totalProcessedRequest === totalStudentRequest) {
        dispatch(receivePendingCount(count - 1));
      }
    }, function (error) {
      dispatch(leaveClassFailure(error));
    });
  };
}
export function updateAllianceUserCode(studentId, allianceUserCode) {
  return function (dispatch, getState) {
    var organizationId = getState().organizations.current;
    dispatch(requestStudentDetails(UPDATE_ENTITY_REQUEST, studentId));
    return API.post("/api/organizations/".concat(organizationId, "/students/").concat(studentId, "/update_alliance_user_code"), {
      alliance_user_code: allianceUserCode
    }).then(function (response) {
      dispatch(receiveStudentDetails(UPDATE_ENTITY_SUCCESS, response.data));
      dispatch(updateStudentOk());
    }, function (error) {
      dispatch(updateStudentFailure(error, studentId));
      dispatch(validationFailure(error));
    });
  };
}
export function validatePassword(password, username) {
  return function (dispatch) {
    var errors = AuthHelper.passwordValidationErrorMessages(password, username);

    if (!_isEmpty(errors)) {
      return dispatch(passwordValidationFailure(errors));
    }

    return dispatch(passwordValidationOk());
  };
}
export function validateNameExistence(firstName, lastName, orgCountryCode) {
  return function (dispatch) {
    var canSubmit;

    if (orgCountryCode === 'ID') {
      canSubmit = !_isEmpty(firstName);
    } else {
      var containsSymbol = !firstName.match(/^[a-zA-Z \u00c0-\u00ff'\-々ぁ-ゔゞァ-・ヽヾ゛゜ー\u4e00-\u9faf]+$/) || !lastName.match(/^[a-zA-Z \u00c0-\u00ff'\-々ぁ-ゔゞァ-・ヽヾ゛゜ー\u4e00-\u9faf]+$/);
      canSubmit = !_isEmpty(firstName) && !_isEmpty(lastName) && !containsSymbol;
    }

    return dispatch(updateCanSubmitStatus(canSubmit));
  };
}
export function updateStudentPasswordIfNeeded(studentId, password, username) {
  return function (dispatch) {
    return dispatch(updateStudentPassword(studentId, password, username));
  };
}
export var updateStudentsClass = function updateStudentsClass(classId, studentIds, method) {
  return function (dispatch, getState) {
    var organizationId = getState().organizations.current;
    studentIds.map(function (studentId) {
      return dispatch(requestStudentDetails(UPDATE_ENTITY_REQUEST, studentId));
    });
    var token = getState().auth.currentUser.token;
    var opts = {
      headers: {
        Authorization: "Bearer ".concat(token)
      }
    };
    var addOrRemoveStudents = method === 'add_class' ? 'add_students' : 'remove_students';
    return API.put("/v1/organizations/".concat(organizationId, "/classes/").concat(classId, "/").concat(addOrRemoveStudents), {
      student_ids: studentIds
    }, opts).then(function (response) {
      dispatch(updateClassStudentsOk(response));

      _map(response.data.data.success, function (student) {
        return dispatch(receiveStudentDetails(UPDATE_ENTITY_SUCCESS, student));
      });
    }, function (error) {
      dispatch(updateClassStudentsFailure(error));
      dispatch(fetchStudentsFailure(error));
    });
  };
};

function shouldFetchStudents(_ref) {
  var students = _ref.students;

  if (_isEmpty(students)) {
    return true;
  }

  if (students.isFetching) {
    return false;
  }

  return students.didInvalidate;
}

export function fetchStudentsIfNeeded() {
  return function (dispatch, getState) {
    if (shouldFetchStudents(getState())) {
      return dispatch(fetchStudents());
    }

    return false;
  };
}
'use strict';

export var SHOW_MODAL = 'SHOW_MODAL';
export var HIDE_MODAL = 'HIDE_MODAL';
export var UPDATE_MODAL = 'UPDATE_MODAL';
export var FACEBOOK_LOADING = 'FACEBOOK_LOADING';
export var FACEBOOK_LOAD_SUCCESS = 'FACEBOOK_LOAD_SUCCESS';
export var FACEBOOK_LOAD_FAILURE = 'FACEBOOK_LOAD_FAILURE';
export var FETCH_ENTITY_REQUEST = 'FETCH_ENTITY_REQUEST';
export var FETCH_ENTITY_SUCCESS = 'FETCH_ENTITY_SUCCESS';
export var FETCH_ENTITY_FAILURE = 'FETCH_ENTITY_FAILURE';
export var CREATE_ENTITY_REQUEST = 'CREATE_ENTITY_REQUEST';
export var CREATE_ENTITY_SUCCESS = 'CREATE_ENTITY_SUCCESS';
export var CREATE_ENTITY_FAILURE = 'CREATE_ENTITY_FAILURE';
export var UPDATE_ENTITY_REQUEST = 'UPDATE_ENTITY_REQUEST';
export var UPDATE_ENTITY_SUCCESS = 'UPDATE_ENTITY_SUCCESS';
export var UPDATE_ENTITY_FAILURE = 'UPDATE_ENTITY_FAILURE';
export var DELETE_ENTITY_REQUEST = 'DELETE_ENTITY_REQUEST';
export var DELETE_ENTITY_SUCCESS = 'DELETE_ENTITY_SUCCESS';
export var DELETE_ENTITY_FAILURE = 'DELETE_ENTITY_FAILURE';
export var CLEAR_ENTITY = 'CLEAR_ENTITY';
export var REQUEST_NEWS = 'REQUEST_NEWS';
export var RECEIVE_NEWS = 'RECEIVE_NEWS';
export var REQUEST_NEWS_SUMMARY = 'REQUEST_NEWS_SUMMARY';
export var RECEIVE_NEWS_SUMMARY = 'RECEIVE_NEWS_SUMMARY';
export var REQUEST_NEWS_ITEM = 'REQUEST_NEWS_ITEM';
export var RECEIVE_NEWS_ITEM = 'RECEIVE_NEWS_ITEM';
export var FETCH_NEWS_FAILURE = 'FETCH_NEWS_FAILURE';
export var FETCH_NEWS_SUMMARY_FAILURE = 'FETCH_NEWS_SUMMARY_FAILURE';
export var FETCH_NEWS_ITEM_FAILURE = 'FETCH_NEWS_ITEM_FAILURE';
export var FETCH_CLASSES_REQUEST = 'FETCH_CLASSES_REQUEST';
export var FETCH_CLASSES_SUCCESS = 'FETCH_CLASSES_SUCCESS';
export var FETCH_CLASSES_FAILURE = 'FETCH_CLASSES_FAILURE';
export var FETCH_LITE_CLASSES_REQUEST = 'FETCH_LITE_CLASSES_REQUEST';
export var FETCH_LITE_CLASSES_SUCCESS = 'FETCH_LITE_CLASSES_SUCCESS';
export var FETCH_LITE_CLASSES_FAILURE = 'FETCH_LITE_CLASSES_FAILURE';
export var CREATE_CLASSES_REQUEST = 'CREATE_CLASSES_REQUEST';
export var CREATE_CLASSES_SUCCESS = 'CREATE_CLASSES_SUCCESS';
export var CREATE_CLASSES_FAILURE = 'CREATE_CLASSES_FAILURE';
export var DELETE_CLASSES_PROCESSING = 'DELETE_CLASSES_PROCESSING';
export var DELETE_CLASSES_SUCCESS = 'DELETE_CLASSES_SUCCESS';
export var REMOVE_TEACHER_FROM_CLASS_REQUEST = 'REMOVE_TEACHER_FROM_CLASS_REQUEST';
export var REMOVE_TEACHER_FROM_CLASS_SUCCESS = 'REMOVE_TEACHER_FROM_CLASS_SUCCESS';
export var REMOVE_TEACHER_FROM_CLASS_FAILURE = 'REMOVE_TEACHER_FROM_CLASS_FAILURE';
export var ADD_TEACHER_TO_CLASS_REQUEST = 'ADD_TEACHER_TO_CLASS_REQUEST';
export var ADD_TEACHER_TO_CLASS_SUCCESS = 'ADD_TEACHER_TO_CLASS_SUCCESS';
export var ADD_TEACHER_TO_CLASS_FAILURE = 'ADD_TEACHER_TO_CLASS_FAILURE';
export var SHOW_JOIN_CLASSES_REQUEST = 'SHOW_JOIN_CLASSES_REQUEST';
export var SHOW_JOIN_CLASSES_SUCCESS = 'SHOW_JOIN_CLASSES_SUCCESS';
export var FETCH_COURSE_SETS_REQUEST = 'FETCH_COURSE_SETS_REQUEST';
export var FETCH_COURSE_SETS_SUCCESS = 'FETCH_COURSE_SETS_SUCCESS';
export var FETCH_COURSE_SETS_FAILURE = 'FETCH_COURSE_SETS_FAILURE';
export var FETCH_ASSESSMENT_COURSES = 'FETCH_ASSESSMENT_COURSES';
export var FETCH_ASSESSMENT_COURSES_REQUEST = 'FETCH_ASSESSMENT_COURSES_REQUEST';
export var FETCH_ASSESSMENT_COURSES_SUCCESS = 'FETCH_ASSESSMENT_COURSES_SUCCESS';
export var FETCH_ASSESSMENT_COURSES_FAILURE = 'FETCH_ASSESSMENT_COURSES_FAILURE';
export var FETCH_SCHOOL_ASSESSMENT_COURSES = 'FETCH_SCHOOL_ASSESSMENT_COURSES';
export var FETCH_SCHOOL_ASSESSMENT_COURSES_REQUEST = 'FETCH_SCHOOL_ASSESSMENT_COURSES_REQUEST';
export var FETCH_SCHOOL_ASSESSMENT_COURSES_SUCCESS = 'FETCH_SCHOOL_ASSESSMENT_COURSES_SUCCESS';
export var FETCH_SCHOOL_ASSESSMENT_COURSES_FAILURE = 'FETCH_SCHOOL_ASSESSMENT_COURSES_FAILURE';
export var FETCH_ACTIVITIES_REQUEST = 'FETCH_ACTIVITIES_REQUEST';
export var FETCH_ACTIVITIES_SUCCESS = 'FETCH_ACTIVITIES_SUCCESS';
export var FETCH_ACTIVITIES_FAILURE = 'FETCH_ACTIVITIES_FAILURE';
export var FETCH_ASSIGNMENTS_REQUEST = 'FETCH_ASSIGNMENTS_REQUEST';
export var FETCH_ASSIGNMENTS_SUCCESS = 'FETCH_ASSIGNMENTS_SUCCESS';
export var FETCH_ASSIGNMENTS_FAILURE = 'FETCH_ASSIGNMENTS_FAILURE';
export var FETCH_CLASS_ASSIGNMENTS_REQUEST = 'FETCH_CLASS_ASSIGNMENTS_REQUEST';
export var FETCH_CLASS_ASSIGNMENTS_SUCCESS = 'FETCH_CLASS_ASSIGNMENTS_SUCCESS';
export var FETCH_CLASS_ASSIGNMENTS_FAILURE = 'FETCH_CLASS_ASSIGNMENTS_FAILURE';
export var CREATE_ASSIGNMENTS_DELAYED = 'CREATE_ASSIGNMENTS_DELAYED';
export var CREATE_ASSIGNMENTS_REQUEST = 'CREATE_ASSIGNMENTS_REQUEST';
export var CREATE_ASSIGNMENTS_SUCCESS = 'CREATE_ASSIGNMENTS_SUCCESS';
export var CREATE_ASSIGNMENTS_FAILURE = 'CREATE_ASSIGNMENTS_FAILURE';
export var CREATE_ASSIGNMENTS_RESET = 'CREATE_ASSIGNMENTS_RESET';
export var UPDATE_ASSIGNMENTS_REQUEST = 'UPDATE_ASSIGNMENTS_REQUEST';
export var UPDATE_ASSIGNMENTS_SUCCESS = 'UPDATE_ASSIGNMENTS_SUCCESS';
export var UPDATE_ASSIGNMENTS_FAILURE = 'UPDATE_ASSIGNMENTS_FAILURE';
export var DELETE_ASSIGNMENTS_REQUEST = 'DELETE_ASSIGNMENTS_REQUEST';
export var DELETE_ASSIGNMENTS_SUCCESS = 'DELETE_ASSIGNMENTS_SUCCESS';
export var DELETE_ASSIGNMENTS_FAILURE = 'DELETE_ASSIGNMENTS_FAILURE';
export var CREATE_ACHIEVEMENT_ASSIGNMENTS_REQUEST = 'CREATE_ACHIEVEMENT_ASSIGNMENTS_REQUEST';
export var CREATE_ACHIEVEMENT_ASSIGNMENTS_SUCCESS = 'CREATE_ACHIEVEMENT_ASSIGNMENTS_SUCCESS';
export var CREATE_ACHIEVEMENT_ASSIGNMENTS_FAILURE = 'CREATE_ACHIEVEMENT_ASSIGNMENTS_FAILURE';
export var FETCH_ACHIEVEMENTS_REQUEST = 'FETCH_ACHIEVEMENTS_REQUEST';
export var FETCH_ACHIEVEMENTS_SUCCESS = 'FETCH_ACHIEVEMENTS_SUCCESS';
export var FETCH_ACHIEVEMENTS_FAILURE = 'FETCH_ACHIEVEMENTS_FAILURE';
export var FETCH_STUDENT_ASSIGNMENTS_REQUEST = 'FETCH_STUDENT_ASSIGNMENTS_REQUEST';
export var FETCH_STUDENT_ASSIGNMENTS_SUCCESS = 'FETCH_STUDENT_ASSIGNMENTS_SUCCESS';
export var FETCH_STUDENT_ASSIGNMENTS_FAILURE = 'FETCH_STUDENT_ASSIGNMENTS_FAILURE';
export var FETCH_TOPICS_REQUEST = 'FETCH_TOPICS_REQUEST';
export var FETCH_TOPICS_SUCCESS = 'FETCH_TOPICS_SUCCESS';
export var FETCH_TOPICS_FAILURE = 'FETCH_TOPICS_FAILURE';
export var FETCH_ASSIGNMENT_STUDENTS_REQUEST = 'FETCH_ASSIGNMENT_STUDENTS_REQUEST';
export var FETCH_ASSIGNMENT_STUDENTS_SUCCESS = 'FETCH_ASSIGNMENT_STUDENTS_SUCCESS';
export var FETCH_ASSIGNMENT_STUDENTS_FAILURE = 'FETCH_ASSIGNMENT_STUDENTS_FAILURE';
export var SET_CURRENT_ASSIGNMENT = 'SET_CURRENT_ASSIGNMENT';
export var SET_CURRENT_TOPIC = 'SET_CURRENT_TOPIC';
export var TOGGLE_ASSIGNMENT_TOPICS = 'TOGGLE_ASSIGNMENT_TOPICS';
export var TOGGLE_MULTIPLE_ASSIGNMENT_TOPICS = 'TOGGLE_MULTIPLE_ASSIGNMENT_TOPICS';
export var CLEAR_ASSIGNMENT_TOPICS = 'CLEAR_ASSIGNMENT_TOPICS';
export var TOGGLE_ASSIGNMENT_FORM_STEP = 'TOGGLE_ASSIGNMENT_FORM_STEP';
export var FETCH_ASSIGNMENT_FORM_CLASSES_REQUEST = 'FETCH_ASSIGNMENT_FORM_CLASSES_REQUEST';
export var FETCH_ASSIGNMENT_FORM_CLASSES_SUCCESS = 'FETCH_ASSIGNMENT_FORM_CLASSES_SUCCESS';
export var FETCH_ASSIGNMENT_FORM_CLASSES_FAILURE = 'FETCH_ASSIGNMENT_FORM_CLASSES_FAILURE';
export var TOGGLE_UPDATE_TIME_PICKER = 'TOGGLE_UPDATE_TIME_PICKER';
export var REQUEST_STATISTICS = 'REQUEST_STATISTICS';
export var RECEIVE_STATISTICS = 'RECEIVE_STATISTICS';
export var INVALIDATE_STATISTICS = 'INVALIDATE_STATISTICS';
export var CHANGE_SCOPE_FILTER = 'CHANGE_SCOPE_FILTER';
export var FETCH_STATISTICS_REQUEST = 'FETCH_STATISTICS_REQUEST';
export var FETCH_STATISTICS_SUCCESS = 'FETCH_STATISTICS_SUCCESS';
export var FETCH_STATISTICS_FAILURE = 'FETCH_STATISTICS_FAILURE';
export var INVALIDATE_TEACHERS = 'INVALIDATE_TEACHERS';
export var FETCH_TEACHERS_REQUEST = 'FETCH_TEACHERS_REQUEST';
export var FETCH_TEACHERS_SUCCESS = 'FETCH_TEACHERS_SUCCESS';
export var FETCH_TEACHERS_FAILURE = 'FETCH_TEACHERS_FAILURE';
export var DELETE_TEACHER_REQUEST = 'DELETE_TEACHER_REQUEST';
export var DELETE_TEACHER_SUCCESS = 'DELETE_TEACHER_SUCCESS';
export var DELETE_TEACHER_FAILURE = 'DELETE_TEACHER_FAILURE';
export var UPDATE_TEACHER_REQUEST = 'UPDATE_TEACHER_REQUEST';
export var UPDATE_TEACHER_SUCCESS = 'UPDATE_TEACHER_SUCCESS';
export var UPDATE_TEACHER_FAILURE = 'UPDATE_TEACHER_FAILURE';
export var CREATE_TEACHER_REQUEST = 'CREATE_TEACHER_REQUEST';
export var CREATE_TEACHER_SUCCESS = 'CREATE_TEACHER_SUCCESS';
export var CREATE_TEACHER_FAILURE = 'CREATE_TEACHER_FAILURE';
export var CREATE_TEACHER_INIT = 'CREATE_TEACHER_INIT';
export var INVALIDATE_STUDENTS = 'INVALIDATE_STUDENTS';
export var RESET_PASSWORD_TEACHERS_REQUEST = 'RESET_PASSWORD_TEACHERS_REQUEST';
export var RESET_PASSWORD_TEACHERS_SUCCESS = 'RESET_PASSWORD_TEACHERS_SUCCESS';
export var RESET_PASSWORD_TEACHERS_FAILURE = 'RESET_PASSWORD_TEACHERS_FAILURE';
export var FETCH_STUDENTS_REQUEST = 'FETCH_STUDENTS_REQUEST';
export var FETCH_STUDENTS_SUCCESS = 'FETCH_STUDENTS_SUCCESS';
export var FETCH_STUDENTS_FAILURE = 'FETCH_STUDENTS_FAILURE';
export var SELECT_STUDENT = 'SELECT_STUDENT';
export var FETCH_PENDING_REQUEST = 'FETCH_PENDING_REQUEST';
export var FETCH_PENDING_SUCCESS = 'FETCH_PENDING_SUCCESS';
export var FETCH_PENDING_FAILURE = 'FETCH_PENDING_FAILURE';
export var INVALIDATE_STUDENTS_MEMBERSHIP = 'INVALIDATE_STUDENTS_MEMBERSHIP';
export var CLEAR_STUDENTS_MEMBERSHIP = 'CLEAR_STUDENTS_MEMBERSHIP';
export var FETCH_STUDENTS_MEMBERSHIP_REQUEST = 'FETCH_STUDENTS_MEMBERSHIP_REQUEST';
export var FETCH_STUDENTS_MEMBERSHIP_SUCCESS = 'FETCH_STUDENTS_MEMBERSHIP_SUCCESS';
export var FETCH_STUDENTS_MEMBERSHIP_FAILURE = 'FETCH_STUDENTS_MEMBERSHIP_FAILURE';
export var UPDATE_STUDENTS_MEMBERSHIP_REQUEST = 'UPDATE_STUDENTS_MEMBERSHIP_REQUEST';
export var UPDATE_STUDENTS_MEMBERSHIP_SUCCESS = 'UPDATE_STUDENTS_MEMBERSHIP_SUCCESS';
export var UPDATE_STUDENTS_MEMBERSHIP_FAILURE = 'UPDATE_STUDENTS_MEMBERSHIP_FAILURE';
export var FETCH_ROSTERS_REQUEST = 'FETCH_ROSTERS_REQUEST';
export var FETCH_ROSTERS_SUCCESS = 'FETCH_ROSTERS_SUCCESS';
export var FETCH_ROSTERS_FAILURE = 'FETCH_ROSTERS_FAILURE';
export var FETCH_GRADES_REQUEST = 'FETCH_GRADES_REQUEST';
export var FETCH_GRADES_SUCCESS = 'FETCH_GRADES_SUCCESS';
export var FETCH_GRADES_FAILURE = 'FETCH_GRADES_FAILURE';
export var TOGGLE_LOCALE_MENU = 'TOGGLE_LOCALE_MENU';
export var AUTHENTICATE_USER_REQUEST = 'AUTHENTICATE_USER_REQUEST';
export var AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS';
export var AUTHENTICATE_USER_FAILURE = 'AUTHENTICATE_USER_FAILURE';
export var FACEBOOK_CONNECT_REQUEST = 'FACEBOOK_CONNECT_REQUEST';
export var UPDATE_AUTH_CURRENT_USER = 'UPDATE_AUTH_CURRENT_USER';
export var FETCH_INVITATION_REQUEST = 'FETCH_INVITATION_REQUEST';
export var FETCH_INVITATION_SUCCESS = 'FETCH_INVITATION_SUCCESS';
export var FETCH_INVITATION_FAILURE = 'FETCH_INVITATION_FAILURE';
export var FETCH_SIGNUP_REQUEST = 'FETCH_SIGNUP_REQUEST';
export var FETCH_SIGNUP_SUCCESS_CODE = 'FETCH_SIGNUP_SUCCESS_CODE';
export var FETCH_SIGNUP_SUCCESS_ORGANIZATION = 'FETCH_SIGNUP_SUCCESS_ORGANIZATION';
export var FETCH_SIGNUP_FAILURE = 'FETCH_SIGNUP_FAILURE';
export var COURSE_BOOKMARK_REQUEST = 'COURSE_BOOKMARK_REQUEST';
export var COURSE_BOOKMARK_SUCCESS = 'COURSE_BOOKMARK_SUCCESS';
export var COURSE_BOOKMARK_FAILURE = 'COURSE_BOOKMARK_FAILURE';
export var COURSE_UNBOOKMARK_REQUEST = 'COURSE_UNBOOKMARK_REQUEST';
export var COURSE_UNBOOKMARK_SUCCESS = 'COURSE_UNBOOKMARK_SUCCESS';
export var COURSE_UNBOOKMARK_FAILURE = 'COURSE_UNBOOKMARK_FAILURE';
export var RESEND_EMAIL_CONFIRM_REQUEST = 'RESEND_EMAIL_CONFIRM_REQUEST';
export var RESEND_EMAIL_CONFIRM_SUCCESS = 'RESEND_EMAIL_CONFIRM_SUCCESS';
export var RESEND_EMAIL_CONFIRM_FAILURE = 'RESEND_EMAIL_CONFIRM_FAILURE';
export var ACCEPT_TERMS_REQUEST = 'ACCEPT_TERMS_REQUEST';
export var ACCEPT_TERMS_SUCCESS = 'ACCEPT_TERMS_SUCCESS';
export var ACCEPT_TERMS_FAILURE = 'ACCEPT_TERMS_FAILURE';
export var CLOSE_USER_NOTIFICATION = 'CLOSE_USER_NOTIFICATION';
export var SUBMIT_EMAIL_CONFIRM_REQUEST = 'SUBMIT_EMAIL_CONFIRM_REQUEST';
export var SUBMIT_EMAIL_CONFIRM_SUCCESS = 'SUBMIT_EMAIL_CONFIRM_SUCCESS';
export var SUBMIT_EMAIL_CONFIRM_FAILURE = 'SUBMIT_EMAIL_CONFIRM_FAILURE';
export var SUBMIT_SIGNUP_REQUEST = 'SUBMIT_SIGNUP_REQUEST';
export var SUBMIT_SIGNUP_SUCCESS = 'SUBMIT_SIGNUP_SUCCESS';
export var SUBMIT_SIGNUP_FAILURE = 'SUBMIT_SIGNUP_FAILURE';
export var SET_CURRENT_ORGANIZATION = 'SET_CURRENT_ORGANIZATION';
export var FETCH_ORGANIZATIONS_REQUEST = 'FETCH_ORGANIZATIONS_REQUEST';
export var FETCH_ORGANIZATIONS_SUCCESS = 'FETCH_ORGANIZATIONS_SUCCESS';
export var FETCH_ORGANIZATIONS_FAILURE = 'FETCH_ORGANIZATIONS_FAILURE';
export var FETCH_PROVINCES_REQUEST = 'FETCH_PROVINCES_REQUEST';
export var FETCH_PROVINCES_SUCCESS = 'FETCH_PROVINCES_SUCCESS';
export var FETCH_PROVINCES_FAILURE = 'FETCH_PROVINCES_FAILURE';
export var FETCH_CITIES_REQUEST = 'FETCH_CITIES_REQUEST';
export var FETCH_CITIES_SUCCESS = 'FETCH_CITIES_SUCCESS';
export var FETCH_CITIES_FAILURE = 'FETCH_CITIES_FAILURE';
export var FETCH_SCHOOLS_REQUEST = 'FETCH_SCHOOLS_REQUEST';
export var FETCH_SCHOOLS_SUCCESS = 'FETCH_SCHOOLS_SUCCESS';
export var FETCH_SCHOOLS_FAILURE = 'FETCH_SCHOOLS_FAILURE';
export var FETCH_BILLING_DATA_FILES_REQUEST = 'FETCH_BILLING_DATA_FILES_REQUEST';
export var FETCH_BILLING_DATA_FILES_SUCCESS = 'FETCH_BILLING_DATA_FILES_SUCCESS';
export var FETCH_BILLING_DATA_FILES_FAILURE = 'FETCH_BILLING_DATA_FILES_FAILURE';
export var SET_ROUTING_STATUS = 'SET_ROUTING_STATUS';
export var SET_SITE_ERROR = 'SET_SITE_ERROR';
export var FETCH_ORGANIZATION_REQUEST = 'FETCH_ORGANIZATION_REQUEST';
export var FETCH_ORGANIZATION_SUCCESS = 'FETCH_ORGANIZATION_SUCCESS';
export var FETCH_ORGANIZATION_FAILURE = 'FETCH_ORGANIZATION_FAILURE';
export var EDIT_ORGANIZATION = 'EDIT_ORGANIZATION';
export var JOIN_ORGANIZATION_REQUEST = 'JOIN_ORGANIZATION_REQUEST';
export var JOIN_ORGANIZATION_SUCESSS = 'JOIN_ORGANIZATION_SUCESSS';
export var JOIN_ORGANIZATION_FAILURE = 'JOIN_ORGANIZATION_FAILURE';
export var SAVE_ORGANIZATION_REQUEST = 'SAVE_ORGANIZATION_REQUEST';
export var SAVE_ORGANIZATION_SUCCESS = 'SAVE_ORGANIZATION_SUCCESS';
export var SAVE_ORGANIZATION_FAILURE = 'SAVE_ORGANIZATION_FAILURE';
export var SAVE_ORGANIZATION_CANCEL = 'SAVE_ORGANIZATION_CANCEL';
export var FETCH_REPORTS_REQUEST = 'FETCH_REPORTS_REQUEST';
export var FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export var FETCH_REPORTS_FAILURE = 'FETCH_REPORTS_FAILURE';
export var DOWNLOAD_REPORT_REQUEST = 'DOWNLOAD_REPORT_REQUEST';
export var DOWNLOAD_REPORT_SUCCESS = 'DOWNLOAD_REPORT_SUCCESS';
export var DOWNLOAD_REPORT_FAILURE = 'DOWNLOAD_REPORT_FAILURE';
export var REQUEST_USAGE_REPORT = 'REQUEST_USAGE_REPORT';
export var REQUEST_USAGE_REPORT_SUCCESS = 'REQUEST_USAGE_REPORT_SUCCESS';
export var REQUEST_USAGE_REPORT_FAILURE = 'REQUEST_USAGE_REPORT_FAILURE';
export var FETCH_AVAILABLE_USAGE_REPORTS_REQUEST = 'FETCH_AVAILABLE_USAGE_REPORTS_REQUEST';
export var FETCH_AVAILABLE_USAGE_REPORTS_SUCCESS = 'FETCH_AVAILABLE_USAGE_REPORTS_SUCCESS';
export var FETCH_AVAILABLE_USAGE_REPORTS_FAILURE = 'FETCH_AVAILABLE_USAGE_REPORTS_FAILURE';
export var DOWNLOAD_CSV_REQUEST = 'DOWNLOAD_CSV_REQUEST';
export var DOWNLOAD_CSV_SUCCESS = 'DOWNLOAD_CSV_SUCCESS';
export var DOWNLOAD_CSV_FAILURE = 'DOWNLOAD_CSV_FAILURE';
export var UPLOAD_CSV_REQUEST = 'UPLOAD_CSV_REQUEST';
export var UPLOAD_CSV_SUCCESS = 'UPLOAD_CSV_SUCCESS';
export var UPLOAD_CSV_FAILURE = 'UPLOAD_CSV_FAILURE';
export var RESET_CSV_ALERT_MESSAGE = 'RESET_CSV_ALERT_MESSAGE';
export var REQUEST_PASSWORD_RECOVERY = 'REQUEST_PASSWORD_RECOVERY';
export var REQUEST_PASSWORD_RECOVERY_SUCCESS = 'REQUEST_PASSWORD_RECOVERY_SUCCESS';
export var REQUEST_PASSWORD_RECOVERY_FAILURE = 'REQUEST_PASSWORD_RECOVERY_FAILURE';
export var FETCH_UNREAD_MESSAGES_COUNT_REQUEST = 'FETCH_UNREAD_MESSAGES_COUNT_REQUEST';
export var FETCH_UNREAD_MESSAGES_COUNT_SUCCESS = 'FETCH_UNREAD_MESSAGES_COUNT_SUCCESS';
export var FETCH_UNREAD_MESSAGES_COUNT_FAILURE = 'FETCH_UNREAD_MESSAGES_COUNT_FAILURE';
export var UPDATE_UNREAD_MESSAGES_COUNT = 'UPDATE_UNREAD_MESSAGES_COUNT';
export var SET_PASSWORD = 'SET_PASSWORD';
export var SEARCH_TOPICS_REQUEST = 'SEARCH_TOPICS_REQUEST';
export var SEARCH_TOPICS_SUCCESS = 'SEARCH_TOPICS_SUCCESS';
export var SEARCH_TOPICS_FAILURE = 'SEARCH_TOPICS_FAILURE';
export var SEARCH_TOPICS_INVALID = 'SEARCH_TOPICS_INVALID';
export var RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export var RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export var RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export var MOVE_CONTRACTS = 'MOVE_CONTRACTS';
export var CLEAR_MOVE_CONTRACTS = 'CLEAR_MOVE_CONTRACTS';
export var COMMIT_CONTRACTS_REQUEST = 'COMMIT_CONTRACTS_REQUEST';
export var COMMIT_CONTRACTS_SUCCESS = 'COMMIT_CONTRACTS_SUCCESS';
export var COMMIT_CONTRACTS_FAILURE = 'COMMIT_CONTRACTS_FAILURE';
export var FETCH_HELP_DOCUMENTS_REQUEST = 'FETCH_HELP_DOCUMENTS_REQUEST';
export var FETCH_HELP_DOCUMENTS_SUCCESS = 'FETCH_HELP_DOCUMENTS_SUCCESS';
export var FETCH_HELP_DOCUMENTS_FAILURE = 'FETCH_HELP_DOCUMENTS_FAILURE';
export var DOWNLOAD_STUDENT_LIST_REQUEST = 'DOWNLOAD_STUDENT_LIST_REQUEST';
export var DOWNLOAD_STUDENT_LIST_SUCCESS = 'DOWNLOAD_STUDENT_LIST_SUCCESS';
export var DOWNLOAD_STUDENT_LIST_FAILURE = 'DOWNLOAD_STUDENT_LIST_FAILURE';
export var UPLOAD_STUDENT_LIST_REQUEST = 'UPLOAD_STUDENT_LIST_REQUEST';
export var UPLOAD_STUDENT_LIST_SUCCESS = 'UPLOAD_STUDENT_LIST_SUCCESS';
export var UPLOAD_STUDENT_LIST_FAILURE = 'UPLOAD_STUDENT_LIST_FAILURE';
export var RESET_STUDENT_LIST = 'RESET_STUDENT_LIST';
export var FETCH_STUDENT_MERGE_REQUEST = 'FETCH_STUDENT_MERGE_REQUEST';
export var FETCH_STUDENT_MERGE_SUCCESS = 'FETCH_STUDENT_MERGE_SUCCESS';
export var FETCH_STUDENT_MERGE_FAILURE = 'FETCH_STUDENT_MERGE_FAILURE';
export var SET_STUDENT_MERGE_RESULTS = 'SET_STUDENT_MERGE_RESULTS';
export var SET_STUDENT_MERGE_STATUS = 'SET_STUDENT_MERGE_STATUS';
export var FETCH_STUDENT_PERFORMANCE_REQUEST = 'FETCH_STUDENT_PERFORMANCE_REQUEST';
export var FETCH_STUDENT_PERFORMANCE_SUCCESS = 'FETCH_STUDENT_PERFORMANCE_SUCCESS';
export var FETCH_STUDENT_PERFORMANCE_FAILURE = 'FETCH_STUDENT_PERFORMANCE_FAILURE';
export var FETCH_STUDENT_TOPIC_DETAIL_REQUEST = 'FETCH_STUDENT_TOPIC_DETAIL_REQUEST';
export var FETCH_STUDENT_TOPIC_DETAIL_SUCCESS = 'FETCH_STUDENT_TOPIC_DETAIL_SUCCESS';
export var FETCH_STUDENT_TOPIC_DETAIL_FAILURE = 'FETCH_STUDENT_TOPIC_DETAIL_FAILURE';
export var FETCH_STUDENT_PORTFOLIO_PROFILE_REQUEST = 'FETCH_STUDENT_PORTFOLIO_PROFILE_REQUEST';
export var FETCH_STUDENT_PORTFOLIO_PROFILE_SUCCESS = 'FETCH_STUDENT_PORTFOLIO_PROFILE_SUCCESS';
export var FETCH_STUDENT_PORTFOLIO_PROFILE_FAILURE = 'FETCH_STUDENT_PORTFOLIO_PROFILE_FAILURE';
export var FETCH_STUDENT_PORTFOLIO_PROFILE_SCHEMA_REQUEST = 'FETCH_STUDENT_PORTFOLIO_PROFILE_SCHEMA_REQUEST';
export var FETCH_STUDENT_PORTFOLIO_PROFILE_SCHEMA_FAILURE = 'FETCH_STUDENT_PORTFOLIO_PROFILE_SCHEMA_FAILURE';
export var FETCH_STUDENT_PORTFOLIO_PROFILE_SCHEMA_SUCCESS = 'FETCH_STUDENT_PORTFOLIO_PROFILE_SCHEMA_SUCCESS';
export var FETCH_TEACHER_NOTES_REQUEST = 'FETCH_TEACHER_NOTES_REQUEST';
export var FETCH_TEACHER_NOTES_SUCCESS = 'FETCH_TEACHER_NOTES_SUCCESS';
export var FETCH_TEACHER_NOTES_FAILURE = 'FETCH_TEACHER_NOTES_FAILURE';
export var CREATE_TEACHER_NOTE_REQUEST = 'CREATE_TEACHER_NOTE_REQUEST';
export var CREATE_TEACHER_NOTE_SUCCESS = 'CREATE_TEACHER_NOTE_SUCCESS';
export var CREATE_TEACHER_NOTE_FAILURE = 'CREATE_TEACHER_NOTE_FAILURE';
export var UPDATE_TEACHER_NOTE_REQUEST = 'UPDATE_TEACHER_NOTE_REQUEST';
export var UPDATE_TEACHER_NOTE_SUCCESS = 'UPDATE_TEACHER_NOTE_SUCCESS';
export var UPDATE_TEACHER_NOTE_FAILURE = 'UPDATE_TEACHER_NOTE_FAILURE';
export var DELETE_TEACHER_NOTE_UPDATE_ERROR = 'DELETE_TEACHER_NOTE_UPDATE_ERROR';
export var DELETE_TEACHER_NOTE_REQUEST = 'DELETE_TEACHER_NOTE_REQUEST';
export var DELETE_TEACHER_NOTE_SUCCESS = 'DELETE_TEACHER_NOTE_SUCCESS';
export var DELETE_TEACHER_NOTE_FAILURE = 'DELETE_TEACHER_NOTE_FAILURE';
export var UPDATE_COURSE_SETTINGS_REQUEST = 'UPDATE_COURSE_SETTINGS_REQUEST';
export var UPDATE_COURSE_SETTINGS_SUCCESS = 'UPDATE_COURSE_SETTINGS_SUCCESS';
export var UPDATE_COURSE_SETTINGS_FAILURE = 'UPDATE_COURSE_SETTINGS_FAILURE';
export var FETCH_ACCOUNT_SETTINGS_REQUEST = 'FETCH_ACCOUNT_SETTINGS_REQUEST';
export var FETCH_ACCOUNT_SETTINGS_SUCCESS = 'FETCH_ACCOUNT_SETTINGS_SUCCESS';
export var FETCH_ACCOUNT_SETTINGS_FAILURE = 'FETCH_ACCOUNT_SETTINGS_FAILURE';
export var EDIT_ACCOUNT_SETTINGS = 'EDIT_ACCOUNT_SETTINGS';
export var EDIT_ACCOUNT_SETTINGS_CANCEL = 'EDIT_ACCOUNT_SETTINGS_CANCEL';
export var SAVE_ACCOUNT_SETTINGS_REQUEST = 'SAVE_ACCOUNT_SETTINGS_REQUEST';
export var SAVE_ACCOUNT_SETTINGS_SUCCESS = 'SAVE_ACCOUNT_SETTINGS_SUCCESS';
export var SAVE_ACCOUNT_SETTINGS_FAILURE = 'SAVE_ACCOUNT_SETTINGS_FAILURE';
export var LINK_FACEBOOK_REQUEST = 'LINK_FACEBOOK_REQUEST';
export var LINK_FACEBOOK_SUCCESS = 'LINK_FACEBOOK_SUCCESS';
export var LINK_FACEBOOK_FAILURE = 'LINK_FACEBOOK_FAILURE';
export var UNLINK_FACEBOOK_REQUEST = 'UNLINK_FACEBOOK_REQUEST';
export var UNLINK_FACEBOOK_SUCCESS = 'UNLINK_FACEBOOK_SUCCESS';
export var UNLINK_FACEBOOK_FAILURE = 'UNLINK_FACEBOOK_FAILURE';
export var UPLOAD_PROFILE_IMG_SUCCESS = 'UPLOAD_PROFILE_IMG_SUCCESS';
export var UPLOAD_PROFILE_IMG_FAILURE = 'UPLOAD_PROFILE_IMG_FAILURE';
export var SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export var SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE';
export var SET_PASSWORD_CANCEL = 'SET_PASSWORD_CANCEL';
export var RESET_EXAM_SUBJECTS_REQUEST = 'RESET_EXAM_SUBJECTS_REQUEST';
export var RESET_EXAM_SUBJECTS_SUCCESS = 'RESET_EXAM_SUBJECTS_SUCCESS';
export var RESET_EXAM_SUBJECTS_FAILURE = 'RESET_EXAM_SUBJECTS_FAILURE';
export var FETCH_UNUSED_MEMBERSHIPS_SUCCESS = 'FETCH_UNUSED_MEMBERSHIPS_SUCCESS';
export var FETCH_UNUSED_MEMBERSHIPS_FAILURE = 'FETCH_UNUSED_MEMBERSHIPS_FAILURE';
export var DELETE_UNUSED_MEMBERSHIPS_SUCCESS = 'DELETE_UNUSED_MEMBERSHIPS_SUCCESS';
export var DELETE_UNUSED_MEMBERSHIPS_FAILURE = 'DELETE_UNUSED_MEMBERSHIPS_FAILURE';
export var FETCH_ACTIVITY_NOTES_REQUEST = 'FETCH_ACTIVITY_NOTES_REQUEST';
export var FETCH_ACTIVITY_NOTES_REQUEST_READMORE = 'FETCH_ACTIVITY_NOTES_REQUEST_READMORE';
export var FETCH_ACTIVITY_NOTES_SUCCESS = 'FETCH_ACTIVITY_NOTES_SUCCESS';
export var FETCH_ACTIVITY_NOTES_SUCCESS_READMORE = 'FETCH_ACTIVITY_NOTES_SUCCESS_READMORE';
export var FETCH_ACTIVITY_NOTES_FAILURE = 'FETCH_ACTIVITY_NOTES_FAILURE';
export var FETCH_ACTIVITY_NOTES_TAGS_REQUEST = 'FETCH_ACTIVITY_NOTES_TAGS_REQUEST';
export var FETCH_ACTIVITY_NOTES_TAGS_SUCCESS = 'FETCH_ACTIVITY_NOTES_TAGS_SUCCESS';
export var FETCH_ACTIVITY_NOTES_TAGS_FAILURE = 'FETCH_ACTIVITY_NOTES_TAGS_FAILURE';
export var FETCH_STUDENT_LIST_WITH_ACTIVITY_NOTE_COUNT = 'FETCH_STUDENT_LIST_WITH_ACTIVITY_NOTE_COUNT';
export var FETCH_ACTIVITY_NOTE_ALL_STUDENT_TAGS = 'FETCH_ACTIVITY_NOTE_ALL_STUDENT_TAGS';
export var RESET_STUDENT_LIST_WITH_ACTIVITY_NOTE_COUNT = 'RESET_STUDENT_LIST_WITH_ACTIVITY_NOTE_COUNT';
export var FETCH_USER_CLASSES = 'FETCH_USER_CLASSES';
export var FETCH_PEOPLE = 'FETCH_PEOPLE';
export var CREATE_CONVERSATION = 'CREATE_CONVERSATION';
export var FETCH_CONVERSATIONS = 'FETCH_CONVERSATIONS';
export var DELETE_CONVERSATION = 'DELETE_CONVERSATION';
export var SEND_CONVERSATION_MESSAGE = 'SEND_CONVERSATION_MESSAGE';
export var FETCH_MESSAGES = 'FETCH_MESSAGES';
export var DELETE_MESSAGE = 'DELETE_MESSAGE';
export var SET_CURRENT_CONVERSATION_ID = 'SET_CURRENT_CONVERSATION_ID';
export var READ_CONVERSATION = 'READ_CONVERSATION';
export var MARK_CONVERSATION_AS_READ = 'MARK_CONVERSATION_AS_READ';
export var UPLOAD_CONVERSATION_IMAGE = 'UPLOAD_CONVERSATION_IMAGE';
export var UPLOAD_CONVERSATION_PDF = 'UPLOAD_CONVERSATION_PDF';
export var SETUP_PUSHER = 'SETUP_PUSHER';
export var UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export var UPLOAD_FILE = 'UPLOAD_FILE';
export var FETCH_STATISTICS = 'FETCH_STATISTICS';
export var FETCH_STATISTICS_FOR_ORGANIZATION_STUDENTS = 'FETCH_STATISTICS_FOR_ORGANIZATION_STUDENTS';
export var REQUEST_DOWNLOAD_STATISTICS = 'REQUEST_DOWNLOAD_STATISTICS';
export var UPDATE_CURRICULUM_FILTERS = 'UPDATE_CURRICULUM_FILTERS';
export var ASSESSMENT_UPDATE_ACTIVE_STEP = 'ASSESSMENT_UPDATE_ACTIVE_STEP';
export var ASSESSMENT_UPDATE_SELECTED_TOPIC = 'ASSESSMENT_UPDATE_SELECTED_TOPIC';
export var ASSESSMENT_RESET_SELECTED_TOPIC = 'ASSESSMENT_RESET_SELECTED_TOPIC';
export var ASSESSMENT_UPDATE_SELECTED_COURSES_SOURCE = 'ASSESSMENT_UPDATE_SELECTED_COURSES_SOURCE';
export var ASSESSMENT_UPDATE_SEARCH_COURSES_KEYWORD = 'ASSESSMENT_UPDATE_SEARCH_COURSES_KEYWORD';
export var FETCH_SERVER_TIME = 'FETCH_SERVER_TIME';
export var UPDATE_SELF_ADMINISTERED_STUDENT_DETAILS_ROW = 'UPDATE_SELF_ADMINISTERED_STUDENT_DETAILS_ROW';
export var UPDATE_SELF_ADMINISTERED_STUDENT_DETAILS_REQUEST = 'UPDATE_SELF_ADMINISTERED_STUDENT_DETAILS_REQUEST';
export var UPDATE_SELF_ADMINISTERED_STUDENT_DETAILS_SUCCESS = 'UPDATE_SELF_ADMINISTERED_STUDENT_DETAILS_SUCCESS';
export var UPDATE_SELF_ADMINISTERED_STUDENT_DETAILS_FAILURE = 'UPDATE_SELF_ADMINISTERED_STUDENT_DETAILS_FAILURE';
export var FETCH_SELF_ADMINISTERED_STUDENTS_REQUEST = 'FETCH_SELF_ADMINISTERED_STUDENTS_REQUEST';
export var FETCH_SELF_ADMINISTERED_STUDENTS_SUCCESS = 'FETCH_SELF_ADMINISTERED_STUDENTS_SUCCESS';
export var FETCH_SELF_ADMINISTERED_STUDENTS_FAILURE = 'FETCH_SELF_ADMINISTERED_STUDENTS_FAILURE';
export var UPLOAD_SELF_ADMIN_CSV_REQUEST = 'UPLOAD_SELF_ADMIN_CSV_REQUEST';
export var UPLOAD_SELF_ADMIN_CSV_SUCCESS = 'UPLOAD_SELF_ADMIN_CSV_SUCCESS';
export var UPLOAD_SELF_ADMIN_CSV_VALIDATION_SUCCESS = 'UPLOAD_SELF_ADMIN_CSV_VALIDATION_SUCCESS';
export var UPLOAD_SELF_ADMIN_CSV_VALIDATION_PARTIAL_SUCCESS = 'UPLOAD_SELF_ADMIN_CSV_VALIDATION_PARTIAL_SUCCESS';
export var UPLOAD_SELF_ADMIN_CSV_CONTINUE_WITH_WARNING = 'UPLOAD_SELF_ADMIN_CSV_CONTINUE_WITH_WARNING';
export var UPLOAD_SELF_ADMIN_CSV_FAILURE = 'UPLOAD_SELF_ADMIN_CSV_FAILURE';
export var UPLOAD_SELF_ADMIN_CSV_CLEAR = 'UPLOAD_SELF_ADMIN_CSV_CLEAR';
export var GENERATE_ASSIGNMENT_REPORT_SUCCESS = 'GENERATE_ASSIGNMENT_REPORT_SUCCESS';
export var GENERATE_ASSIGNMENT_REPORT_FAILED = 'GENERATE_ASSIGNMENT_REPORT_FAILED';
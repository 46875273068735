'use strict';

import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import API from 'utils/API';
var createAsync = asyncFactory(actionCreatorFactory());
export var getActivities = createAsync('GET_ACTIVITIES', function (_a) {
  var organizationId = _a.organizationId,
      page = _a.page;
  return API.get("/api/organizations/" + organizationId + "/activities", {
    page: page
  });
});
'use strict';

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { fetchServerTime } from 'actions/ServerTime';
export var initialState = {
  currentTime: new Date(),
  timezone: undefined,
  isFetching: false,
  error: undefined
};
export var serverTime = reducerWithInitialState(initialState)["case"](fetchServerTime.async.started, function (state) {
  return __assign(__assign({}, state), {
    isFetching: true,
    error: undefined
  });
})["case"](fetchServerTime.async.done, function (state, _a) {
  var result = _a.result;
  return __assign(__assign(__assign({}, state), result), {
    isFetching: false,
    error: undefined
  });
})["case"](fetchServerTime.async.failed, function (state, _a) {
  var error = _a.error;
  return __assign(__assign({}, state), {
    isFetching: false,
    error: error
  });
});
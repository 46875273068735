'use strict';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { FETCH_ORGANIZATION_REQUEST, FETCH_ORGANIZATION_SUCCESS, FETCH_ORGANIZATION_FAILURE, EDIT_ORGANIZATION, JOIN_ORGANIZATION_REQUEST, JOIN_ORGANIZATION_SUCESSS, JOIN_ORGANIZATION_FAILURE, SAVE_ORGANIZATION_REQUEST, SAVE_ORGANIZATION_FAILURE, SAVE_ORGANIZATION_SUCCESS, SAVE_ORGANIZATION_CANCEL } from 'constants/ActionTypes';
var initialState = {
  isFetching: false,
  isFetchError: false,
  didInvalidate: false,
  status: null,
  organization: null,
  targetOrganizations: [],
  pristineOrganization: null,
  isJoining: false,
  joinSuccess: false
};

function _currentOrganization() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_ORGANIZATION_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: true,
        didInvalidate: false
      });

    case FETCH_ORGANIZATION_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        didInvalidate: false,
        organization: action.organization,
        pristineOrganization: action.organization,
        targetOrganizations: action.targetOrganizations
      });

    case FETCH_ORGANIZATION_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        isFetchError: true,
        organization: null,
        didInvalidate: true,
        pristineOrganization: null
      });

    case EDIT_ORGANIZATION:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        status: 'editing',
        pristineOrganization: Object.assign(action.organization, {}),
        errorMessages: [] // Clear error message for re-editing after error message appear

      });

    case JOIN_ORGANIZATION_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isJoining: true
      });

    case JOIN_ORGANIZATION_SUCESSS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isJoining: false,
        joinSuccess: true
      });

    case JOIN_ORGANIZATION_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isJoining: false,
        joinSuccess: false
      });

    case SAVE_ORGANIZATION_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        status: 'saving',
        organization: action.organization
      });

    case SAVE_ORGANIZATION_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        status: 'error',
        errorMessages: action.errorMessages
      });

    case SAVE_ORGANIZATION_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        status: 'saved',
        pristineOrganization: action.organization,
        organization: action.organization
      });

    case SAVE_ORGANIZATION_CANCEL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetching: false,
        status: null,
        organization: action.organization
      });

    default:
      return state;
  }
}

export default function currentOrganization() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_ORGANIZATION_REQUEST:
    case FETCH_ORGANIZATION_SUCCESS:
    case FETCH_ORGANIZATION_FAILURE:
    case EDIT_ORGANIZATION:
    case SAVE_ORGANIZATION_REQUEST:
    case SAVE_ORGANIZATION_FAILURE:
    case SAVE_ORGANIZATION_SUCCESS:
    case SAVE_ORGANIZATION_CANCEL:
      return _objectSpread(_objectSpread({}, state), _currentOrganization(state, action));

    default:
      return state;
  }
}